/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import agent from './Agent'; 
import area from '../../areas/Area'; 

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
  colOne: {
    border: '1px solid #cfd8dc', color: '#37474f', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0
  },
  colTwo: {
    border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top'
  }
});

class Model extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      currentTopic: '',
      currentRating: { relevance: 0, specificity: 0, control: 0, impacts: 0 },
      supportLevels: [],
      support: [],
      openPDFDialog: false,
      title: '',
      url: '',
    }

    this.handlePDFClickOpen = this.handlePDFClickOpen.bind(this);
    this.handlePDFClickClose = this.handlePDFClickClose.bind(this);

  } 

  componentDidMount() {
    var resultSupport = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    var Support = ['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''];

    agent.getList(this.props.areaId).then((result) => {

      
      result.map((row) => {
        row.support_levels.map((row) => {

          switch (row.topic) {
            case 'agent_strategy.item_one':
              resultSupport[0] += row.supportvalue;
              if (resultSupport[0]/result.length >= 0 && resultSupport[0]/result.length <= (resultSupport[0] * .5)) {
                Support[0] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de agentes regionales dinamizadores de la GC y  la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_01_Mapa_Agent_Dinamiz_GC_I%2BD%2BI_Mision.pdf')}>NIVAP01</Link>
                              }} />;
              } else if (resultSupport[0]/result.length > (resultSupport[0] * .5) && resultSupport[0]/result.length <= (resultSupport[0] * .7)) {
                Support[0] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de agentes regionales dinamizadores de la GC y la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_02_Mapa_Agent_Dinamiz_GC_I%2BD%2Bi_Propuesta_Mision.pdf')}>NIVAP02</Link>
                              }} />;
              } else if (resultSupport[0]/result.length > (resultSupport[0] * .7) && resultSupport[0]/result.length <= (resultSupport[0] * 1)) {
                Support[0] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de acción de agentes regionales dinamizadores de la GC para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_03_An%C3%A1lisis_Grado_Solapam_Acci%C3%B3n_Agentes_Reg_GCI%2BD%2Bi.pdf')}>NIVAP03</Link>
                                }} />;
              }
              break;
            case 'agent_strategy.item_two':
              resultSupport[1] += row.supportvalue;
              if (resultSupport[1] >= 0 && resultSupport[1] <= (resultSupport[1] * .5)) {
                Support[1] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de agentes regionales dinamizadores de la GC y  la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_01_Mapa_Agent_Dinamiz_GC_I%2BD%2BI_Mision.pdf')}>NIVAP01</Link>
                              }} />;
              } else if (resultSupport[1] > (resultSupport[1] * .5) && resultSupport[1] <= (resultSupport[1] * .7)) {
                Support[1] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de agentes regionales dinamizadores de la GC y la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_02_Mapa_Agent_Dinamiz_GC_I%2BD%2Bi_Propuesta_Mision.pdf')}>NIVAP02</Link>
                              }} />;
              } else if (resultSupport[1] > (resultSupport[1] * .7) && resultSupport[1] <= (resultSupport[1] * 1)) {
                Support[1] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de acción de agentes regionales dinamizadores de la GC para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_03_An%C3%A1lisis_Grado_Solapam_Acci%C3%B3n_Agentes_Reg_GCI%2BD%2Bi.pdf')}>NIVAP03</Link>
                              }} />;
              }
              break; 
            case 'agent_connect.item_one':
              resultSupport[2] += row.supportvalue;
              if (resultSupport[2] >= 0 && resultSupport[2] <= (resultSupport[2] * .5)) {
                Support[2] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a observatorios de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_04_Mapa_Inic_Reg_Observ_Mision.pdf')}>NIVAP04</Link>
                              }} />;
              } else if (resultSupport[2] > (resultSupport[2] * .5) && resultSupport[2] <= (resultSupport[2] * .7)) {
                Support[2] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a observatorios de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_08_Mapa_Inici_Reg_Observ_PptaMision.pdf')}>NIVAP08</Link>
                              }} />;
              } else if (resultSupport[2] > (resultSupport[2] * .7) && resultSupport[2] <= (resultSupport[2] * 1)) {
                Support[2] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a observatorios de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_12_An%C3%A1lisis_Grado_Solapam_Inicia_Reg_Observat_I%2BD%2Bi.pdf')}>NIVAP12</Link>
                              }} />;
              }
              break; 
            case 'agent_connect.item_two':
              resultSupport[3] += row.supportvalue;
              if (resultSupport[3] >= 0 && resultSupport[3] <= (resultSupport[3] * .5)) {
                Support[3] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a vigilancia tecnológica y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_05_Mapa_Inic_VT_Mision.pdf')}>NIVAP05</Link>
                              }} />;
              } else if (resultSupport[3] > (resultSupport[3] * .5) && resultSupport[3] <= (resultSupport[3] * .7)) {
                Support[3] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a vigilancia tecnológica e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_09_Mapa_Inici_Reg_VT_PptaMision.pdf')}>NIVAP09</Link>
                              }} />;
              } else if (resultSupport[3] > (resultSupport[3] * .7) && resultSupport[3] <= (resultSupport[3] * 1)) {
                Support[3] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a vigilancia tecnológica', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_13_An%C3%A1lisis_Grado_Solap_VT_Reg.pdf')}>NIVAP13</Link>
                              }} />;
              }
              break; 
            case 'agent_connect.item_three':
              resultSupport[4] += row.supportvalue;
              if (resultSupport[4] >= 0 && resultSupport[4] <= (resultSupport[4] * .5)) {
                Support[4] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a comunicación de la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_06_Mapa_Inic_Comunic_I%2BD%2Bi_Mision.pdf')}>NIVAP06</Link>
                              }} />;
              } else if (resultSupport[4] > (resultSupport[4] * .5) && resultSupport[4] <= (resultSupport[4] * .7)) {
                Support[4] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a comunicación de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_10_Mapa_Inici_Reg_ComI%2BD%2Bi_PptaMision.pdf')}>NIVAP10</Link>
                              }} />;
              } else if (resultSupport[4] > (resultSupport[4] * .7) && resultSupport[4] <= (resultSupport[4] * 1)) {
                Support[4] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a comunicación de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_14_An%C3%A1lisis_Grado_Solapa_Comunic_I%2BD%2Bi.pdf')}>NIVAP14</Link>
                              }} />;
              }
              break;
            case 'agent_connect.item_four':
              resultSupport[5] += row.supportvalue;
              if (resultSupport[5] >= 0 && resultSupport[5] <= (resultSupport[5] * .5)) {
                Support[5] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Inventario de publicaciones regionales de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_07_Inventario_Pub_Reg_Mision.pdf')}>NIVAP07</Link>
                              }} />;
              } else if (resultSupport[5] > (resultSupport[5] * .5) && resultSupport[5] <= (resultSupport[5] * .7)) {
                Support[5] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Inventario de publicaciones regionales de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_11_Inventario_Pub_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP11</Link>
                              }} />;
              } else if (resultSupport[5] > (resultSupport[5] * .7) && resultSupport[5] <= (resultSupport[5] * 1)) {
                Support[5] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las publicaciones regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_15_An%C3%A1lisis_Grado_Solap_Pub_Reg_I%2BD%2Bi.pdf')}>NIVAP15</Link>
                              }} />;
              }
              break;
            case 'agent_sort.item_one':
              resultSupport[6] += row.supportvalue;
              if (resultSupport[6] >= 0 && resultSupport[6] <= (resultSupport[6] * .5)) {
                Support[6] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de sitios web regionales de consulta sobre I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_16_Mapa_Sitios_Web_Consulta_Mision.pdf')}>NIVAP16</Link>
                              }} />;
              } else if (resultSupport[6] > (resultSupport[6] * .5) && resultSupport[6] <= (resultSupport[6] * .7)) {
                Support[6] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Mapa de sitios web regionales de consulta sobre I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_20_Mapa_Sitiosweb_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP20</Link>
                              }} />;
              } else if (resultSupport[6] > (resultSupport[6] * .7) && resultSupport[6] <= (resultSupport[6] * 1)) {
                Support[6] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los sitios web regionales de consulta sobre I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_24_An%C3%A1lisis_Grado_Solap_Sitios_Web_Reg_I%2BD%2Bi.pdf')}>NIVAP24</Link>
                              }} />;
              }
              break;
            case 'agent_sort.item_two':
              resultSupport[7] += row.supportvalue;
              if (resultSupport[7] >= 0 && resultSupport[7] <= (resultSupport[7] * .5)) {
                Support[7] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Directorio de organizaciones (no empresas) regionales de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_17_Directorio_Org_I%2BD%2Bi_Mision.pdf')}>NIVAP17</Link>
                              }} />;
              } else if (resultSupport[7] > (resultSupport[7] * .5) && resultSupport[7] <= (resultSupport[7] * .7)) {
                Support[7] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Directorio de organizaciones (no empresas) regionales de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_21_Directorio_Org_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP21</Link>
                              }} />;
              } else if (resultSupport[7] > (resultSupport[7] * .7) && resultSupport[7] <= (resultSupport[7] * 1)) {
                Support[7] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las organizaciones (no empresas) regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_25_An%C3%A1lisis_Grado_Solap_Org_Reg_I%2BD%2Bi.pdf')}>NIVAP25</Link>
                              }} />;
              }
              break;
            case 'agent_sort.item_three':
              resultSupport[8] += row.supportvalue;
              if (resultSupport[8] >= 0 && resultSupport[8] <= (resultSupport[8] * .5)) {
                Support[8] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Directorio de empresas regionales de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_18_Directorio_Emp_I%2BD%2Bi_Reg_Mision.pdf')}>NIVAP18</Link>
                              }} />;
              } else if (resultSupport[8] > (resultSupport[8] * .5) && resultSupport[8] <= (resultSupport[8] * .7)) {
                Support[8] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Directorio de empresas regionales de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_22_Directorio_Emp_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP22</Link>
                              }} />;
              } else if (resultSupport[8] > (resultSupport[8] * .7) && resultSupport[8] <= (resultSupport[8] * 1)) {
                Support[8] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las empresas regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_26_An%C3%A1lisis_Grado_Solap_Emp_Reg_I%2BD%2Bi.pdf')}>NIVAP26</Link>
                              }} />;
              }
              break;
            case 'agent_sort.item_four':
              resultSupport[9] += row.supportvalue;
              if (resultSupport[9] >= 0 && resultSupport[9] <= (resultSupport[9] * .5)) {
                Support[9] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Directorio de grupos de investigación regionales de I+D+i y revisión del carácter misional tanto del directorio como de los grupos', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_19_Directorio_grupos_Inv_Mision.pdf')}>NIVAP19</Link>
                              }} />;
              } else if (resultSupport[9] > (resultSupport[9] * .5) && resultSupport[9] <= (resultSupport[9] * .7)) {
                Support[9] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Directorio de grupos de investigación regionales e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_23_Directorio_GruposInvest_PptaMision.pdf')}>NIVAP23</Link>
                              }} />;
              } else if (resultSupport[9] > (resultSupport[9] * .7) && resultSupport[9] <= (resultSupport[9] * 1)) {
                Support[9] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los grupos de investigación regionales de orientación hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_27_An%C3%A1lisis_Grado_Solap_Grupos_Inv_Reg_I%2BD%2Bi.pdf')}>NIVAP27</Link>
                              }} />;
              }
              break;
            case 'agent_socialize.item_one':
              resultSupport[10] += row.supportvalue;
              if (resultSupport[10] >= 0 && resultSupport[10] <= (resultSupport[10] * .5)) {
                Support[10] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de clusters regionales orientados a la I+D+i y revisión del carácter misional tanto del mapa como de los clusters', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_28_Mapa_Clusters_I%2BD%2Bi_Mision.pdf')}>NIVAP28</Link>
                                }} />;
              } else if (resultSupport[10] > (resultSupport[10] * .5) && resultSupport[10] <= (resultSupport[10] * .7)) {
                Support[10] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de clusters regionales orientados a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_32_Mapa_clusters_I%2BD%2Bi_PptaMision.pdf')}>NIVAP32</Link>
                                }} />;
              } else if (resultSupport[10] > (resultSupport[10] * .7) && resultSupport[10] <= (resultSupport[10] * 1)) {
                Support[10] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de clusters regionales orientados a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_36_An%C3%A1lisis_Grado_Solapa_Clusters_Reg_I%2BD%2Bi.pdf')}>NIVAP36</Link>
                                }} />;
              }
              break; 
            case 'agent_socialize.item_two':
              resultSupport[11] += row.supportvalue;
              if (resultSupport[11] >= 0 && resultSupport[11] <= (resultSupport[11] * .5)) {
                Support[11] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de agrupaciones empresariales para la I+D+i y revisión del carácter misional tanto del mapa como de las agrupaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_29_Mapa_Agrup_Emp_Mision.pdf')}>NIVAP29</Link>
                                }} />;
              } else if (resultSupport[11] > (resultSupport[10] * .5) && resultSupport[11] <= (resultSupport[11] * .7)) {
                Support[11] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de agrupaciones empresariales para la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_33_Mapa_AgrupEmp_I%2BD%2Bi_PptaMision.pdf')}>NIVAP33</Link>
                                }} />;
              } else if (resultSupport[11] > (resultSupport[10] * .7) && resultSupport[11] <= (resultSupport[11] * 1)) {
                Support[11] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las agrupaciones empresariales para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_37_An%C3%A1lisis_Grado_Solapa_Agrup_Emp_I%2BD%2Bi.pdf')}>NIVAP37</Link>
                                }} />;
              }
              break; 
            case 'agent_socialize.item_three':
              resultSupport[12] += row.supportvalue;
              resultSupport[12] += row.supportvalue;
              if (resultSupport[12] >= 0 && resultSupport[12] <= (resultSupport[12] * .5)) {
                Support[12] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de mesas de trabajo para la I+D+i y revisión del carácter misional tanto del mapa como de las mesas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_30_Mapa_Mesas_Trabajo_I%2BD%2Bi_Mision.pdf')}>NIVAP30</Link>
                                }} />;
              } else if (resultSupport[12] > (resultSupport[12] * .5) && resultSupport[12] <= (resultSupport[12] * .7)) {
                Support[12] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de mesas de trabajo para la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_34_Mapa_Mesas_Trabajo_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP34</Link>
                                }} />;
              } else if (resultSupport[12] > (resultSupport[12] * .7) && resultSupport[12] <= (resultSupport[12] * 1)) {
                Support[12] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las mesas de trabajo regionales orientadas hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_38_An%C3%A1lisis_Grado_Solapa_Mesas_Trabajo_I%2BD%2Bi.pdf')}>NIVAP38</Link>
                                }} />;
              }
              break; 
            case 'agent_socialize.item_four':
              resultSupport[13] += row.supportvalue;
              if (resultSupport[13] >= 0 && resultSupport[13] <= (resultSupport[13] * .5)) {
                Support[13] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de asociaciones y fundaciones regionales orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de las asociaciones y fundaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_31_Mapa_Asoc_Fundac_I%2BD%2Bi_Mision.pdf')}>NIVAP31</Link>
                                }} />;
              } else if (resultSupport[13] > (resultSupport[13] * .5) && resultSupport[13] <= (resultSupport[13] * .7)) {
                Support[13] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de asociaciones y fundaciones regionales orientadas a la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/Ficha_NIVAP_35_Mapa_Asoc_Fundac_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP35</Link>
                                }} />;
              } else if (resultSupport[13] > (resultSupport[13] * .7) && resultSupport[13] <= (resultSupport[13] * 1)) {
                Support[13] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las asociaciones y fundaciones regionales orientadas hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_39_An%C3%A1lisis_Grado_Solapa_Asoc_Fundac_I%2BD%2Bi.pdf')}>NIVAP39</Link>
                                }} />;
              }
              break;
            case 'agent_develop.item_one':
              resultSupport[14] += row.supportvalue;
              if (resultSupport[14] >= 0 && resultSupport[14] <= (resultSupport[14] * .5)) {
                Support[14] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de titulaciones universitarias (grados) disponibles en la región con perfil de I+D+i y revisión del carácter misional tanto del mapa como de las titulaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_40_Mapa_Titulac_Reg_Mision.pdf')}>NIVAP40</Link>
                                }} />;
              } else if (resultSupport[14] > (resultSupport[14] * .5) && resultSupport[14] <= (resultSupport[14] * .7)) {
                Support[14] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de titulaciones universitarias (grados) impartidas en la región y revisión del carácter misional tanto del mapa como de tales titulaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_44_Mapa_Titulac_Grados_Reg_PptaMision.pdf')}>NIVAP44</Link>
                                }} />;
              } else if (resultSupport[14] > (resultSupport[14] * .7) && resultSupport[14] <= (resultSupport[14] * 1)) {
                Support[14] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las titulaciones universitarias (grados) impartidas en la región', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_48_An%C3%A1lisis_Grado_Solapa_Titulac_Univer_Reg.pdf')}>NIVAP48</Link>
                                }} />;
              }
              break;
            case 'agent_develop.item_two':
              resultSupport[15] += row.supportvalue;
              if (resultSupport[15] >= 0 && resultSupport[15] <= (resultSupport[15] * .5)) {
                Support[15] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de titulaciones de posgrados disponibles en la región con perfil de I+D+i y revisión del carácter misional tanto del mapa como de las titulaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_41_Mapa_Titulac_Posgrado_Reg_Mision.pdf')}>NIVAP41</Link>
                                }} />;
              } else if (resultSupport[15] > (resultSupport[15] * .5) && resultSupport[15] <= (resultSupport[15] * .7)) {
                Support[15] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de titulaciones de posgrado impartidas en la región y revisión del carácter misional tanto del mapa como de tales titulaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_45_Mapa_Titulac_Posgrado_Reg_PptaMision.pdf')}>NIVAP45</Link>
                                }} />;
              } else if (resultSupport[15] > (resultSupport[15] * .7) && resultSupport[15] <= (resultSupport[15] * 1)) {
                Support[15] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las titulaciones posgrado impartidas en la región', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_49_An%C3%A1lisis_Grado_Solapa_Titulac_Posgrado_Reg.pdf')}>NIVAP49</Link>
                                }} />;
              }
              break;
            case 'agent_develop.item_three':
              resultSupport[16] += row.supportvalue;
              if (resultSupport[16] >= 0 && resultSupport[16] <= (resultSupport[16] * .5)) {
                Support[16] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas de apoyo público regional a la I+D+i y revisión del carácter misional tanto del mapa como de las iniciativas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_42_Mapa_Iniciativas_Pub_I%2BD%2Bi_Mision.pdf')}>NIVAP42</Link>
                                }} />;
              } else if (resultSupport[16] > (resultSupport[16] * .5) && resultSupport[16] <= (resultSupport[16] * .7)) {
                Support[16] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas de apoyo púbico regional a la I+D+i y revisión del carácter misional tanto del mapa como de tales iniciativas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_46_Mapa_Inici_Apoy_Pub_I%2BD%2BiReg_PpatMision.pdf')}>NIVAP46</Link>
                                }} />;
              } else if (resultSupport[16] > (resultSupport[16] * .7) && resultSupport[16] <= (resultSupport[16] * 1)) {
                Support[16] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas de apoyo público a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_50_An%C3%A1lisis_Grado_Solapa_Inici_Apoyo_Pub_I%2BD%2Bi.pdf')}>NIVAP50</Link>
                                }} />;
              }
              break;
            case 'agent_develop.item_four':
              resultSupport[17] += row.supportvalue;
              if (resultSupport[17] >= 0 && resultSupport[17] <= (resultSupport[17] * .5)) {
                Support[17] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas de apoyo público regional al emprendimiento innovador y revisión del carácter misional tanto del mapa como de las iniciativas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_43_Mapa_Inic_Apoyo_Publico_Empren_Innov_Mision.pdf')}>NIVAP43</Link>
                                }} />;
              } else if (resultSupport[17] > (resultSupport[17] * .5) && resultSupport[17] <= (resultSupport[17] * .7)) {
                Support[17] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas de apoyo púbico regional al emprendimiento innovador y revisión del carácter misional tanto del mapa como de tales iniciativas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_47_Mapa_Apoyo_Pub_Reg_EmpInnovador_PpatMision.pdf')}>NIVAP47</Link>
                                }} />;
              } else if (resultSupport[17] > (resultSupport[17] * .7) && resultSupport[17] <= (resultSupport[17] * 1)) {
                Support[17] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas de apoyo público al emprendimiento innovador', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_51_An%C3%A1lisis_Grado_Solapa_Inici_Empren_Innov.pdf')}>NIVAP51</Link>
                                }} />;
              }
              break;
            case 'agent_policies.item_one':
              resultSupport[18] += row.supportvalue;
              if (resultSupport[18] >= 0 && resultSupport[18] <= (resultSupport[18] * .5)) {
                Support[18] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis de las convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación) y revisión del carácter misional tanto del directorio como de tales convocatorias', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_52_An%C3%A1lisis_Convoca_Competitiva_Reg_Apoyo_I%2BD%2Bi.pdf')}>NIVAP52</Link>
                                }} />;
              } else if (resultSupport[18] > (resultSupport[18] * .5) && resultSupport[18] <= (resultSupport[18] * .7)) {
                Support[18] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación) y revisión del carácter misional tanto del directorio como de tales convocatorias', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_56_Directorio_Convoc_Compet_Cofinanc_PptaMision.pdf')}>NIVAP56</Link>
                                }} />;
              } else if (resultSupport[18] > (resultSupport[18] * .7) && resultSupport[18] <= (resultSupport[18] * 1)) {
                Support[18] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación)', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_60_An%C3%A1lisis_Grado_Solapa_Convoc_Compe_Reg_Cofinanc.pdf')}>NIVAP60</Link>
                                }} />;
              }
              break;
            case 'agent_policies.item_two':
              resultSupport[19] += row.supportvalue;
              if (resultSupport[19] >= 0 && resultSupport[19] <= (resultSupport[19] * .5)) {
                Support[19] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis de las opciones de subvenciones regionales de la I+D+i y revisión del carácter misional de tales líneas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_53_An%C3%A1lisis_Opciones_Subvenci%C3%B3n_I%2BD%2Bi.pdf')}>NIVAP53</Link>
                                }} />;
              } else if (resultSupport[19] > (resultSupport[19] * .5) && resultSupport[19] <= (resultSupport[19] * .7)) {
                Support[19] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de subvenciones regionales de la I+D+i y revisión del carácter misional tanto del directorio como de tales líneas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_57_Directorio_Subvenc_I%2BD%2Bi_PptaMision.pdf')}>NIVAP57</Link>
                                }} />;
              } else if (resultSupport[19] > (resultSupport[19] * .7) && resultSupport[19] <= (resultSupport[19] * 1)) {
                Support[19] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las subvenciones regionales de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_61_An%C3%A1lisis_Grado_Solapa_Subvencion.pdf')}>NIVAP61</Link>
                                }} />;
              }
              break; 
            case 'agent_policies.item_three':
              resultSupport[20] += row.supportvalue;
              if (resultSupport[20] >= 0 && resultSupport[20] <= (resultSupport[20] * .5)) {
                Support[20] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de líneas de crédito reembolsables de apoyo a la I+D+i y revisión del carácter misional tanto del directorio como de las líneas de crédito', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_54_Directorio_Lineas_CredReemb_I%2BD%2Bi_Mision.pdf')}>NIVAP54</Link>
                                }} />;
              } else if (resultSupport[20] > (resultSupport[20] * .5) && resultSupport[20] <= (resultSupport[20] * .7)) {
                Support[20] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de líneas de crédito reembolsables para el apoyo regional a la I+D+i y revisión del carácter misional tanto del directorio como de tales líneas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_58_Directorio_L%C3%ADneas_CredReem_I%2BD%2Bi_PptaMision.pdf')}>NIVAP58</Link>
                                }} />;
              } else if (resultSupport[20] > (resultSupport[20] * .7) && resultSupport[20] <= (resultSupport[20] * 1)) {
                Support[20] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las líneas de crédito reembolsables para el apoyo regional a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_62_An%C3%A1lisis_Grado_Solapa_CredReemb_Reg_I%2BD%2Bi.pdf')}>NIVAP62</Link>
                                }} />;
              }
              break; 
            case 'agent_policies.item_four':
              resultSupport[21] += row.supportvalue;
              if (resultSupport[21] >= 0 && resultSupport[21] <= (resultSupport[21] * .5)) {
                Support[21] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del número de solicitudes regionales de propiedad intelectual e industrial', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_55_An%C3%A1lisis_Solicit_Reg_Prop_Intelec_Indus.pdf')}>NIVAP55</Link>
                                }} />;
              } else if (resultSupport[21] > (resultSupport[21] * .5) && resultSupport[21] <= (resultSupport[21] * .7)) {
                Support[21] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de solicitudes de propiedad intelectual e industrial y revisión del rol institucional para la realización de esta labor', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_59_Directorio_Solicitud_Propiedad_Intelec_Rol_Institu.pdf')}>NIVAP59</Link>
                                }} />;
              } else if (resultSupport[21] > (resultSupport[21] * .7) && resultSupport[21] <= (resultSupport[21] * 1)) {
                Support[21] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis de los resultados de solicitudes regionales de propiedad intelectual e industrial', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_63_An%C3%A1lisis_Grado_Solapa_Solicitu_Propie_Intelec.pdf')}>NIVAP63</Link>
                                }} />;
              }
              break; 
            case 'agent_structures.item_one':
              resultSupport[22] += row.supportvalue;
              if (resultSupport[22] >= 0 && resultSupport[22] <= (resultSupport[22] * .5)) {
                Support[22] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de los consejos y comités estratégicos regionales para la I+D+i y revisión del carácter misional tanto del directorio como de los consejos y comités', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_64_Directorio_Consejos_Comit%C3%A9s_Estrateg_Reg_I%2BD%2Bi.pdf')}>NIVAP64</Link>
                                }} />;
              } else if (resultSupport[22] > (resultSupport[22] * .5) && resultSupport[22] <= (resultSupport[22] * .7)) {
                Support[22] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de los consejos y comités estratégicos regionales para la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_68_Directorio_Consejos_Comit%C3%A9s_Estrateg_Reg_I%2BD%2Bi_PptaM.pdf')}>NIVAP68</Link>
                                }} />;
              } else if (resultSupport[22] > (resultSupport[22] * .7) && resultSupport[22] <= (resultSupport[22] * 1)) {
                Support[22] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los Consejos y Comités estratégicos para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_72_An%C3%A1lisis_Grado_Solap_Consejos_Comit%C3%A9s_Estrateg_Reg_I%2BD%2Bi.pdf')}>NIVAP72</Link>
                                }} />;
              }
              break;
            case 'agent_structures.item_two':
              resultSupport[23] += row.supportvalue;
              if (resultSupport[23] >= 0 && resultSupport[23] <= (resultSupport[23] * .5)) {
                Support[23] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de las fundaciones regionales de promoción y apoyo a la I+D+i y revisión del carácter misional tanto del directorio como de las fundaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_65_Directorio_Fundaciones_Reg_Promoci%C3%B3n_Apoy_I%2BD%2Bi.pdf')}>NIVAP65</Link>
                                }} />;
              } else if (resultSupport[23] > (resultSupport[23] * .5) && resultSupport[23] <= (resultSupport[23] * .7)) {
                Support[23] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de las fundaciones regionales de promoción y apoyo a la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_69_Directorio_Fundaciones_Reg_Promoci%C3%B3n_Apoy_I%2BD%2Bi_PptaMision.pdf')}>NIVAP69</Link>
                                }} />;
              } else if (resultSupport[23] > (resultSupport[23] * .7) && resultSupport[23] <= (resultSupport[23] * 1)) {
                Support[23] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las fundaciones de promoción y apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_73_An%C3%A1lisis_Grado_Solap_Fundac_Reg_Promoci%C3%B3n_Apoy_I%2BD%2Bi.pdf')}>NIVAP73</Link>
                                }} />;
              }
              break;
            case 'agent_structures.item_three':
              resultSupport[24] += row.supportvalue;
              if (resultSupport[24] >= 0 && resultSupport[24] <= (resultSupport[24] * .5)) {
                Support[24] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de los organismos de desarrollo regionales para la I+D+i y revisión del carácter misional tanto del directorio como de los organismos', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_66_Directorio_Organis_Desarroll_Reg_I%2BD%2Bi.pdf')}>NIVAP66</Link>
                                }} />;
              } else if (resultSupport[24] > (resultSupport[24] * .5) && resultSupport[24] <= (resultSupport[24] * .7)) {
                Support[24] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de los organismos de desarrollo regionales para la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_70_Directorio_Organis_Desa_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP70</Link>
                                }} />;
              } else if (resultSupport[24] > (resultSupport[24] * .7) && resultSupport[24] <= (resultSupport[24] * 1)) {
                Support[24] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los organismos de desarrollo de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_74_An%C3%A1lisis_Grado_Solap_Organis_Desa_Reg_I%2BD%2Bi.pdf')}>NIVAP74</Link>
                                }} />;
              }
              break;
            case 'agent_structures.item_four':
              resultSupport[25] += row.supportvalue;
              if (resultSupport[25] >= 0 && resultSupport[25] <= (resultSupport[25] * .5)) {
                Support[25] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de organizaciones regionales para la transferencia de resultados de la I+D+i y revisión del carácter misional tanto del directorio como de las organizaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_67_Directorio_Organizaciones_Reg_Transferen_I%2BD%2Bi.pdf')}>NIVAP67</Link>
                                }} />;
              } else if (resultSupport[25] > (resultSupport[25] * .5) && resultSupport[25] <= (resultSupport[25] * .7)) {
                Support[25] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de organizaciones para la transferencia de resultados de la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_71_Directorio_Organizaciones_Reg_Transferen_I%2BD%2Bi_PptaMision.pdf')}>NIVAP71</Link>
                                }} />;
              } else if (resultSupport[25] > (resultSupport[25] * .7) && resultSupport[25] <= (resultSupport[25] * 1)) {
                Support[25] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las organizaciones para la transferencia de resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_75_An%C3%A1lisis_Grado_Solap_Organizaciones_Reg_Transferen_I%2BD%2Bi.pdf')}>NIVAP75</Link>
                                }} />;
              }
              break;
            case 'agent_technology.item_one':
              resultSupport[26] += row.supportvalue;
              if (resultSupport[26] >= 0 && resultSupport[26] <= (resultSupport[26] * .5)) {
                Support[26] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de plataformas virtuales regionales de apoyo a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_76_Mapa_Plataf_Virt_I%2BD%2Bi_Mision.pdf')}>NIVAP76</Link>
                                }} />;
              } else if (resultSupport[26] > (resultSupport[26] * .5) && resultSupport[26] <= (resultSupport[26] * .7)) {
                Support[26] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de plataformas virtuales regionales de apoyo a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_80_Mapa_Plataf_Virt_I%2BD%2Bi_PptaMision.pdf')}>NIVAP80</Link>
                                }} />;
              } else if (resultSupport[26] > (resultSupport[26] * .7) && resultSupport[26] <= (resultSupport[26] * 1)) {
                Support[26] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las plataformas virtuales regionales de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_84_An%C3%A1lisis_Grado_Solap_Plataf_Virt_I%2BD%2Bi.pdf')}>NIVAP84</Link>
                                }} />;
              }
              break; 
            case 'agent_technology.item_two':
              resultSupport[27] += row.supportvalue;
              if (resultSupport[27] >= 0 && resultSupport[27] <= (resultSupport[27] * .5)) {
                Support[27] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas y revisión del carácter misional tanto del mapa como de tales bibliotecas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_77_Mapa_Bibliot_Virt_PubCientyTec_Mision.pdf')}>NIVAP77</Link>
                                }} />;
              } else if (resultSupport[27] > (resultSupport[27] * .5) && resultSupport[27] <= (resultSupport[27] * .7)) {
                Support[27] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas y revisión del carácter misional tanto del mapa como de tales bibliotecas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_81_Mapa_Bibliotecas_Virt_PubCientyTec_PptaMision.pdf')}>NIVAP81</Link>
                                }} />;
              } else if (resultSupport[27] > (resultSupport[27] * .7) && resultSupport[27] <= (resultSupport[27] * 1)) {
                Support[27] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_85_An%C3%A1lisis_Grado_Solapa_Bibliot_Virt_PubCientifTec.pdf')}>NIVAP85</Link>
                                }} />;
              }
              break;
            case 'agent_technology.item_three':
              resultSupport[28] += row.supportvalue;
              if (resultSupport[28] >= 0 && resultSupport[28] <= (resultSupport[28] * .5)) {
                Support[28] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de marketplaces de I+D+i y revisión del carácter misional tanto del mapa como de tales marketplaces', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_78_Mapa_Marketplaces_I%2BD%2Bi_Mision.pdf')}>NIVAP78</Link>
                                }} />;
              } else if (resultSupport[28] > (resultSupport[28] * .5) && resultSupport[28] <= (resultSupport[28] * .7)) {
                Support[28] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de Marketplace de I+D+i y revisión del carácter misional tanto del mapa como de tales marketplace', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_82_Mapa_Marketplace_I%2BD%2Bi_PptaMision.pdf')}>NIVAP82</Link>
                                }} />;
              } else if (resultSupport[28] > (resultSupport[28] * .7) && resultSupport[28] <= (resultSupport[28] * 1)) {
                Support[28] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los marketplaces de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_86_An%C3%A1lisis_Grado_Solapa_Marketplace_I%2BD%2Bi.pdf')}>NIVAP86</Link>
                                }} />;
              }
              break;
            case 'agent_technology.item_four':
              resultSupport[29] += row.supportvalue;
              if (resultSupport[29] >= 0 && resultSupport[29] <= (resultSupport[29] * .5)) {
                Support[29] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de plataformas de crowdsourcing orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_79_Mapa_Plataf_Crowdsour_I%2BD%2Bi_Mision.pdf')}>NIVAP79</Link>
                                }} />;
              } else if (resultSupport[29] > (resultSupport[29] * .5) && resultSupport[29] <= (resultSupport[29] * .7)) {
                Support[29] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de plataformas de crowdsourcing orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_83_Mapa_Plataf_Crowdsour_I%2BD%2Bi_PptaMision.pdf')}>NIVAP83</Link>
                                }} />;
              } else if (resultSupport[29] > (resultSupport[29] * .7) && resultSupport[29] <= (resultSupport[29] * 1)) {
                Support[29] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las plataformas de crowdsourcing', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_87_An%C3%A1lisis_Grado-Solapa_Plataf_Crowdsour.pdf')}>NIVAP87</Link>
                                }} />;
              }
              break;
            case 'agent_results.item_one':
              resultSupport[30] += row.supportvalue;
              if (resultSupport[30] >= 0 && resultSupport[30] <= (resultSupport[30] * .5)) {
                Support[30] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de publicaciones regionales sobre resultados de la I+D+i y revisión del carácter misional tanto del mapa como de tales publicaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_88_directorio_Pub_Reg_Rdos_Mision.pdf')}>NIVAP88</Link>
                                }} />;
              } else if (resultSupport[30] > (resultSupport[30] * .5) && resultSupport[30] <= (resultSupport[30] * .7)) {
                Support[30] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de publicaciones regionales sobre resultados de la I+D+i y revisión del carácter misional tanto del directorio como de tales publicaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_92_Directorio_Pub_Reg_Rdos_I%2BD%2Bi_PptaMision.pdf')}>NIVAP92</Link>
                                }} />;
              } else if (resultSupport[30] > (resultSupport[30] * .7) && resultSupport[30] <= (resultSupport[30] * 1)) {
                Support[30] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las publicaciones regionales sobre resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_96_An%C3%A1lisis_Grado_Solapa_Public_Rdos_I%2BD%2Bi.pdf')}>NIVAP96</Link>
                                }} />;
              }
              break; 
            case 'agent_results.item_two':
              resultSupport[31] += row.supportvalue;
              if (resultSupport[31] >= 0 && resultSupport[31] <= (resultSupport[31] * .5)) {
                Support[31] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de sistemas de seguimiento del cumplimiento de metas regionales de I+D+i y revisión del carácter misional tanto del directorio como de tales sistemas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_89_Directorio_Sist_Seg_Cump_Metas_Reg_I%2BD%2Bi.pdf')}>NIVAP89</Link>
                                }} />;
              } else if (resultSupport[31] > (resultSupport[31] * .5) && resultSupport[31] <= (resultSupport[31] * .7)) {
                Support[31] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de sistemas de seguimiento del cumplimiento de metas regionales de I+D+i y revisión del carácter misional tanto del directorio como de tales sistemas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_93_Directorio_Sist_Seg_Cump_Metas_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP93</Link>
                                }} />;
              } else if (resultSupport[31] > (resultSupport[31] * .7) && resultSupport[31] <= (resultSupport[31] * 1)) {
                Support[31] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los sistemas de seguimiento periódico del cumplimiento de metas regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_97_An%C3%A1lisis_Grado_Solapa_Sist_Segui_Metas_I%2Bd%2Bi.pdf')}>NIVAP97</Link>
                                }} />;
              }
              break;
            case 'agent_results.item_three':
              resultSupport[32] += row.supportvalue;
              if (resultSupport[32] >= 0 && resultSupport[32] <= (resultSupport[32] * .5)) {
                Support[32] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Listado de sistemas de atención de consultas sobre transparencia en la asignación de fondos y revisión de I+D+i y análisis del carácter misional tanto del listado como de tales sistemas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_90_Listado_Sist_Atencion_Consul_Transpar_Fondos_Rdos_Mision.pdf')}>NIVAP90</Link>
                                }} />;
              } else if (resultSupport[32] > (resultSupport[32] * .5) && resultSupport[32] <= (resultSupport[32] * .7)) {
                Support[32] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Listado de portales regionales informativos sobre transparencia en la asignación de fondos y resultados de I+D+i y revisión del carácter misional tanto del listado como de tales portales', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_94_Listado_Portales_Info_Transparencia_PptaMision.pdf')}>NIVAP94</Link>
                                }} />;
              } else if (resultSupport[32] > (resultSupport[32] * .7) && resultSupport[32] <= (resultSupport[32] * 1)) {
                Support[32] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los portales de transparencia con acceso a la base de datos y la realización de consultas específicas sobre la asignación de fondos y resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_98_An%C3%A1lisis_Grado_Solapa_Portales_Transparencia_BBDD_Co.pdf')}>NIVAP98</Link>
                                }} />;
              }
              break;
            case 'agent_results.item_four':
              resultSupport[33] += row.supportvalue;
              if (resultSupport[33] >= 0 && resultSupport[33] <= (resultSupport[33] * .5)) {
                Support[33] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Listado de sistemas de información que recopilan datos y opiniones sobre la imagen que tiene la región en el contexto de I+D+i y análisis del carácter misional tanto del listado como de tales sistemas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_91_Listado_Sist_Info_Imagen_Reg_I%2BD%2Bi_Mision.pdf')}>NIVAP91</Link>
                                }} />;
              } else if (resultSupport[33] > (resultSupport[33] * .5) && resultSupport[33] <= (resultSupport[33] * .7)) {
                Support[33] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Listado de portales regionales destinados a la recopilación de información sobre la imagen que tiene la región en el contexto de I+D+i y revisión del carácter misional tanto del listado como de tales portales', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_95_Listado_Portales_Info_Imagen_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP95</Link>
                                }} />;
              } else if (resultSupport[33] > (resultSupport[33] * .7) && resultSupport[33] <= (resultSupport[33] * 1)) {
                Support[33] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los sistemas de participación y consecución de reconocimientos nacionales e internacionales para la región en el contexto de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_99_An%C3%A1lisis_Grado_Solap_Portales_Info_Imagen_Reg_I%2BD%2Bi.pdf')}>NIVAP99</Link>
                                }} />;
              }
              break;                                         
            default:
              break;
          }

          return true
        })
        
        this.setState({ supportLevels: resultSupport, support: Support  });
        
        return true
      })
      
    });
    
  }

  handlePDFClickOpen(title, url) {
    this.setState({ openPDFDialog: true, title: title, url: url });
  }

  handlePDFClickClose() {
    this.setState({ openPDFDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          { 'Análisis de Apoyo a la Gestión de Conocimiento' }
        </Typography>

        <Dialog
          fullWidth={true}
          open={this.state.openPDFDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handlePDFClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>
        
        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '20%' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '2%' }} align={'left'}>{ ' Valoración' }</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Interpretación / Recomendaciones' }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_strategy.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_strategy.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_strategy.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[0] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[0] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_strategy.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[1] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[1] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_connect.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_connect.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_connect.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[2] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[2] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_connect.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[3] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[3] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_connect.item_three_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[4] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[4] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_connect.item_four_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[5] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[5] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_sort.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_sort.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_sort.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[6] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[6] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_sort.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[7] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[7] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_sort.item_three_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[8] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[8] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_sort.item_four_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[9] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[9] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_socialize.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_socialize.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_socialize.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[10] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[10] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_socialize.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[11] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[11] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_socialize.item_three_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[12] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[12] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_socialize.item_four_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[13] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[13] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_develop.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_develop.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_develop.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[14] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[14] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_develop.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[15] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[15] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_develop.item_three_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[16] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[16] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_develop.item_four_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[17] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[17] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_policies.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_policies.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_policies.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[18] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[18] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_policies.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[19] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[19] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_policies.item_three_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[20] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[20] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_policies.item_four_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[21] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[21] }</TableCell>
              </TableRow> 

              <TableRow>
                <TableCell style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_structures.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_structures.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_structures.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[22] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[22] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_structures.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[23] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[23] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_structures.item_three_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[24] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[24] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_structures.item_four_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[25] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[25] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_technology.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_technology.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_technology.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[26] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[26] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_technology.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[27] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[27] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_technology.item_three_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[28] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[28] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_technology.item_four_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[29] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[29] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_results.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_results.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_results.item_one_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[30] }</TableCell>
                <TableCell className={ classes.colTwo }align={'left'}>{ this.state.support[30] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_results.item_two_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[31] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[31] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_results.item_three_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[32] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[32] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_results.item_four_title" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[33] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[33] }</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

Model.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Model);
/* eslint-enable no-unused-vars */