import React from 'react';
import { Switch } from "react-router-dom";
import { ProtectedRoute } from "../auth/ProtectedRoute";
import PropTypes from 'prop-types';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator';
import Header from './Header';

import ListAreas from '../areas/List';
import ListReferences from '../kit/List';
import ShowArea from '../areas/Show';
import Flow from '../kit/Flow';
import Tutorial from '../kit/Tutorial';
import Reference from '../kit/Reference';
import ListUsers from '../users/List';
import UserAccount from '../users/Account';
import Dashboard from '../dashboard';

let theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
      make: '#fff',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#fff',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'initial',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.primary.light,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#fff',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth = 256;

const styles = () => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    padding: theme.spacing(1, 2),
    //background: '#eaeff1',
    background: '#FFF',
  },
});

class MakeBase extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      spaceData: {},
      mobileOpen: false,
      value: 0
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  componentDidUpdate(prevProps, prevState) {
    //alert()
    //alert(JSON.stringify(this.props.history.location.pathname));
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <CssBaseline />
          { this.props.match.url === '/' ? null :
          <nav className={classes.drawer}>
            <Hidden smUp implementation="js">
              <Navigator
                PaperProps={{ style: { width: drawerWidth } }}
                variant="temporary"
                open={this.state.mobileOpen}
                onClose={this.handleDrawerToggle}
                history={this.props.history}
              />
            </Hidden>
            <Hidden xsDown implementation="css">
              <Navigator PaperProps={{ style: { width: drawerWidth } }} history={this.props.history} />
            </Hidden>
          </nav>
          }
          <div className={classes.appContent}>
            <Header onDrawerToggle={this.handleDrawerToggle} history={this.props.history} isHome={this.props.match.url === '/'} />
            <main className={classes.mainContent}>
            { this.props.match.url === '/' ?
            <Dashboard history={this.props.history} />
            :
            <Switch>
              <ProtectedRoute path="/areas/:id" component={ShowArea} />
              <ProtectedRoute path="/areas" component={ListAreas} />
              <ProtectedRoute path="/references" component={ListReferences} />
              <ProtectedRoute path="/model" component={Flow} />
              <ProtectedRoute path="/how" component={Tutorial} />
              <ProtectedRoute path="/reference" component={Reference} />
              <ProtectedRoute path="/users" component={ListUsers} />
              <ProtectedRoute path="/account" component={UserAccount} />
            </Switch>
            }
            </main>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

MakeBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MakeBase);
