/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import agent from './Agent'; 
import area from '../../areas/Area'; 

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
  colOne: {
    border: '1px solid #cfd8dc', color: '#37474f', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0
  },
  colTwo: {
    border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top'
  }
});

class ModelZero extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      currentTopic: '',
      currentRating: { relevance: 0, specificity: 0, control: 0, impacts: 0 },
      supportLevels: [],
      support: [],
      openPDFDialog: false,
      title: '',
      url: '',
    }

    this.handlePDFClickOpen = this.handlePDFClickOpen.bind(this);
    this.handlePDFClickClose = this.handlePDFClickClose.bind(this);

  } 

  componentDidMount() {
    var resultSupport = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    var Support = ['','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','','',''];

    agent.getList(this.props.areaId).then((result) => {

      
      result.map((row) => {
        row.support_levels.map((row) => {

          switch (row.topic) {
            case 'agent_strategy.item_one':
              resultSupport[0] += row.supportvalue;
              if (resultSupport[0]/result.length >= 0 && resultSupport[0]/result.length <= (resultSupport[0] * .5)) {
                Support[0] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP001', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP001.pdf')}>NIVAP001</Link>
                }} />;
              } else if (resultSupport[0]/result.length > (resultSupport[0] * .5) && resultSupport[0]/result.length <= (resultSupport[0] * .7)) {
                Support[0] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP002', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP002.pdf')}>NIVAP002</Link>
                }} />;
              } else if (resultSupport[0]/result.length > (resultSupport[0] * .7) && resultSupport[0]/result.length <= (resultSupport[0] * 1)) {
                Support[0] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP003', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP003.pdf')}>NIVAP003</Link>
                }} />;
              }
              break;
            case 'agent_strategy.item_two':
              resultSupport[1] += row.supportvalue;
              if (resultSupport[1] >= 0 && resultSupport[1] <= (resultSupport[1] * .5)) {
                Support[1] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP004', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP004.pdf')}>NIVAP004</Link>
                }} />;
              } else if (resultSupport[1] > (resultSupport[1] * .5) && resultSupport[1] <= (resultSupport[1] * .7)) {
                Support[1] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP005', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP005.pdf')}>NIVAP005</Link>
                }} />;
              } else if (resultSupport[1] > (resultSupport[1] * .7) && resultSupport[1] <= (resultSupport[1] * 1)) {
                Support[1] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP006', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP006.pdf')}>NIVAP006</Link>
                }} />;
              }
              break; 
            case 'agent_connect.item_one':
              resultSupport[2] += row.supportvalue;
              if (resultSupport[2] >= 0 && resultSupport[2] <= (resultSupport[2] * .5)) {
                Support[2] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP007', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP007.pdf')}>NIVAP007</Link>
                }} />;
              } else if (resultSupport[2] > (resultSupport[2] * .5) && resultSupport[2] <= (resultSupport[2] * .7)) {
                Support[2] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP008', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP008.pdf')}>NIVAP008</Link>
                }} />;
              } else if (resultSupport[2] > (resultSupport[2] * .7) && resultSupport[2] <= (resultSupport[2] * 1)) {
                Support[2] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP009', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP009.pdf')}>NIVAP009</Link>
                }} />;
              }
              break; 
            case 'agent_connect.item_two':
              resultSupport[3] += row.supportvalue;
              if (resultSupport[3] >= 0 && resultSupport[3] <= (resultSupport[3] * .5)) {
                Support[3] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP010', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP010.pdf')}>NIVAP010</Link>
                }} />;
              } else if (resultSupport[3] > (resultSupport[3] * .5) && resultSupport[3] <= (resultSupport[3] * .7)) {
                Support[3] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP011', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP011.pdf')}>NIVAP011</Link>
                }} />;
              } else if (resultSupport[3] > (resultSupport[3] * .7) && resultSupport[3] <= (resultSupport[3] * 1)) {
                Support[3] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP012', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP012.pdf')}>NIVAP012</Link>
                }} />;
              }
              break; 
            case 'agent_connect.item_three':
              resultSupport[4] += row.supportvalue;
              if (resultSupport[4] >= 0 && resultSupport[4] <= (resultSupport[4] * .5)) {
                Support[4] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP013', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP013.pdf')}>NIVAP013</Link>
                }} />;
              } else if (resultSupport[4] > (resultSupport[4] * .5) && resultSupport[4] <= (resultSupport[4] * .7)) {
                Support[4] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP014', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP014.pdf')}>NIVAP014</Link>
                }} />;
              } else if (resultSupport[4] > (resultSupport[4] * .7) && resultSupport[4] <= (resultSupport[4] * 1)) {
                Support[4] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP015', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP015.pdf')}>NIVAP015</Link>
                }} />;
              }
              break;
            case 'agent_connect.item_four':
              resultSupport[5] += row.supportvalue;
              if (resultSupport[5] >= 0 && resultSupport[5] <= (resultSupport[5] * .5)) {
                Support[5] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP016', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP016.pdf')}>NIVAP016</Link>
                }} />;
              } else if (resultSupport[5] > (resultSupport[5] * .5) && resultSupport[5] <= (resultSupport[5] * .7)) {
                Support[5] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP017', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP017.pdf')}>NIVAP017</Link>
                }} />;
              } else if (resultSupport[5] > (resultSupport[5] * .7) && resultSupport[5] <= (resultSupport[5] * 1)) {
                Support[5] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP018', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP018.pdf')}>NIVAP018</Link>
                }} />;
              }
              break;
            case 'agent_sort.item_one':
              resultSupport[6] += row.supportvalue;
              if (resultSupport[6] >= 0 && resultSupport[6] <= (resultSupport[6] * .5)) {
                Support[6] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP019', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP019.pdf')}>NIVAP019</Link>
                }} />;
              } else if (resultSupport[6] > (resultSupport[6] * .5) && resultSupport[6] <= (resultSupport[6] * .7)) {
                Support[6] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP020', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP020.pdf')}>NIVAP020</Link>
                }} />;
              } else if (resultSupport[6] > (resultSupport[6] * .7) && resultSupport[6] <= (resultSupport[6] * 1)) {
                Support[6] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP021', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP021.pdf')}>NIVAP021</Link>
                }} />;
              }
              break;
            case 'agent_sort.item_two':
              resultSupport[7] += row.supportvalue;
              if (resultSupport[7] >= 0 && resultSupport[7] <= (resultSupport[7] * .5)) {
                Support[7] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP022', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP022.pdf')}>NIVAP022</Link>
                }} />;
              } else if (resultSupport[7] > (resultSupport[7] * .5) && resultSupport[7] <= (resultSupport[7] * .7)) {
                Support[7] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP023', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP023.pdf')}>NIVAP023</Link>
                }} />;
              } else if (resultSupport[7] > (resultSupport[7] * .7) && resultSupport[7] <= (resultSupport[7] * 1)) {
                Support[7] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP024', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP024.pdf')}>NIVAP024</Link>
                }} />;
              }
              break;
            case 'agent_sort.item_three':
              resultSupport[8] += row.supportvalue;
              if (resultSupport[8] >= 0 && resultSupport[8] <= (resultSupport[8] * .5)) {
                Support[8] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP025', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP025.pdf')}>NIVAP025</Link>
                }} />;
              } else if (resultSupport[8] > (resultSupport[8] * .5) && resultSupport[8] <= (resultSupport[8] * .7)) {
                Support[8] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP026', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP026.pdf')}>NIVAP026</Link>
                }} />;
              } else if (resultSupport[8] > (resultSupport[8] * .7) && resultSupport[8] <= (resultSupport[8] * 1)) {
                Support[8] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP027', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP027.pdf')}>NIVAP027</Link>
                }} />;
              }
              break;
            case 'agent_sort.item_four':
              resultSupport[9] += row.supportvalue;
              if (resultSupport[9] >= 0 && resultSupport[9] <= (resultSupport[9] * .5)) {
                Support[9] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP028', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP028.pdf')}>NIVAP028</Link>
                }} />;
              } else if (resultSupport[9] > (resultSupport[9] * .5) && resultSupport[9] <= (resultSupport[9] * .7)) {
                Support[9] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP029', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP029.pdf')}>NIVAP029</Link>
                }} />;
              } else if (resultSupport[9] > (resultSupport[9] * .7) && resultSupport[9] <= (resultSupport[9] * 1)) {
                Support[9] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP030', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP030.pdf')}>NIVAP030</Link>
                }} />;
              }
              break;
            case 'agent_socialize.item_one':
              resultSupport[10] += row.supportvalue;
              if (resultSupport[10] >= 0 && resultSupport[10] <= (resultSupport[10] * .5)) {
                Support[10] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP031', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP031.pdf')}>NIVAP031</Link>
                }} />;
              } else if (resultSupport[10] > (resultSupport[10] * .5) && resultSupport[10] <= (resultSupport[10] * .7)) {
                Support[10] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP032', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP032.pdf')}>NIVAP032</Link>
                }} />;
              } else if (resultSupport[10] > (resultSupport[10] * .7) && resultSupport[10] <= (resultSupport[10] * 1)) {
                Support[10] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP033', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP033.pdf')}>NIVAP033</Link>
                }} />;
              }
              break; 
            case 'agent_socialize.item_two':
              resultSupport[11] += row.supportvalue;
              if (resultSupport[11] >= 0 && resultSupport[11] <= (resultSupport[11] * .5)) {
                Support[11] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP034', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP034.pdf')}>NIVAP034</Link>
                }} />;
              } else if (resultSupport[11] > (resultSupport[10] * .5) && resultSupport[11] <= (resultSupport[11] * .7)) {
                Support[11] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP035', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP035.pdf')}>NIVAP035</Link>
                }} />;
              } else if (resultSupport[11] > (resultSupport[10] * .7) && resultSupport[11] <= (resultSupport[11] * 1)) {
                Support[11] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP036', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP036.pdf')}>NIVAP036</Link>
                }} />;
              }
              break; 
            case 'agent_socialize.item_three':
              resultSupport[12] += row.supportvalue;
              resultSupport[12] += row.supportvalue;
              if (resultSupport[12] >= 0 && resultSupport[12] <= (resultSupport[12] * .5)) {
                Support[12] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP037', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP037.pdf')}>NIVAP037</Link>
                }} />;
              } else if (resultSupport[12] > (resultSupport[12] * .5) && resultSupport[12] <= (resultSupport[12] * .7)) {
                Support[12] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP038', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP038.pdff')}>NIVAP038</Link>
                }} />;
              } else if (resultSupport[12] > (resultSupport[12] * .7) && resultSupport[12] <= (resultSupport[12] * 1)) {
                Support[12] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP039', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP039.pdf')}>NIVAP039</Link>
                }} />;
              }
              break; 
            case 'agent_socialize.item_four':
              resultSupport[13] += row.supportvalue;
              if (resultSupport[13] >= 0 && resultSupport[13] <= (resultSupport[13] * .5)) {
                Support[13] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP040', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP040.pdf')}>NIVAP040</Link>
                }} />;
              } else if (resultSupport[13] > (resultSupport[13] * .5) && resultSupport[13] <= (resultSupport[13] * .7)) {
                Support[13] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP041', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP041.pdf')}>NIVAP041</Link>
                }} />;
              } else if (resultSupport[13] > (resultSupport[13] * .7) && resultSupport[13] <= (resultSupport[13] * 1)) {
                Support[13] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP042', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP042.pdf')}>NIVAP042</Link>
                }} />;
              }
              break;
            case 'agent_develop.item_one':
              resultSupport[14] += row.supportvalue;
              if (resultSupport[14] >= 0 && resultSupport[14] <= (resultSupport[14] * .5)) {
                Support[14] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP043', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP043.pdf')}>NIVAP043</Link>
                }} />;
              } else if (resultSupport[14] > (resultSupport[14] * .5) && resultSupport[14] <= (resultSupport[14] * .7)) {
                Support[14] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP044', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP044.pdf')}>NIVAP044</Link>
                }} />;
              } else if (resultSupport[14] > (resultSupport[14] * .7) && resultSupport[14] <= (resultSupport[14] * 1)) {
                Support[14] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP045', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP045.pdf')}>NIVAP045</Link>
                }} />;
              }
              break;
            case 'agent_develop.item_two':
              resultSupport[15] += row.supportvalue;
              if (resultSupport[15] >= 0 && resultSupport[15] <= (resultSupport[15] * .5)) {
                Support[15] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP046', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP046.pdf')}>NIVAP046</Link>
                }} />;
              } else if (resultSupport[15] > (resultSupport[15] * .5) && resultSupport[15] <= (resultSupport[15] * .7)) {
                Support[15] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP047', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP047.pdf')}>NIVAP047</Link>
                }} />;
              } else if (resultSupport[15] > (resultSupport[15] * .7) && resultSupport[15] <= (resultSupport[15] * 1)) {
                Support[15] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP048', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP048.pdf')}>NIVAP048</Link>
                }} />;
              }
              break;
            case 'agent_develop.item_three':
              resultSupport[16] += row.supportvalue;
              if (resultSupport[16] >= 0 && resultSupport[16] <= (resultSupport[16] * .5)) {
                Support[16] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP049', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP049.pdf')}>NIVAP049</Link>
                }} />;
              } else if (resultSupport[16] > (resultSupport[16] * .5) && resultSupport[16] <= (resultSupport[16] * .7)) {
                Support[16] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP050', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP050.pdf')}>NIVAP050</Link>
                }} />;
              } else if (resultSupport[16] > (resultSupport[16] * .7) && resultSupport[16] <= (resultSupport[16] * 1)) {
                Support[16] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP051', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP051.pdf')}>NIVAP051</Link>
                }} />;
              }
              break;
            case 'agent_develop.item_four':
              resultSupport[17] += row.supportvalue;
              if (resultSupport[17] >= 0 && resultSupport[17] <= (resultSupport[17] * .5)) {
                Support[17] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP052', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP052.pdf')}>NIVAP052</Link>
                }} />;
              } else if (resultSupport[17] > (resultSupport[17] * .5) && resultSupport[17] <= (resultSupport[17] * .7)) {
                Support[17] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP053', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP053.pdf')}>NIVAP053</Link>
                }} />;
              } else if (resultSupport[17] > (resultSupport[17] * .7) && resultSupport[17] <= (resultSupport[17] * 1)) {
                Support[17] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP054', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP054.pdf')}>NIVAP054</Link>
                }} />;
              }
              break;
            case 'agent_policies.item_one':
              resultSupport[18] += row.supportvalue;
              if (resultSupport[18] >= 0 && resultSupport[18] <= (resultSupport[18] * .5)) {
                Support[18] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP055', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP055.pdf')}>NIVAP055</Link>
                }} />;
              } else if (resultSupport[18] > (resultSupport[18] * .5) && resultSupport[18] <= (resultSupport[18] * .7)) {
                Support[18] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP056', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP056.pdf')}>NIVAP056</Link>
                }} />;
              } else if (resultSupport[18] > (resultSupport[18] * .7) && resultSupport[18] <= (resultSupport[18] * 1)) {
                Support[18] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP057', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP057.pdf')}>NIVAP057</Link>
                }} />;
              }
              break;
            case 'agent_policies.item_two':
              resultSupport[19] += row.supportvalue;
              if (resultSupport[19] >= 0 && resultSupport[19] <= (resultSupport[19] * .5)) {
                Support[19] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP058', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP058.pdf')}>NIVAP058</Link>
                }} />;
              } else if (resultSupport[19] > (resultSupport[19] * .5) && resultSupport[19] <= (resultSupport[19] * .7)) {
                Support[19] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP059', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP059.pdf')}>NIVAP059</Link>
                }} />;
              } else if (resultSupport[19] > (resultSupport[19] * .7) && resultSupport[19] <= (resultSupport[19] * 1)) {
                Support[19] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP060', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP060.pdf')}>NIVAP060</Link>
                }} />;
              }
              break; 
            case 'agent_policies.item_three':
              resultSupport[20] += row.supportvalue;
              if (resultSupport[20] >= 0 && resultSupport[20] <= (resultSupport[20] * .5)) {
                Support[20] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP061', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP061.pdf')}>NIVAP061</Link>
                }} />;
              } else if (resultSupport[20] > (resultSupport[20] * .5) && resultSupport[20] <= (resultSupport[20] * .7)) {
                Support[20] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP062', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP062.pdf')}>NIVAP062</Link>
                }} />;
              } else if (resultSupport[20] > (resultSupport[20] * .7) && resultSupport[20] <= (resultSupport[20] * 1)) {
                Support[20] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP063', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP063.pdf')}>NIVAP063</Link>
                }} />;
              }
              break; 
            case 'agent_policies.item_four':
              resultSupport[21] += row.supportvalue;
              if (resultSupport[21] >= 0 && resultSupport[21] <= (resultSupport[21] * .5)) {
                Support[21] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP064', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP064.pdf')}>NIVAP064</Link>
                }} />;
              } else if (resultSupport[21] > (resultSupport[21] * .5) && resultSupport[21] <= (resultSupport[21] * .7)) {
                Support[21] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP065', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP065.pdf')}>NIVAP065</Link>
                }} />;
              } else if (resultSupport[21] > (resultSupport[21] * .7) && resultSupport[21] <= (resultSupport[21] * 1)) {
                Support[21] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP066', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP066.pdf')}>NIVAP066</Link>
                }} />;
              }
              break; 
            case 'agent_structures.item_one':
              resultSupport[22] += row.supportvalue;
              if (resultSupport[22] >= 0 && resultSupport[22] <= (resultSupport[22] * .5)) {
                Support[22] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP067', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP067.pdf')}>NIVAP067</Link>
                }} />;
              } else if (resultSupport[22] > (resultSupport[22] * .5) && resultSupport[22] <= (resultSupport[22] * .7)) {
                Support[22] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP068', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP068.pdf')}>NIVAP068</Link>
                }} />;
              } else if (resultSupport[22] > (resultSupport[22] * .7) && resultSupport[22] <= (resultSupport[22] * 1)) {
                Support[22] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP069', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP069.pdf')}>NIVAP069</Link>
                }} />;
              }
              break;
            case 'agent_structures.item_two':
              resultSupport[23] += row.supportvalue;
              if (resultSupport[23] >= 0 && resultSupport[23] <= (resultSupport[23] * .5)) {
                Support[23] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP070', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP070.pdf')}>NIVAP070</Link>
                }} />;
              } else if (resultSupport[23] > (resultSupport[23] * .5) && resultSupport[23] <= (resultSupport[23] * .7)) {
                Support[23] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP071', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP071.pdf')}>NIVAP071</Link>
                }} />;
              } else if (resultSupport[23] > (resultSupport[23] * .7) && resultSupport[23] <= (resultSupport[23] * 1)) {
                Support[23] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP072', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP072.pdf')}>NIVAP072</Link>
                }} />;
              }
              break;
            case 'agent_structures.item_three':
              resultSupport[24] += row.supportvalue;
              if (resultSupport[24] >= 0 && resultSupport[24] <= (resultSupport[24] * .5)) {
                Support[24] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP073', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP073.pdf')}>NIVAP073</Link>
                }} />;
              } else if (resultSupport[24] > (resultSupport[24] * .5) && resultSupport[24] <= (resultSupport[24] * .7)) {
                Support[24] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP074', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP074.pdf')}>NIVAP074</Link>
                }} />;
              } else if (resultSupport[24] > (resultSupport[24] * .7) && resultSupport[24] <= (resultSupport[24] * 1)) {
                Support[24] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP075', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP075.pdf')}>NIVAP075</Link>
                }} />;
              }
              break;
            case 'agent_structures.item_four':
              resultSupport[25] += row.supportvalue;
              if (resultSupport[25] >= 0 && resultSupport[25] <= (resultSupport[25] * .5)) {
                Support[25] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP076', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP076.pdf')}>NIVAP076</Link>
                }} />;
              } else if (resultSupport[25] > (resultSupport[25] * .5) && resultSupport[25] <= (resultSupport[25] * .7)) {
                Support[25] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP077', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP077.pdf')}>NIVAP077</Link>
                }} />;
              } else if (resultSupport[25] > (resultSupport[25] * .7) && resultSupport[25] <= (resultSupport[25] * 1)) {
                Support[25] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP078', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP078.pdf')}>NIVAP078</Link>
                }} />;
              }
              break;
            case 'agent_technology.item_one':
              resultSupport[26] += row.supportvalue;
              if (resultSupport[26] >= 0 && resultSupport[26] <= (resultSupport[26] * .5)) {
                Support[26] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP079', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP079.pdf')}>NIVAP079</Link>
                }} />;
              } else if (resultSupport[26] > (resultSupport[26] * .5) && resultSupport[26] <= (resultSupport[26] * .7)) {
                Support[26] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP080', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP080.pdf')}>NIVAP080</Link>
                }} />;
              } else if (resultSupport[26] > (resultSupport[26] * .7) && resultSupport[26] <= (resultSupport[26] * 1)) {
                Support[26] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP081', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP081.pdf')}>NIVAP081</Link>
                }} />;
              }
              break; 
            case 'agent_technology.item_two':
              resultSupport[27] += row.supportvalue;
              if (resultSupport[27] >= 0 && resultSupport[27] <= (resultSupport[27] * .5)) {
                Support[27] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP082', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP082.pdf')}>NIVAP082</Link>
                }} />;
              } else if (resultSupport[27] > (resultSupport[27] * .5) && resultSupport[27] <= (resultSupport[27] * .7)) {
                Support[27] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP083', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP083.pdf')}>NIVAP083</Link>
                }} />;
              } else if (resultSupport[27] > (resultSupport[27] * .7) && resultSupport[27] <= (resultSupport[27] * 1)) {
                Support[27] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP084', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP084.pdf')}>NIVAP084</Link>
                }} />;
              }
              break;
            case 'agent_technology.item_three':
              resultSupport[28] += row.supportvalue;
              if (resultSupport[28] >= 0 && resultSupport[28] <= (resultSupport[28] * .5)) {
                Support[28] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP085', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP085.pdf')}>NIVAP085</Link>
                }} />;
              } else if (resultSupport[28] > (resultSupport[28] * .5) && resultSupport[28] <= (resultSupport[28] * .7)) {
                Support[28] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP086', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP086.pdf')}>NIVAP086</Link>
                }} />;
              } else if (resultSupport[28] > (resultSupport[28] * .7) && resultSupport[28] <= (resultSupport[28] * 1)) {
                Support[28] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP087', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP087.pdf')}>NIVAP087</Link>
                }} />;
              }
              break;
            case 'agent_technology.item_four':
              resultSupport[29] += row.supportvalue;
              if (resultSupport[29] >= 0 && resultSupport[29] <= (resultSupport[29] * .5)) {
                Support[29] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP088', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP088.pdf')}>NIVAP088</Link>
                }} />;
              } else if (resultSupport[29] > (resultSupport[29] * .5) && resultSupport[29] <= (resultSupport[29] * .7)) {
                Support[29] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP089', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP089.pdf')}>NIVAP089</Link>
                }} />;
              } else if (resultSupport[29] > (resultSupport[29] * .7) && resultSupport[29] <= (resultSupport[29] * 1)) {
                Support[29] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP090', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP090.pdf')}>NIVAP090</Link>
                }} />;
              }
              break;
            case 'agent_results.item_one':
              resultSupport[30] += row.supportvalue;
              if (resultSupport[30] >= 0 && resultSupport[30] <= (resultSupport[30] * .5)) {
                Support[30] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP091', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP091.pdf')}>NIVAP091</Link>
                }} />;
              } else if (resultSupport[30] > (resultSupport[30] * .5) && resultSupport[30] <= (resultSupport[30] * .7)) {
                Support[30] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP092', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP092.pdf')}>NIVAP092</Link>
                }} />;
              } else if (resultSupport[30] > (resultSupport[30] * .7) && resultSupport[30] <= (resultSupport[30] * 1)) {
                Support[30] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP093', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP093.pdf')}>NIVAP093</Link>
                }} />;
              }
              break; 
            case 'agent_results.item_two':
              resultSupport[31] += row.supportvalue;
              if (resultSupport[31] >= 0 && resultSupport[31] <= (resultSupport[31] * .5)) {
                Support[31] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP094', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP094.pdf')}>NIVAP094</Link>
                }} />;
              } else if (resultSupport[31] > (resultSupport[31] * .5) && resultSupport[31] <= (resultSupport[31] * .7)) {
                Support[31] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP095', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP095.pdf')}>NIVAP095</Link>
                }} />;
              } else if (resultSupport[31] > (resultSupport[31] * .7) && resultSupport[31] <= (resultSupport[31] * 1)) {
                Support[31] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP096', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP096.pdf')}>NIVAP096</Link>
                }} />;
              }
              break;
            case 'agent_results.item_three':
              resultSupport[32] += row.supportvalue;
              if (resultSupport[32] >= 0 && resultSupport[32] <= (resultSupport[32] * .5)) {
                Support[32] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP097', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP097.pdf')}>NIVAP097</Link>
                }} />;
              } else if (resultSupport[32] > (resultSupport[32] * .5) && resultSupport[32] <= (resultSupport[32] * .7)) {
                Support[32] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP098', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP098.pdf')}>NIVAP098</Link>
                }} />;
              } else if (resultSupport[32] > (resultSupport[32] * .7) && resultSupport[32] <= (resultSupport[32] * 1)) {
                Support[32] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP099', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP099.pdf')}>NIVAP099</Link>
                }} />;
              }
              break;
            case 'agent_results.item_four':
              resultSupport[33] += row.supportvalue;
              if (resultSupport[33] >= 0 && resultSupport[33] <= (resultSupport[33] * .5)) {
                Support[33] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_one_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP0100', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP0100.pdf')}>NIVAP0100</Link>
                }} />;
              } else if (resultSupport[33] > (resultSupport[33] * .5) && resultSupport[33] <= (resultSupport[33] * .7)) {
                Support[33] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP0101', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP0101.pdf')}>NIVAP0101</Link>
                }} />;
              } else if (resultSupport[33] > (resultSupport[33] * .7) && resultSupport[33] <= (resultSupport[33] * 1)) {
                Support[33] = <FormattedMessage id={ 'agent_strategy.item_one_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('NIVAP0102', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP0102.pdf')}>NIVAP0102</Link>
                }} />;
              }
              break;                                         
            default:
              break;
          }

          return true
        })
        
        this.setState({ supportLevels: resultSupport, support: Support  });
        
        return true
      })
      
    });
    
  } 

  handlePDFClickOpen(title, url) {
    this.setState({ openPDFDialog: true, title: title, url: url });
  }

  handlePDFClickClose() {
    this.setState({ openPDFDialog: false, title: '', url: '' });
  }
  
  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          { 'Análisis de Apoyo a la Gestión de Conocimiento' }
        </Typography>

        <Dialog
          fullWidth={true}
          open={this.state.openPDFDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handlePDFClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>
        
        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '20%' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '2%' }} align={'left'}>{ ' Valoración' }</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Interpretación / Recomendaciones' }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_strategy.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_strategy.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_strategy.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[0] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[0] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_strategy.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[1] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[1] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_connect.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_connect.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_connect.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[2] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[2] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_connect.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[3] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[3] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_connect.item_three_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[4] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[4] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_connect.item_four_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[5] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[5] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_sort.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_sort.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_sort.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[6] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[6] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_sort.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[7] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[7] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_sort.item_three_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[8] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[8] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_sort.item_four_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[9] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[9] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_socialize.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_socialize.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_socialize.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[10] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[10] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_socialize.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[11] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[11] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_socialize.item_three_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[12] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[12] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_socialize.item_four_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[13] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[13] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_develop.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_develop.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_develop.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[14] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[14] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_develop.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[15] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[15] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_develop.item_three_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[16] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[16] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_develop.item_four_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[17] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[17] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_policies.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_policies.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_policies.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[18] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[18] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_policies.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[19] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[19] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_policies.item_three_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[20] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[20] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_policies.item_four_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[21] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[21] }</TableCell>
              </TableRow> 

              <TableRow>
                <TableCell style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_structures.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_structures.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_structures.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[22] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[22] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_structures.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[23] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[23] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_structures.item_three_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[24] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[24] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_structures.item_four_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[25] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[25] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_technology.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_technology.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_technology.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[26] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[26] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_technology.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[27] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[27] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_technology.item_three_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[28] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[28] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_technology.item_four_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[29] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[29] }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_results.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_results.title_desc" /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_results.item_one_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[30] }</TableCell>
                <TableCell className={ classes.colTwo }align={'left'}>{ this.state.support[30] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_results.item_two_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[31] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[31] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_results.item_three_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[32] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[32] }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="agent_results.item_four_title_lz" /></TableCell>
                <TableCell className={ classes.colOne } align={'left'}>{ this.state.supportLevels[33] }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.state.support[33] }</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

ModelZero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModelZero);
/* eslint-enable no-unused-vars */