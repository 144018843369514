/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../MakeProvider';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Header from './Header';
import { FormattedMessage, FormattedTime } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 0,
  },
});

class Flow extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      areaData: {},
      value: 0,
      openDialog: false
    }

  } 

  componentDidMount() {

  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>
        <Header title={ <FormattedMessage id="kit.model" />} />
        <img src="/flow.png" alt="Make" width="100%" />
      </React.Fragment>
    );
  }
}

Flow.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Flow);
/* eslint-enable no-unused-vars */