/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, FormattedTime } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 0,
  },
  colOne: {
    cursor: 'pointer'
  }
});

class ReferenceOne extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      areaData: {},
      value: 0,
      openDialog: false,
      title: '',
      url: ''
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);

  } 

  componentDidMount() {

  }

  handleClickOpen(title, url) {
    this.setState({ openDialog: true, title: title, url: url });
  }

  handleClickClose() {
    this.setState({ openDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Dialog
          fullWidth={true}
          open={this.state.openDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handleClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Agentes Dinamizadores</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>Conceptos clave</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Estrategia Regional</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de agentes regionales dinamizadores de la GC y  la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_01_Mapa_Agent_Dinamiz_GC_I%2BD%2BI_Mision.pdf')}>Mapa de agentes regionales dinamizadores de la GC y  la I+D+i y su revisión del carácter misional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de agentes regionales dinamizadores de la GC y la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_02_Mapa_Agent_Dinamiz_GC_I%2BD%2Bi_Propuesta_Mision.pdf')}>Mapa de agentes regionales dinamizadores de la GC y la I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de acción de agentes regionales dinamizadores de la GC para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_03_An%C3%A1lisis_Grado_Solapam_Acci%C3%B3n_Agentes_Reg_GCI%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de acción de agentes regionales dinamizadores de la GC para la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Conectar</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas regionales relativas a observatorios de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_04_Mapa_Inic_Reg_Observ_Mision.pdf')}>Mapa de iniciativas regionales relativas a observatorios de I+D+i y su revisión del carácter misional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas regionales relativas a vigilancia tecnológica y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_05_Mapa_Inic_VT_Mision.pdf')}>Mapa de iniciativas regionales relativas a vigilancia tecnológica y su revisión del carácter misional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas regionales relativas a comunicación de la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_06_Mapa_Inic_Comunic_I%2BD%2Bi_Mision.pdf')}>Mapa de iniciativas regionales relativas a comunicación de la I+D+i y su revisión del carácter misional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Inventario de publicaciones regionales de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_07_Inventario_Pub_Reg_Mision.pdf')}>Inventario de publicaciones regionales de I+D+i y revisión de su carácter misional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas regionales relativas a observatorios de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_08_Mapa_Inici_Reg_Observ_PptaMision.pdf')}>Mapa de iniciativas regionales relativas a observatorios de I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas regionales relativas a vigilancia tecnológica e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_09_Mapa_Inici_Reg_VT_PptaMision.pdf')}>Mapa de iniciativas regionales relativas a vigilancia tecnológica e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas regionales relativas a comunicación de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_10_Mapa_Inici_Reg_ComI%2BD%2Bi_PptaMision.pdf')}>Mapa de iniciativas regionales relativas a comunicación de I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Inventario de publicaciones regionales de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_11_Inventario_Pub_Reg_I%2BD%2Bi_PptaMision.pdf')}>Inventario de publicaciones regionales de I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a observatorios de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_12_An%C3%A1lisis_Grado_Solapam_Inicia_Reg_Observat_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las iniciativas regionales relativas a observatorios de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a vigilancia tecnológica', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_13_An%C3%A1lisis_Grado_Solap_VT_Reg.pdf')}>Análisis del grado de solapamiento de las iniciativas regionales relativas a vigilancia tecnológica</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a comunicación de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_14_An%C3%A1lisis_Grado_Solapa_Comunic_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las iniciativas regionales relativas a comunicación de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las publicaciones regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_15_An%C3%A1lisis_Grado_Solap_Pub_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las publicaciones regionales de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Ordenar</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de sitios web regionales de consulta sobre I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_16_Mapa_Sitios_Web_Consulta_Mision.pdf')}>Mapa de sitios web regionales de consulta sobre I+D+i y su revisión del carácter misional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de organizaciones (no empresas) regionales de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_17_Directorio_Org_I%2BD%2Bi_Mision.pdf')}>Directorio de organizaciones (no empresas) regionales de I+D+i y su revisión del carácter misional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de empresas regionales de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_18_Directorio_Emp_I%2BD%2Bi_Reg_Mision.pdf')}>Directorio de empresas regionales de I+D+i y su revisión del carácter misional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de grupos de investigación regionales de I+D+i y revisión del carácter misional tanto del directorio como de los grupos', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_19_Directorio_grupos_Inv_Mision.pdf')}>Directorio de grupos de investigación regionales de I+D+i y revisión del carácter misional tanto del directorio como de los grupos</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de sitios web regionales de consulta sobre I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_20_Mapa_Sitiosweb_Reg_I%2BD%2Bi_PptaMision.pdf')}>Mapa de sitios web regionales de consulta sobre I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de organizaciones (no empresas) regionales de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_21_Directorio_Org_Reg_I%2BD%2Bi_PptaMision.pdf')}>Directorio de organizaciones (no empresas) regionales de I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de empresas regionales de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_22_Directorio_Emp_Reg_I%2BD%2Bi_PptaMision.pdf')}>Directorio de empresas regionales de I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de grupos de investigación regionales e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_23_Directorio_GruposInvest_PptaMision.pdf')}>Directorio de grupos de investigación regionales e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de los sitios web regionales de consulta sobre I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_24_An%C3%A1lisis_Grado_Solap_Sitios_Web_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de los sitios web regionales de consulta sobre I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las organizaciones (no empresas) regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_25_An%C3%A1lisis_Grado_Solap_Org_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las organizaciones (no empresas) regionales de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las empresas regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_26_An%C3%A1lisis_Grado_Solap_Emp_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las empresas regionales de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de los grupos de investigación regionales de orientación hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_27_An%C3%A1lisis_Grado_Solap_Grupos_Inv_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de los grupos de investigación regionales de orientación hacia la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Socializar</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de clusters regionales orientados a la I+D+i y revisión del carácter misional tanto del mapa como de los clusters', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_28_Mapa_Clusters_I%2BD%2Bi_Mision.pdf')}>Mapa de clusters regionales orientados a la I+D+i y revisión del carácter misional tanto del mapa como de los clusters</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de agrupaciones empresariales para la I+D+i y revisión del carácter misional tanto del mapa como de las agrupaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_29_Mapa_Agrup_Emp_Mision.pdf')}>Mapa de agrupaciones empresariales para la I+D+i y revisión del carácter misional tanto del mapa como de las agrupaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de mesas de trabajo para la I+D+i y revisión del carácter misional tanto del mapa como de las mesas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_30_Mapa_Mesas_Trabajo_I%2BD%2Bi_Mision.pdf')}>Mapa de mesas de trabajo para la I+D+i y revisión del carácter misional tanto del mapa como de las mesas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de asociaciones y fundaciones regionales orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de las asociaciones y fundaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_31_Mapa_Asoc_Fundac_I%2BD%2Bi_Mision.pdf')}>Mapa de asociaciones y fundaciones regionales orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de las asociaciones y fundaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de clusters regionales orientados a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_32_Mapa_clusters_I%2BD%2Bi_PptaMision.pdf')}>Mapa de clusters regionales orientados a la I+D+i e interpretación de su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de agrupaciones empresariales para la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_33_Mapa_AgrupEmp_I%2BD%2Bi_PptaMision.pdf')}>Mapa de agrupaciones empresariales para la I+D+i e interpretación de su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de mesas de trabajo para la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_34_Mapa_Mesas_Trabajo_Reg_I%2BD%2Bi_PptaMision.pdf')}>Mapa de mesas de trabajo para la I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de asociaciones y fundaciones regionales orientadas a la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/Ficha_NIVAP_35_Mapa_Asoc_Fundac_Reg_I%2BD%2Bi_PptaMision.pdf')}>Mapa de asociaciones y fundaciones regionales orientadas a la I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de clusters regionales orientados a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_36_An%C3%A1lisis_Grado_Solapa_Clusters_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de clusters regionales orientados a la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las agrupaciones empresariales para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_37_An%C3%A1lisis_Grado_Solapa_Agrup_Emp_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las agrupaciones empresariales para la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las mesas de trabajo regionales orientadas hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_38_An%C3%A1lisis_Grado_Solapa_Mesas_Trabajo_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las mesas de trabajo regionales orientadas hacia la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las asociaciones y fundaciones regionales orientadas hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_39_An%C3%A1lisis_Grado_Solapa_Asoc_Fundac_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las asociaciones y fundaciones regionales orientadas hacia la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Desarrollar</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de titulaciones universitarias (grados) disponibles en la región con perfil de I+D+i y revisión del carácter misional tanto del mapa como de las titulaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_40_Mapa_Titulac_Reg_Mision.pdf')}>Mapa de titulaciones universitarias (grados) disponibles en la región con perfil de I+D+i y revisión del carácter misional tanto del mapa como de las titulaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de titulaciones de posgrados disponibles en la región con perfil de I+D+i y revisión del carácter misional tanto del mapa como de las titulaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_41_Mapa_Titulac_Posgrado_Reg_Mision.pdf')}>Mapa de titulaciones de posgrados disponibles en la región con perfil de I+D+i y revisión del carácter misional tanto del mapa como de las titulaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas de apoyo público regional a la I+D+i y revisión del carácter misional tanto del mapa como de las iniciativas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_42_Mapa_Iniciativas_Pub_I%2BD%2Bi_Mision.pdf')}>Mapa de iniciativas de apoyo público regional a la I+D+i y revisión del carácter misional tanto del mapa como de las iniciativas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas de apoyo público regional al emprendimiento innovador y revisión del carácter misional tanto del mapa como de las iniciativas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_43_Mapa_Inic_Apoyo_Publico_Empren_Innov_Mision.pdf')}>Mapa de iniciativas de apoyo público regional al emprendimiento innovador y revisión del carácter misional tanto del mapa como de las iniciativas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de titulaciones universitarias (grados) impartidas en la región y revisión del carácter misional tanto del mapa como de tales titulaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_44_Mapa_Titulac_Grados_Reg_PptaMision.pdf')}>Mapa de titulaciones universitarias (grados) impartidas en la región y revisión del carácter misional tanto del mapa como de tales titulaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de titulaciones de posgrado impartidas en la región y revisión del carácter misional tanto del mapa como de tales titulaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_45_Mapa_Titulac_Posgrado_Reg_PptaMision.pdf')}>Mapa de titulaciones de posgrado impartidas en la región y revisión del carácter misional tanto del mapa como de tales titulaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas de apoyo púbico regional a la I+D+i y revisión del carácter misional tanto del mapa como de tales iniciativas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_46_Mapa_Inici_Apoy_Pub_I%2BD%2BiReg_PpatMision.pdf')}>Mapa de iniciativas de apoyo púbico regional a la I+D+i y revisión del carácter misional tanto del mapa como de tales iniciativas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de iniciativas de apoyo púbico regional al emprendimiento innovador y revisión del carácter misional tanto del mapa como de tales iniciativas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_47_Mapa_Apoyo_Pub_Reg_EmpInnovador_PpatMision.pdf')}>Mapa de iniciativas de apoyo púbico regional al emprendimiento innovador y revisión del carácter misional tanto del mapa como de tales iniciativas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las titulaciones universitarias (grados) impartidas en la región', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_48_An%C3%A1lisis_Grado_Solapa_Titulac_Univer_Reg.pdf')}>Análisis del grado de solapamiento de las titulaciones universitarias (grados) impartidas en la región</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las titulaciones posgrado impartidas en la región', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_49_An%C3%A1lisis_Grado_Solapa_Titulac_Posgrado_Reg.pdf')}>Análisis del grado de solapamiento de las titulaciones posgrado impartidas en la región</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las iniciativas de apoyo público a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_50_An%C3%A1lisis_Grado_Solapa_Inici_Apoyo_Pub_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las iniciativas de apoyo público a la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las iniciativas de apoyo público al emprendimiento innovador', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_51_An%C3%A1lisis_Grado_Solapa_Inici_Empren_Innov.pdf')}>Análisis del grado de solapamiento de las iniciativas de apoyo público al emprendimiento innovador</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Políticas</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis de las convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación) y revisión del carácter misional tanto del directorio como de tales convocatorias', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_52_An%C3%A1lisis_Convoca_Competitiva_Reg_Apoyo_I%2BD%2Bi.pdf')}>Análisis de las convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación) y revisión del carácter misional tanto del directorio como de tales convocatorias</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis de las opciones de subvenciones regionales de la I+D+i y revisión del carácter misional de tales líneas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_53_An%C3%A1lisis_Opciones_Subvenci%C3%B3n_I%2BD%2Bi.pdf')}>Análisis de las opciones de subvenciones regionales de la I+D+i y revisión del carácter misional de tales líneas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de líneas de crédito reembolsables de apoyo a la I+D+i y revisión del carácter misional tanto del directorio como de las líneas de crédito', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_54_Directorio_Lineas_CredReemb_I%2BD%2Bi_Mision.pdf')}>Directorio de líneas de crédito reembolsables de apoyo a la I+D+i y revisión del carácter misional tanto del directorio como de las líneas de crédito</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del número de solicitudes regionales de propiedad intelectual e industrial', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_55_An%C3%A1lisis_Solicit_Reg_Prop_Intelec_Indus.pdf')}>Análisis del número de solicitudes regionales de propiedad intelectual e industrial </TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación) y revisión del carácter misional tanto del directorio como de tales convocatorias', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_56_Directorio_Convoc_Compet_Cofinanc_PptaMision.pdf')}>Directorio de convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación) y revisión del carácter misional tanto del directorio como de tales convocatorias</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de subvenciones regionales de la I+D+i y revisión del carácter misional tanto del directorio como de tales líneas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_57_Directorio_Subvenc_I%2BD%2Bi_PptaMision.pdf')}>Directorio de subvenciones regionales de la I+D+i y revisión del carácter misional tanto del directorio como de tales líneas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de líneas de crédito reembolsables para el apoyo regional a la I+D+i y revisión del carácter misional tanto del directorio como de tales líneas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_58_Directorio_L%C3%ADneas_CredReem_I%2BD%2Bi_PptaMision.pdf')}>Directorio de líneas de crédito reembolsables para el apoyo regional a la I+D+i y revisión del carácter misional tanto del directorio como de tales líneas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de solicitudes de propiedad intelectual e industrial y revisión del rol institucional para la realización de esta labor', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_59_Directorio_Solicitud_Propiedad_Intelec_Rol_Institu.pdf')}>Directorio de solicitudes de propiedad intelectual e industrial y revisión del rol institucional para la realización de esta labor</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación)', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_60_An%C3%A1lisis_Grado_Solapa_Convoc_Compe_Reg_Cofinanc.pdf')}>Análisis del grado de solapamiento de las convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las subvenciones regionales de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_61_An%C3%A1lisis_Grado_Solapa_Subvencion.pdf')}>Análisis del grado de solapamiento de las subvenciones regionales de la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las líneas de crédito reembolsables para el apoyo regional a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_62_An%C3%A1lisis_Grado_Solapa_CredReemb_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las líneas de crédito reembolsables para el apoyo regional a la I+D+i </TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis de los resultados de solicitudes regionales de propiedad intelectual e industrial', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_63_An%C3%A1lisis_Grado_Solapa_Solicitu_Propie_Intelec.pdf')}>Análisis de los resultados de solicitudes regionales de propiedad intelectual e industrial </TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Estructuras</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de los consejos y comités estratégicos regionales para la I+D+i y revisión del carácter misional tanto del directorio como de los consejos y comités', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_64_Directorio_Consejos_Comit%C3%A9s_Estrateg_Reg_I%2BD%2Bi.pdf')}>Directorio de los consejos y comités estratégicos regionales para la I+D+i y revisión del carácter misional tanto del directorio como de los consejos y comités</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de las fundaciones regionales de promoción y apoyo a la I+D+i y revisión del carácter misional tanto del directorio como de las fundaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_65_Directorio_Fundaciones_Reg_Promoci%C3%B3n_Apoy_I%2BD%2Bi.pdf')}>Directorio de las fundaciones regionales de promoción y apoyo a la I+D+i y revisión del carácter misional tanto del directorio como de las fundaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de los organismos de desarrollo regionales para la I+D+i y revisión del carácter misional tanto del directorio como de los organismos', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_66_Directorio_Organis_Desarroll_Reg_I%2BD%2Bi.pdf')}>Directorio de los organismos de desarrollo regionales para la I+D+i y revisión del carácter misional tanto del directorio como de los organismos</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de organizaciones regionales para la transferencia de resultados de la I+D+i y revisión del carácter misional tanto del directorio como de las organizaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_67_Directorio_Organizaciones_Reg_Transferen_I%2BD%2Bi.pdf')}>Directorio de organizaciones regionales para la transferencia de resultados de la I+D+i y revisión del carácter misional tanto del directorio como de las organizaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de los consejos y comités estratégicos regionales para la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_68_Directorio_Consejos_Comit%C3%A9s_Estrateg_Reg_I%2BD%2Bi_PptaM.pdf')}>Directorio de los consejos y comités estratégicos regionales para la I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de las fundaciones regionales de promoción y apoyo a la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_69_Directorio_Fundaciones_Reg_Promoci%C3%B3n_Apoy_I%2BD%2Bi_PptaMision.pdf')}>Directorio de las fundaciones regionales de promoción y apoyo a la I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de los organismos de desarrollo regionales para la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_70_Directorio_Organis_Desa_Reg_I%2BD%2Bi_PptaMision.pdf')}>Directorio de los organismos de desarrollo regionales para la I+D+i e interpretación desde su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de organizaciones para la transferencia de resultados de la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_71_Directorio_Organizaciones_Reg_Transferen_I%2BD%2Bi_PptaMision.pdf')}>Mapa de organizaciones para la transferencia de resultados de la I+D+i e interpretación de su contribución deseada</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de los Consejos y Comités estratégicos para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_72_An%C3%A1lisis_Grado_Solap_Consejos_Comit%C3%A9s_Estrateg_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de los Consejos y Comités estratégicos para la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las fundaciones de promoción y apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_73_An%C3%A1lisis_Grado_Solap_Fundac_Reg_Promoci%C3%B3n_Apoy_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las fundaciones de promoción y apoyo a la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de los organismos de desarrollo de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_74_An%C3%A1lisis_Grado_Solap_Organis_Desa_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de los organismos de desarrollo de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las organizaciones para la transferencia de resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_75_An%C3%A1lisis_Grado_Solap_Organizaciones_Reg_Transferen_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las organizaciones para la transferencia de resultados de la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Tecnología</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de plataformas virtuales regionales de apoyo a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_76_Mapa_Plataf_Virt_I%2BD%2Bi_Mision.pdf')}>Mapa de plataformas virtuales regionales de apoyo a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas y revisión del carácter misional tanto del mapa como de tales bibliotecas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_77_Mapa_Bibliot_Virt_PubCientyTec_Mision.pdf')}>Mapa de bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas y revisión del carácter misional tanto del mapa como de tales bibliotecas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de marketplaces de I+D+i y revisión del carácter misional tanto del mapa como de tales marketplaces', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_78_Mapa_Marketplaces_I%2BD%2Bi_Mision.pdf')}>Mapa de marketplaces de I+D+i y revisión del carácter misional tanto del mapa como de tales marketplaces</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de plataformas de crowdsourcing orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_79_Mapa_Plataf_Crowdsour_I%2BD%2Bi_Mision.pdf')}>Mapa de plataformas de crowdsourcing orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de plataformas virtuales regionales de apoyo a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_80_Mapa_Plataf_Virt_I%2BD%2Bi_PptaMision.pdf')}>Mapa de plataformas virtuales regionales de apoyo a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas y revisión del carácter misional tanto del mapa como de tales bibliotecas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_81_Mapa_Bibliotecas_Virt_PubCientyTec_PptaMision.pdf')}>Mapa de bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas y revisión del carácter misional tanto del mapa como de tales bibliotecas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de Marketplace de I+D+i y revisión del carácter misional tanto del mapa como de tales marketplace', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_82_Mapa_Marketplace_I%2BD%2Bi_PptaMision.pdf')}>Mapa de Marketplace de I+D+i y revisión del carácter misional tanto del mapa como de tales marketplace</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de plataformas de crowdsourcing orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_83_Mapa_Plataf_Crowdsour_I%2BD%2Bi_PptaMision.pdf')}>Mapa de plataformas de crowdsourcing orientadas a la I+D+i y revisión del carácter misional tanto del mapa como de tales plataformas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>


              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las plataformas virtuales regionales de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_84_An%C3%A1lisis_Grado_Solap_Plataf_Virt_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las plataformas virtuales regionales de apoyo a la I+D+i </TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_85_An%C3%A1lisis_Grado_Solapa_Bibliot_Virt_PubCientifTec.pdf')}>Análisis del grado de solapamiento de las bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de los marketplaces de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_86_An%C3%A1lisis_Grado_Solapa_Marketplace_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de los marketplaces de I+D+i </TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las plataformas de crowdsourcing', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_87_An%C3%A1lisis_Grado-Solapa_Plataf_Crowdsour.pdf')}>Análisis del grado de solapamiento de las plataformas de crowdsourcing</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Resultados</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de publicaciones regionales sobre resultados de la I+D+i y revisión del carácter misional tanto del mapa como de tales publicaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_88_directorio_Pub_Reg_Rdos_Mision.pdf')}>Directorio de publicaciones regionales sobre resultados de la I+D+i y revisión del carácter misional tanto del mapa como de tales publicaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de sistemas de seguimiento del cumplimiento de metas regionales de I+D+i y revisión del carácter misional tanto del directorio como de tales sistemas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_89_Directorio_Sist_Seg_Cump_Metas_Reg_I%2BD%2Bi.pdf')}>Directorio de sistemas de seguimiento del cumplimiento de metas regionales de I+D+i y revisión del carácter misional tanto del directorio como de tales sistemas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Listado de sistemas de atención de consultas sobre transparencia en la asignación de fondos y revisión de I+D+i y análisis del carácter misional tanto del listado como de tales sistemas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_90_Listado_Sist_Atencion_Consul_Transpar_Fondos_Rdos_Mision.pdf')}>Listado de sistemas de atención de consultas sobre transparencia en la asignación de fondos y revisión de I+D+i y análisis del carácter misional tanto del listado como de tales sistemas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Listado de sistemas de información que recopilan datos y opiniones sobre la imagen que tiene la región en el contexto de I+D+i y análisis del carácter misional tanto del listado como de tales sistemas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_91_Listado_Sist_Info_Imagen_Reg_I%2BD%2Bi_Mision.pdf')}>Listado de sistemas de información que recopilan datos y opiniones sobre la imagen que tiene la región en el contexto de I+D+i y análisis del carácter misional tanto del listado como de tales sistemas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de publicaciones regionales sobre resultados de la I+D+i y revisión del carácter misional tanto del directorio como de tales publicaciones', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_92_Directorio_Pub_Reg_Rdos_I%2BD%2Bi_PptaMision.pdf')}>Directorio de publicaciones regionales sobre resultados de la I+D+i y revisión del carácter misional tanto del directorio como de tales publicaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Directorio de sistemas de seguimiento del cumplimiento de metas regionales de I+D+i y revisión del carácter misional tanto del directorio como de tales sistemas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_93_Directorio_Sist_Seg_Cump_Metas_Reg_I%2BD%2Bi_PptaMision.pdf')}>Directorio de sistemas de seguimiento del cumplimiento de metas regionales de I+D+i y revisión del carácter misional tanto del directorio como de tales sistemas</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Listado de portales regionales informativos sobre transparencia en la asignación de fondos y resultados de I+D+i y revisión del carácter misional tanto del listado como de tales portales', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_94_Listado_Portales_Info_Transparencia_PptaMision.pdf')}>Listado de portales regionales informativos sobre transparencia en la asignación de fondos y resultados de I+D+i y revisión del carácter misional tanto del listado como de tales portales</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Listado de portales regionales destinados a la recopilación de información sobre la imagen que tiene la región en el contexto de I+D+i y revisión del carácter misional tanto del listado como de tales portales', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_95_Listado_Portales_Info_Imagen_Reg_I%2BD%2Bi_PptaMision.pdf')}>Listado de portales regionales destinados a la recopilación de información sobre la imagen que tiene la región en el contexto de I+D+i y revisión del carácter misional tanto del listado como de tales portales</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de las publicaciones regionales sobre resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_96_An%C3%A1lisis_Grado_Solapa_Public_Rdos_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de las publicaciones regionales sobre resultados de la I+D+i </TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de los sistemas de seguimiento periódico del cumplimiento de metas regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_97_An%C3%A1lisis_Grado_Solapa_Sist_Segui_Metas_I%2Bd%2Bi.pdf')}>Análisis del grado de solapamiento de los sistemas de seguimiento periódico del cumplimiento de metas regionales de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de los portales de transparencia con acceso a la base de datos y la realización de consultas específicas sobre la asignación de fondos y resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_98_An%C3%A1lisis_Grado_Solapa_Portales_Transparencia_BBDD_Co.pdf')}>Análisis del grado de solapamiento de los portales de transparencia con acceso a la base de datos y la realización de consultas específicas sobre la asignación de fondos y resultados de la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del grado de solapamiento de los sistemas de participación y consecución de reconocimientos nacionales e internacionales para la región en el contexto de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_99_An%C3%A1lisis_Grado_Solap_Portales_Info_Imagen_Reg_I%2BD%2Bi.pdf')}>Análisis del grado de solapamiento de los sistemas de participación y consecución de reconocimientos nacionales e internacionales para la región en el contexto de la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}></TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

ReferenceOne.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReferenceOne);
/* eslint-enable no-unused-vars */