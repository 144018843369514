import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Header from './Header';
import auth from '../Auth';
import EnhancedTable from './EnhancedTable';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  buttonNew: {
    marginTop: '24px',
    marginBottom: '24px',
  }
});

class List extends Component { 

  constructor (props) {
    super(props);
    this.state = {
      data: {}
    }

  } 

  render() {

  const { classes } = this.props;

  return (
    <div>
      <Header />
      <main className={classes.main}>
        { !auth.getProfile().role.filter(role => (role.name === "admin")).length > 0 ?
        <EnhancedTable history={this.props.history} />
        :
        <EnhancedTable history={this.props.history} />
        }
      </main>
    </div>
  );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(List);