/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Description from './Description';
import CHR from './CHR';
import CER from './CER';
import CRR from './CRR';
import CHRZero from './CHRZero';
import CERZero from './CERZero';
import CRRZero from './CRRZero';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ paddingTop: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 0,
  },
});

class Main extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      areaData: {},
      value: 0,
      submitActionTitle: <FormattedMessage id="areas.new" />,
      openDialog: false
    }

  } 

  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>
        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="inherit"
          position="static"
          elevation={0}
          children={''}
        >
          <Tabs value={value} textColor="inherit" onChange={this.handleTabChange}>
            {/* <Tab textColor="inherit" label={'Descripción General'} /> */}
            <Tab textColor="inherit" label={<FormattedMessage id="CHR.title" />} />
            <Tab textColor="inherit" label={<FormattedMessage id="CER.title" />} />
            <Tab textColor="inherit" label={<FormattedMessage id="CRR.title" />} />
          </Tabs>
        </AppBar>
        <main className={classes.main}>

{/*         {value === 0 && <TabContainer>     
          { <Description areaId={this.props.areaId} /> }      
        </TabContainer>} */}

        {value === 0 && <TabContainer>     
          { this.props.areaLevel === 0 ? <CHRZero areaId={this.props.areaId} /> : <CHR areaId={this.props.areaId} /> }      
        </TabContainer>}

        {value === 1 && <TabContainer>
          { this.props.areaLevel === 0 ? <CERZero areaId={this.props.areaId} /> : <CER areaId={this.props.areaId} /> }
        </TabContainer>}

        {value === 2 && <TabContainer>
          { this.props.areaLevel === 0 ? <CRRZero areaId={this.props.areaId} /> : <CRR areaId={this.props.areaId} /> }
        </TabContainer>}

      </main>
      </React.Fragment>
    );
  }
}

Main.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Main);
/* eslint-enable no-unused-vars */