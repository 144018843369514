/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../MakeProvider';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Header from './Header';
import area from './Area';
import { FormattedMessage, FormattedTime } from 'react-intl';
import IntellectualCapital from './intellectual_capital/Main';
import Madurity from './madurity/Main';
import Map from './map/Main';
import Agents from './agents/Main';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

const types = [
  'Área',
  'Proceso',
  'Proyecto',
  'Tópico',
];

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 0,
  },
});

class Show extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      areaData: {},
      value: 0,
      submitActionTitle: <FormattedMessage id="areas.new" />,
      openDialog: false
    }

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handlePurposeChange = this.handlePurposeChange.bind(this);
    this.handleProductsChange = this.handleProductsChange.bind(this);
    this.handleSpaceUdate = this.handleSpaceUdate.bind(this);
  } 

  componentDidMount() {
    area.show(this.props.match.params.id).then((result) =>
      this.setState({ areaData: result })
    ); 
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    area.show(nextProps.match.params.id).then((result) =>
      this.setState({ areaData: result })
    ); 
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  handleNameChange(event) {
    this.setState({
      areaData: { ...this.state.areaData, name: event.target.value }
    })
  }

  handleTypeChange(event) {
    this.setState({
      areaData: { ...this.state.areaData, type: event.target.value }
    })
  }

  handlePurposeChange(event) {
    this.setState({
      areaData: { ...this.state.areaData, purpose: event.target.value }
    })
  }

  handleProductsChange(event) {
    this.setState({
      areaData: { ...this.state.areaData, products: event.target.value }
    })
  }

  handleClickOpen() {
    this.setState(data => ({
      openDialog: true,
      submitAction: 'new',
      submitActionTitle: <FormattedMessage id="areas.new" />,
    }))
  }

  handleClickEdit = (areaData) => {
    this.setState({ 
      openDialog: true,
      submitAction: 'edit',
      submitActionTitle: <FormattedMessage id="areas.edit" />,
      areaData: areaData
    });
  }

  handleSpaceUdate(e) {
    e.preventDefault();
    area.update(this.props.match.params.id, this.state.areaData.title, this.state.areaData.description, this.state.areaData.country, () => {
      this.setState({ openSnack: true, SnackMessage: <FormattedMessage id="areas.updated" /> });
      this.handleClickClose();
    })
  }

  handleClickClose() {
    this.setState({ 
      openDialog: false
    });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;
    const can = (this.context.userRoles.filter(role => ((role.name === "editor" && role.resource_id.$oid === this.props.match.params.id) || role.name === "owner" || role.name === "admin")).length > 0)

    return (
      <React.Fragment>
        <Header title={ this.state.areaData.title } showtabs={true} />
        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="inherit"
          position="static"
          elevation={0}
          children={''}
        >
          <Tabs variant="fullWidth" value={value} textColor="inherit" onChange={this.handleTabChange}>
            <Tab icon={<SupervisedUserCircleIcon />} textColor="inherit" label="Capital Intelectual" />
            <Tab icon={<MapIcon />} textColor="inherit" label="Mapa de Conocimiento" />
            <Tab icon={<SpaRoundedIcon />} textColor="inherit" label="Madurez de la GC" />
            <Tab icon={<HomeWorkIcon />} textColor="inherit" label="Agentes Dimamizadores" />
          </Tabs>
        </AppBar>
        <main className={classes.main}>
          {value === 0 && <TabContainer>
            <IntellectualCapital areaId={this.props.match.params.id} areaLevel={this.state.areaData.level} />                           
          </TabContainer>}

          {value === 1 && <TabContainer>
            <Map areaId={this.props.match.params.id} areaLevel={this.state.areaData.level} />
          </TabContainer>}

          {value === 2 && <TabContainer style={{ padding:0 }}>
            <Madurity areaId={this.props.match.params.id} areaLevel={this.state.areaData.level} /> 
          </TabContainer>}

          {value === 3 && <TabContainer>
            <Agents areaId={this.props.match.params.id} areaLevel={this.state.areaData.level} />
          </TabContainer>}
          
        </main>
      </React.Fragment>
    );
  }
}

Show.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Show);
/* eslint-enable no-unused-vars */