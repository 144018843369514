/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import agent from './Agent'; 

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
  colOne: {
    border: '1px solid #cfd8dc', color: '#37474f', fontStyle: 'bold', fontSize: '17px', textAlign: 'left', paddingLeft: 55
  },
});

class Support extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      currentTopic: '',
      support: [],
      currentRating: 0,
      openPDFDialog: false,
      title: '',
      url: '',
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handlePDFClickOpen = this.handlePDFClickOpen.bind(this);
    this.handlePDFClickClose = this.handlePDFClickClose.bind(this);
  } 

  componentDidMount() {
    agent.getSupport(this.props.agentId).then((result) =>
      this.setState({ support: result })
    );
  } 

  handleClickOpen(currentTopic) {
    let rating = 0;
    agent.getSupport(this.props.agentId).then((result) => {
      result.map((row) => {
        if (row.topic === currentTopic) {
          rating = row.supportvalue.toString();
        }
        return true;
      });
      this.setState({ openDialog: true, currentTopic: currentTopic, currentRating: rating });
    });
    
  }

  handleClickClose() {
    this.setState({ openDialog: false, currentTopic: '', currentRating: 0 });
    agent.getSupport(this.props.agentId).then((result) => {
      this.setState({ support: result });
      this.forceUpdate();
    });
  }

  handleChange = (event) => {
    this.setState({ currentRating: event.target.value });
  }

  handleSave(e) {
    e.preventDefault();
    agent.saveSupport(this.props.agentId, this.state.currentTopic, this.state.currentRating).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="CHR.updated" />,
        openSnack: true
      });
      this.handleClickClose();
    })
  }

  makeItemValue(item) {

    var value = 0;

    this.state.support.map((row) => {
      if (row.topic === item) {
        value = row.supportvalue;
      }
      return true
    });

    return value;

  }

  makeRatingResult(item) {

    var stat = { sum: 0, recommendation: '' }

    this.state.support.map((row) => {
      if (row.topic.includes(item)) {
        stat.sum += row.supportvalue;
      }
      return true
    });

    if (item === 'agent_strategy') {

      if (stat.sum >= 0 && stat.sum <= 5) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de agentes regionales dinamizadores de la GC y  la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_01_Mapa_Agent_Dinamiz_GC_I%2BD%2BI_Mision.pdf')}>NIVAP01</Link>
                                }} />;
      } else if (stat.sum > 5 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de agentes regionales dinamizadores de la GC y la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_02_Mapa_Agent_Dinamiz_GC_I%2BD%2Bi_Propuesta_Mision.pdf')}>NIVAP02</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de acción de agentes regionales dinamizadores de la GC para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_03_An%C3%A1lisis_Grado_Solapam_Acci%C3%B3n_Agentes_Reg_GCI%2BD%2Bi.pdf')}>NIVAP03</Link>           
                                }} />;    
      }

    } else if (item === 'agent_connect') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a observatorios de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_04_Mapa_Inic_Reg_Observ_Mision.pdf')}>NIVAP04</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a vigilancia tecnológica y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_05_Mapa_Inic_VT_Mision.pdf')}>NIVAP05</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a comunicación de la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_06_Mapa_Inic_Comunic_I%2BD%2Bi_Mision.pdf')}>NIVAP06</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Inventario de publicaciones regionales de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_07_Inventario_Pub_Reg_Mision.pdf')}>NIVAP07</Link>
                                }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas regionales relativas a observatorios de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_08_Mapa_Inici_Reg_Observ_PptaMision.pdf')}>NIVAP08</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Inventario de publicaciones regionales de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_09_Mapa_Inici_Reg_VT_PptaMision.pdf')}>NIVAP09</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Inventario de publicaciones regionales de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_10_Mapa_Inici_Reg_ComI%2BD%2Bi_PptaMision.pdf')}>NIVAP10</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Inventario de publicaciones regionales de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_11_Inventario_Pub_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP11</Link>
                                }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a observatorios de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_12_An%C3%A1lisis_Grado_Solapam_Inicia_Reg_Observat_I%2BD%2Bi.pdf')}>NIVAP12</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a vigilancia tecnológica', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_13_An%C3%A1lisis_Grado_Solap_VT_Reg.pdf')}>NIVAP13</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas regionales relativas a comunicación de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_14_An%C3%A1lisis_Grado_Solapa_Comunic_I%2BD%2Bi.pdf')}>NIVAP14</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las publicaciones regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_15_An%C3%A1lisis_Grado_Solap_Pub_Reg_I%2BD%2Bi.pdf')}>NIVAP15</Link>
                                }} />;      
      }

    } else if (item === 'agent_sort') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de sitios web regionales de consulta sobre I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_16_Mapa_Sitios_Web_Consulta_Mision.pdf')}>NIVAP16</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de organizaciones (no empresas) regionales de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_17_Directorio_Org_I%2BD%2Bi_Mision.pdf')}>NIVAP17</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de empresas regionales de I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_18_Directorio_Emp_I%2BD%2Bi_Reg_Mision.pdf')}>NIVAP18</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de grupos de investigación regionales de orientación hacia la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_19_Directorio_grupos_Inv_Mision.pdf')}>NIVAP19</Link>
                                }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de sitios web regionales de consulta sobre I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_20_Mapa_Sitiosweb_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP20</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de organizaciones (no empresas) regionales de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_21_Directorio_Org_Reg_I+D+i_PptaMision.pdf')}>NIVAP21</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de empresas regionales de I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_22_Directorio_Emp_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP22</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de grupos de investigación regionales de orientación hacia la I+D+i e interpretación desde su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_23_Directorio_GruposInvest_PptaMision.pdf')}>NIVAP23</Link>
                                }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los sitios web regionales de consulta sobre I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_24_An%C3%A1lisis_Grado_Solap_Sitios_Web_Reg_I%2BD%2Bi.pdf')}>NIVAP24</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las organizaciones (no empresas) regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_25_An%C3%A1lisis_Grado_Solap_Org_Reg_I%2BD%2Bi.pdf')}>NIVAP25</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las empresas regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_26_An%C3%A1lisis_Grado_Solap_Emp_Reg_I%2BD%2Bi.pdf')}>NIVAP26</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los grupos de investigación regionales de orientación hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_27_An%C3%A1lisis_Grado_Solap_Grupos_Inv_Reg_I%2BD%2Bi.pdf')}>NIVAP27</Link>
                                }} />;      
      }

    } else if (item === 'agent_socialize') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de clusters regionales orientados a la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_28_Mapa_Clusters_I%2BD%2Bi_Mision.pdf')}>NIVAP28</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de agrupaciones empresariales para la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_29_Mapa_Agrup_Emp_Mision.pdf')}>NIVAP29</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de mesas de trabajo regionales para la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_30_Mapa_Mesas_Trabajo_I%2BD%2Bi_Mision.pdf')}>NIVAP30</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de asociaciones y fundaciones regionales orientadas a la I+D+i y su revisión del carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_31_Mapa_Asoc_Fundac_I%2BD%2Bi_Mision.pdf')}>NIVAP31</Link>
                                }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de clusters regionales orientados a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_32_Mapa_clusters_I%2BD%2Bi_PptaMision.pdf')}>NIVAP32</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de agrupaciones empresariales para la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_33_Mapa_AgrupEmp_I%2BD%2Bi_PptaMision.pdf')}>NIVAP33</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de mesas de trabajo regionales para la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_34_Mapa_Mesas_Trabajo_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP34</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de asociaciones y fundaciones regionales orientadas a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/Ficha_NIVAP_35_Mapa_Asoc_Fundac_Reg_I%2BD%2Bi_PptaMision.pdf')}>NIVAP35</Link>
                                }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de clusters regionales orientados a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_36_An%C3%A1lisis_Grado_Solapa_Clusters_Reg_I%2BD%2Bi.pdf')}>NIVAP36</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las agrupaciones empresariales para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_37_An%C3%A1lisis_Grado_Solapa_Agrup_Emp_I%2BD%2Bi.pdf')}>NIVAP37</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las mesas de trabajo regionales orientadas hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_38_An%C3%A1lisis_Grado_Solapa_Mesas_Trabajo_I%2BD%2Bi.pdf')}>NIVAP38</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las asociaciones y fundaciones regionales orientadas hacia la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_39_An%C3%A1lisis_Grado_Solapa_Asoc_Fundac_I%2BD%2Bi.pdf')}>NIVAP39</Link>
                                }} />;      
      }

    } else if (item === 'agent_develop') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de titulaciones universitarias (grados) impartidas en la región y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_40_Mapa_Titulac_Reg_Mision.pdf')}>NIVAP40</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de titulaciones de posgrado impartidas en la región y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_41_Mapa_Titulac_Posgrado_Reg_Mision.pdf')}>NIVAP41</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas de apoyo público regional a la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_42_Mapa_Iniciativas_Pub_I%2BD%2Bi_Mision.pdf')}>NIVAP42</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas de apoyo público regional al emprendimiento innovador y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_43_Mapa_Inic_Apoyo_Publico_Empren_Innov_Mision.pdf')}>NIVAP43</Link>
                                }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de titulaciones universitarias (grados) impartidas en la región e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_44_Mapa_Titulac_Grados_Reg_PptaMision.pdf')}>NIVAP44</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de titulaciones de posgrado impartidas en la región e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_45_Mapa_Titulac_Posgrado_Reg_PptaMision.pdf')}>NIVAP45</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas de apoyo público regional a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_46_Mapa_Inici_Apoy_Pub_I%2BD%2BiReg_PpatMision.pdf')}>NIVAP46</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de iniciativas de apoyo público regional al emprendimiento innovador e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_47_Mapa_Apoyo_Pub_Reg_EmpInnovador_PpatMision.pdf')}>NIVAP47</Link>
                                }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las titulaciones universitarias (grados) impartidas en la región', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_48_An%C3%A1lisis_Grado_Solapa_Titulac_Univer_Reg.pdf')}>NIVAP48</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las titulaciones posgrado impartidas en la región', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_49_An%C3%A1lisis_Grado_Solapa_Titulac_Posgrado_Reg.pdf')}>NIVAP49</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas de apoyo público a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_50_An%C3%A1lisis_Grado_Solapa_Inici_Apoyo_Pub_I%2BD%2Bi.pdf')}>NIVAP50</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las iniciativas de apoyo público al emprendimiento innovador', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_51_An%C3%A1lisis_Grado_Solapa_Inici_Empren_Innov.pdf')}>NIVAP51</Link>
                                }} />;      
      }

    } else if (item === 'agent_policies') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación) y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_52_Análisis_Convoca_Competitiva_Reg_Apoyo_I+D+i.pdf')}>NIVAP52</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de subvenciones regionales de apoyo a la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_53_Análisis_Opciones_Subvención_I+D+i.pdf')}>NIVAP53</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de líneas de crédito reembolsables para el apoyo regional a la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_54_Directorio_Lineas_CredReemb_I%2BD%2Bi_Mision.pdf')}>NIVAP54</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del número de solicitudes regionales de propiedad intelectual e industrial', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_55_An%C3%A1lisis_Solicit_Reg_Prop_Intelec_Indus.pdf')}>NIVAP55</Link>
                                }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación) e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_56_Directorio_Convoc_Compet_Cofinanc_PptaMision.pdf')}>NIVAP56</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de subvenciones regionales de apoyo a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_57_Directorio_Subvenc_I+D+i_PptaMision.pdf')}>NIVAP57</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de líneas de crédito reembolsables para el apoyo regional a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_58_Directorio_Líneas_CredReem_I+D+i_PptaMision.pdf')}>NIVAP58</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis de la tipología de solicitudes regionales de propiedad intelectual e industrial', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_59_Directorio_Solicitud_Propiedad_Intelec_Rol_Institu.pdf')}>NIVAP59</Link>
                                }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las convocatorias competitivas regionales de apoyo a la I+D+i (cofinanciación)', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_60_Análisis_Grado_Solapa_Convoc_Compe_Reg_Cofinanc.pdf')}>NIVAP60</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las subvenciones regionales de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_61_Análisis_Grado_Solapa_Subvencion.pdf')}>NIVAP61</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las líneas de crédito reembolsables para el apoyo regional a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_62_Análisis_Grado_Solapa_CredReemb_Reg_I+D+i.pdf')}>NIVAP62</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis de los resultados de solicitudes regionales de propiedad intelectual e industrial', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_63_Análisis_Grado_Solapa_Solicitu_Propie_Intelec.pdf')}>NIVAP63</Link>
                                }} />;      
      }

    } else if (item === 'agent_structures') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Oferta servicios de protección de resultados de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_64_Directorio_Consejos_Comités_Estrateg_Reg_I+D+i.pdf')}>NIVAP64</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de Consejos y Comités Estratégicos para la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_65_Directorio_Fundaciones_Reg_Promoción_Apoy_I+D+i.pdf')}>NIVAP65</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de Fundaciones de promoción y apoyo a la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_66_Directorio_Organis_Desarroll_Reg_I+D+i.pdf')}>NIVAP66</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de organismos de desarrollo de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_67_Directorio_Organizaciones_Reg_Transferen_I+D+i.pdf')}>NIVAP67</Link>
                                }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de organizaciones para la transferencia de resultados de la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_68_Directorio_Consejos_Comités_Estrateg_Reg_I+D+i_PptaM.pdf')}>NIVAP68</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MMapa de Consejos y Comités Estratégicos para la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_69_Directorio_Fundaciones_Reg_Promoción_Apoy_I+D+i_PptaMision.pdf')}>NIVAP69</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de Fundaciones de promoción y apoyo a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_70_Directorio_Organis_Desa_Reg_I+D+i_PptaMision.pdf')}>NIVAP70</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de organismos de desarrollo de I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_71_Directorio_Organizaciones_Reg_Transferen_I+D+i_PptaMision.pdf')}>NIVAP71</Link>
                                }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los Consejos y Comités estratégicos para la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_72_Análisis_Grado_Solap_Consejos_Comités_Estrateg_Reg_I+D+i.pdf')}>NIVAP72</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las fundaciones de promoción y apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_73_Análisis_Grado_Solap_Fundac_Reg_Promoción_Apoy_I+D+i.pdf')}>NIVAP73</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los organismos de desarrollo de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_74_Análisis_Grado_Solap_Organis_Desa_Reg_I+D+i.pdf')}>NIVAP74</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las organizaciones para la transferencia de resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_75_Análisis_Grado_Solap_Organizaciones_Reg_Transferen_I+D+i.pdf')}>NIVAP75</Link>
                                }} />;      
      }

    } else if (item === 'agent_technology') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de plataformas virtuales regionales de apoyo a la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_76_Mapa_Plataf_Virt_I+D+i_Mision.pdf')}>NIVAP76</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_77_Mapa_Bibliot_Virt_PubCientyTec_Mision.pdf')}>NIVAP77</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de marketplaces de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_78_Mapa_Marketplaces_I+D+i_Mision.pdf')}>NIVAP78</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de plataformas de crowdsourcing orientadas a la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_79_Mapa_Plataf_Crowdsour_I+D+i_Mision.pdf')}>NIVAP79</Link>
                                }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de plataformas virtuales regionales de apoyo a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_80_Mapa_Plataf_Virt_I+D+i_PptaMision.pdf')}>NIVAP80</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_81_Mapa_Bibliotecas_Virt_PubCientyTec_PptaMision.pdf')}>NIVAP81</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de marketplaces de I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_82_Mapa_Marketplace_I%2BD%2Bi_PptaMision.pdf')}>NIVAP82</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Mapa de plataformas de crowdsourcing orientadas a la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_83_Mapa_Plataf_Crowdsour_I%2BD%2Bi_PptaMision.pdf')}>NIVAP83</Link>
                                }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las plataformas virtuales regionales de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_84_Análisis_Grado_Solap_Plataf_Virt_I+D+i.pdf')}>NIVAP84</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las bibliotecas virtuales de acceso a publicaciones científicas y tecnológicas', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_85_Análisis_Grado_Solapa_Bibliot_Virt_PubCientifTec.pdf')}>NIVAP85</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los marketplaces de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_86_Análisis_Grado_Solapa_Marketplace_I+D+i.pdf')}>NIVAP86</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las plataformas de crowdsourcing', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_87_Análisis_Grado-Solapa_Plataf_Crowdsour.pdf')}>NIVAP87</Link>
                                }} />;      
      }

    } else if (item === 'agent_results') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de publicaciones regionales sobre resultados de la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_88_directorio_Pub_Reg_Rdos_Mision.pdf')}>NIVAP88</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de sistemas de seguimiento del cumplimiento de metas regionales de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_89_Directorio_Sist_Seg_Cump_Metas_Reg_I+D+i.pdf')}>NIVAP89</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Listado de sistemas de atención de consultas sobre transparencia en la asignación de fondos y resultados de I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_90_Listado_Sist_Atencion_Consul_Transpar_Fondos_Rdos_Mision.pdf')}>NIVAP90</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de sistemas de análisis de las apariciones en medios de comunicación de la región en el ámbito de la I+D+i y revisión de su carácter misional', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_91_Listado_Sist_Info_Imagen_Reg_I+D+i_Mision.pdf')}>NIVAP91</Link>
                                }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Directorio de publicaciones regionales sobre resultados de la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_92_Directorio_Pub_Reg_Rdos_I+D+i_PptaMision.pdf')}>NIVAP92</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Listado de sistemas de seguimiento del cumplimiento de metas regionales de I+D+i al cierre de planes y programas e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_93_Directorio_Sist_Seg_Cump_Metas_Reg_I+D+i_PptaMision.pdf')}>NIVAP93</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Listado de portales informativos sobre transparencia sobre asignación de fondos y resultados de la I+D+i e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_94_Listado_Portales_Info_Transparencia_PptaMision.pdf')}>NIVAP94</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Listado de sistemas de análisis de posicionamiento estratégico de la comunicación sobre la I+D+i regional e interpretación de su contribución deseada', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_95_Listado_Portales_Info_Imagen_Reg_I+D+i_PptaMision.pdf')}>NIVAP95</Link>
                                }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de las publicaciones regionales sobre resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_96_Análisis_Grado_Solapa_Public_Rdos_I+D+i.pdf')}>NIVAP96</Link>,
                                  sheetTwo: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los sistemas de seguimiento periódico del cumplimiento de metas regionales de I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_97_Análisis_Grado_Solapa_Sist_Segui_Metas_I+d+i.pdf')}>NIVAP97</Link>,
                                  sheetThree: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los portales de transparencia con acceso a la base de datos y la realización de consultas específicas sobre la asignación de fondos y resultados de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_98_Análisis_Grado_Solapa_Portales_Transparencia_BBDD_Co.pdf')}>NIVAP98</Link>,
                                  sheetFour: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('Análisis del grado de solapamiento de los sistemas de participación y consecución de reconocimientos nacionales e internacionales para la región en el contexto de la I+D+i', 'https://kitmake.ica2.com/resources/ea/ficha_NIVAP_99_Análisis_Grado_Solap_Portales_Info_Imagen_Reg_I+D+i.pdf')}>NIVAP99</Link>
                                }} />;      
      }

    }

    return stat;    
  }

  handlePDFClickOpen(title, url) {
    this.setState({ openPDFDialog: true, title: title, url: url });
  }

  handlePDFClickClose() {
    this.setState({ openPDFDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          { 'Nivel de Apoyo a la Gestión de Conocimiento' }
        </Typography>

        <Dialog
          fullWidth={true}
          open={this.state.openPDFDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handlePDFClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Valoración</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_title' } /> : null }</b>
              <br />{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_desc' } /> : null }
            </DialogContentText>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><b>{ 'Valoración'}</b></TableCell>
                  <TableCell style={ { width: '30%' } }>
                  <RadioGroup row>
                    <Radio checked={this.state.currentRating === '1'} value={1} onChange={this.handleChange} color="primary" />
                    <Radio checked={this.state.currentRating === '2'} value={2} onChange={this.handleChange} color="primary" />
                    <Radio checked={this.state.currentRating === '3'} value={3} onChange={this.handleChange} color="primary" />
                    <Radio checked={this.state.currentRating === '4'} value={4} onChange={this.handleChange} color="primary" />
                    <Radio checked={this.state.currentRating === '5'} value={5} onChange={this.handleChange} color="primary" />
                  </RadioGroup>
                  </TableCell>
                </TableRow>                                                
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              disabled={ this.state.currentRating === 0 }
              onClick={this.handleSave}
              color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Valoración' }</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Interpretación' }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '25%' }} align={'left'}><FormattedMessage id="agent_strategy.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_strategy.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_strategy.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_strategy.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_strategy.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_strategy.item_one') }</TableCell>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_strategy').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_strategy.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_strategy.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_strategy.item_two')}  style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_strategy.item_two') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_connect.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_connect.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_connect.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_connect.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_connect').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_connect.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_connect.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_connect.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_connect.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_connect.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_connect.item_four') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_sort.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_sort.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_sort.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_sort.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_sort').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_sort.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_sort.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_sort.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_sort.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_sort.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_sort.item_four') }</TableCell>
              </TableRow>  

              <TableRow>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_socialize.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_socialize.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_socialize.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_socialize.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_socialize').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_socialize.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_socialize.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_socialize.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_socialize.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_socialize.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_socialize.item_four') }</TableCell>
              </TableRow>  

              <TableRow>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_develop.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_develop.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_develop.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_develop.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_develop').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_develop.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_develop.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_develop.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_develop.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_develop.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_develop.item_four') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_policies.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_policies.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_policies.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_policies.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_policies').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_policies.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_policies.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_policies.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_policies.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_policies.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_policies.item_four') }</TableCell>
              </TableRow> 

              <TableRow>
                <TableCell style={{ backgroundColor: '#ffeb3b', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_structures.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ffeb3b', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_structures.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_structures.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_structures.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_structures').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_structures.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_structures.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_structures.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_structures.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_structures.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_structures.item_four') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_technology.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_technology.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_technology.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_technology.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_technology').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_technology.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_technology.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_technology.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_technology.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_technology.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_technology.item_four') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_results.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_results.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_results.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_results.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_results').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_results.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_results.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_results.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_results.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_results.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_results.item_four') }</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

Support.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Support);
/* eslint-enable no-unused-vars */