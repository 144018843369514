import decode from 'jwt-decode';

class Auth {

  constructor() {
    this.authenticated = false;
    //this.domain = domain || 'http://localhost:3001'
    this.fetch = this.fetch.bind(this)
    this.login = this.login.bind(this)
    this.getProfile = this.getProfile.bind(this)
  }

  login(email, password, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'users/sign_in', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
          user: { email: email, password: password }
      })
    }).then(res => {
      if (res.status === 201) {
        this.authenticated = true;
        this.setToken(res.headers.get('Authorization').replace('Bearer ', ''));
      }
      cb(res);
      //return Promise.resolve(res);
    }).catch(error => console.log(error) );
  }

  register(name, organization, email, password, password_confirmation, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'users', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
          user: { name: name, organization: organization, email: email, password: password, password_confirmation: password_confirmation }
      })
    }).then(res => {
      console.log(res.status); 
      if (res.status === 201) {
        cb();
      }

      //return Promise.resolve(res);
    }).catch(error => console.log(error) );
  }

  async password(invitation_token, password, password_confirmation, cb) { 
    try {
      const res = await fetch(process.env.REACT_APP_DEV_API_URL + 'users/invitation', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'PUT',
        body: JSON.stringify({
          user: { invitation_token: invitation_token, password: password, password_confirmation: password_confirmation }
        })
      });
      const data = await res.json();
      console.log(data.status)
      if (res.status === 200 && data.status) {
        cb();
      }
    }
    catch (error) {
      return console.log(error);
    }
  }

  isAuthenticated() {
    return this.authenticated;
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken()
    return !!token && !this.isTokenExpired(token) // handwaiving here
  }

  isTokenExpired(token) {
      try {
          const decoded = decode(token);
          if (decoded.exp < Date.now() / 1000) {
              return true;
          }
          else
              return false;
      }
      catch (err) {
          return false;
      }
  }

  setToken(idToken) {
      // Saves user token to localStorage
      localStorage.setItem('id_token', idToken)
  }

  getToken() {
      // Retrieves the user token from localStorage
      return localStorage.getItem('id_token')
  }

  logout(cb) {
      // Clear user token and profile data from localStorage
      this.authenticated = false;
      localStorage.removeItem('id_token');
      localStorage.removeItem('currentOrganization');
      cb()
  }

  getProfile() {
      return decode(this.getToken());
  }


  fetch(url, options) {
      // performs api calls sending the required authentication headers
      const headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      }

      if (this.loggedIn()) {
          headers['Authorization'] = 'Bearer ' + this.getToken()
      }

      return fetch(url, {
          headers,
          ...options
      })
          .then(this._checkStatus)
          .then(response => response.json())
  }

  _checkStatus(response) {
      // raises an error in case response status is not a success
      if (response.status >= 200 && response.status < 300) {
          return response
      } else {
          var error = new Error(response.statusText)
          error.response = response
          throw error
      }
  }

}

export default new Auth();