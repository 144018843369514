import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import auth from '../Auth'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class AcceptInvitation extends Component {

  constructor (props) {
    super(props);
    this.state = {
      name: '',
      organization: '',
      email: '',
      password: '',
      password_confirmation: ''
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleOrgChange = this.handleOrgChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordCChange = this.handlePasswordCChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  handleNameChange(event) {
    this.setState({
        name: event.target.value
    })
  }
  
  handleOrgChange(event) {
    this.setState({
        organization: event.target.value
    })
  }

  handleEmailChange(event) {
    this.setState({
        email: event.target.value
    })
  }

  handlePasswordChange(event) {
    this.setState({
        password: event.target.value
    })
  }

  handlePasswordCChange(event) {
    this.setState({
        password_confirmation: event.target.value
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    auth.password(this.props.match.params.invitation_token, this.state.password, this.state.password_confirmation, () => {
      this.props.history.push("/");
    })
  }

  render() {

    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Set password
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input name="password" type="password" id="password" value={this.state.password} onChange={this.handlePasswordChange} autoComplete="current-password" />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password_confirmation">Password confirmation</InputLabel>
              <Input name="password_confirmation" type="password" id="password_confirmation" value={this.state.password_confirmation} onChange={this.handlePasswordCChange} autoComplete="current-password" />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign up
            </Button>
          </form>
          <Typography className={classes.link} component="a" href="/signin">
            Sign in
          </Typography>
        </Paper>
      </main>
    );
  }
}

AcceptInvitation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AcceptInvitation);