import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import MakeBase from './layout/MakeBase';
import SignUp from './auth/SignUp';
import SignIn from './auth/SignIn';
import AcceptInvitation from './auth/AcceptInvitation';

class App extends Component {

  render() {

    return (
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/accept/:invitation_token" component={AcceptInvitation} />
          <ProtectedRoute path="/areas" component={MakeBase} />
          <ProtectedRoute path="/model" component={MakeBase} />
          <ProtectedRoute path="/how" component={MakeBase} />
          <ProtectedRoute path="/reference" component={MakeBase} />
          <ProtectedRoute path="/references" component={MakeBase} />
          <ProtectedRoute path="/account" component={MakeBase} />
          <ProtectedRoute exact path="/" component={MakeBase} />
        </Switch>
    );
  }
}

export default App;
