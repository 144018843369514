/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
});

class Description extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {

    }

  } 

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>
        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          <FormattedMessage id="agent.desc_title_one" />
        </Typography>
        <Typography>
          <FormattedMessage id="agent.desc_desc_one" />
        </Typography>
        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          <FormattedMessage id="agent.desc_title_two" />
        </Typography>            
        <Typography>
          <FormattedMessage id="agent.desc_desc_two" />
        </Typography>
        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          <FormattedMessage id="agent.desc_title_three" />
        </Typography>            
        <Typography>
          <FormattedMessage id="agent.desc_desc_three" />
        </Typography>
        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          <FormattedMessage id="agent.desc_title_four" />
        </Typography>            
        <Typography>
          <FormattedHTMLMessage id="agent.desc_desc_four" />
        </Typography>
      </React.Fragment>
    );
  }
}

Description.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Description);
/* eslint-enable no-unused-vars */