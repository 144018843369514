/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import area from '../../areas/Area'; 

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
  colOne: {
    border: '1px solid #cfd8dc', color: '#37474f', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0
  },
  colTwo: {
    border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top'
  },
  colPointer: {
    border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', cursor: 'pointer'
  }
});

class Model extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      currentTopic: '',
      currentRating: { relevance: 0, specificity: 0, control: 0, impacts: 0 },
      maturity: [],
      openPDFDialog: false,
      title: '',
      url: '',
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setUp = this.setUp.bind(this);
    this.handlePDFClickOpen = this.handlePDFClickOpen.bind(this);
    this.handlePDFClickClose = this.handlePDFClickClose.bind(this);
  } 

  componentDidMount() {
    this.setUp();
  } 

  UNSAFE_componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.areaId !== prevProps.areaId) {
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    area.getMaturity(this.props.areaId).then((result) =>
      this.setState({ maturity: result })
    );
  }

  componentDidUpdate(){

    window.onpopstate  = (e) => {
      if (this.state.openPDFDialog) {
        this.handlePDFClickClose();
        window.history.pushState(null, null, null);
      }
    }
  
  }

  handleClickOpen(currentTopic) {
    let rating = { relevance: 0, specificity: 0, control: 0, impacts: 0 };
    area.getMaturity(this.props.areaId).then((result) => {
      result.map((row) => {
        if (row.topic === currentTopic) {
          rating = { relevance: row.relevance.toString(), specificity: row.specificity.toString(), control: row.control.toString(), impacts: row.impacts.toString() }
        }
        return true;
      });
      this.setState({ openDialog: true, currentTopic: currentTopic, currentRating: rating });
    });
    
  }

  handleClickClose() {
    this.setState({ openDialog: false, currentTopic: '', currentRating: { relevance: 0, specificity: 0, control: 0, impacts: 0 } });
    area.getMaturity(this.props.areaId).then((result) => {
      this.setState({ maturity: result });
      this.forceUpdate();
    });
  }

  handleChange = (criteria) => (event) => {
    switch (criteria) {
      case 'relevance':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, relevance: event.target.value } });
        break;
      case 'specificity':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, specificity: event.target.value } });
        break;
      case 'control':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, control: event.target.value } });
        break;
      case 'impacts':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, impacts: event.target.value } });
        break;
      default:
        break;
    }
  }

  handleSave(e) {
    e.preventDefault();
    area.saveMaturity(this.props.areaId, this.state.currentTopic, this.state.currentRating).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="maturity.updated" />,
        openSnack: true
      });
      this.handleClickClose();
    })
  }

  makeResult(item) {

    var result = { sum: 0, interpretation: '', recommendation: '' };

    this.state.maturity.map((row) => {
      if (row.topic === item) {

        result.sum = row.relevance + row.specificity + row.control + row.impacts;

        if (row.topic.split('.')[0] === 'strategy') {

          if (result.sum >= 0 && result.sum <= 10) {

            result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Sensibilización de policy makers sobre valor de la GC en la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_01_SensibPMakers.pdf')}>MADGC01</Link>,
                                        sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Comunicación y publicaciones divulgativas de I+D+i a stakeholders y sociedad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_02_ComPubStakeholders.pdf')}>MADGC02</Link>
                                      }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Sensibilización de policy makers sobre valor de la GC en la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_01_SensibPMakers.pdf')}>MADGC01</Link>,
                                        sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Comunicación y publicaciones divulgativas de I+D+i a stakeholders y sociedad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_02_ComPubStakeholders.pdf')}>MADGC02</Link>
                                      }} />;

          } else if (result.sum > 10 && result.sum <= 15) {

            result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Scoreboard GC e innovación (impactos)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_03_Score_GC_Innov.pdf')}>MADGC03</Link>
                                      }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Scoreboard GC e innovación (impactos)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_03_Score_GC_Innov.pdf')}>MADGC03</Link>
                                      }} />;

          } else if (result.sum > 15 && result.sum <= 20) {

            result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Innovación en estrategia regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_04_Innov_Est_Reg_I%2BD%2Bi.pdf')}>MADGC04</Link>
                                    }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Innovación en estrategia regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_04_Innov_Est_Reg_I%2BD%2Bi.pdf')}>MADGC04</Link>
                                      }} />;
          }

        } else if (row.topic.split('.')[0] === 'connect') {

          if (result.sum >= 0 && result.sum <= 10) {

            if ((item === 'connect.item_one') || (item === 'connect.item_two')) {
            
              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Benchmarking sobre estrategias de I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_05_Bench_%20Estrat_I%2BD%2Bi_Reg.pdf')}>MADGC05</Link>,
                                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Benchmarking sobre observatorios regionales de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_06_Bench_Observat_Reg_I%2BD%2Bi.pdf')}>MADGC06</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Benchmarking sobre estrategias de I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_05_Bench_%20Estrat_I%2BD%2Bi_Reg.pdf')}>MADGC05</Link>,
                                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Benchmarking sobre observatorios regionales de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_06_Bench_Observat_Reg_I%2BD%2Bi.pdf')}>MADGC06</Link>
                                        }} />;
                                    
            } else {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización de policy makers sobre valor de la GC en la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_01_SensibPMakers.pdf')}>MADGC01</Link>,
                                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Comunicación y publicaciones divulgativas de I+D+i a stakeholders y sociedad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_02_ComPubStakeholders.pdf')}>MADGC02</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización de policy makers sobre valor de la GC en la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_01_SensibPMakers.pdf')}>MADGC01</Link>,
                                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Comunicación y publicaciones divulgativas de I+D+i a stakeholders y sociedad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_02_ComPubStakeholders.pdf')}>MADGC02</Link>
                                        }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Sistema de seguimiento y control del I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_07_Sist_Seg_Contr_I%2BD%2Bi.pdf')}>MADGC07</Link>,
                                        sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Análisis de rendimientos del I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_08_An%C3%A1lisis_Rtos_I%2BD%2Bi.pdf')}>MADGC08</Link>
                                      }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Sistema de seguimiento y control del I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_07_Sist_Seg_Contr_I%2BD%2Bi.pdf')}>MADGC07</Link>,
                                        sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Análisis de rendimientos del I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_08_An%C3%A1lisis_Rtos_I%2BD%2Bi.pdf')}>MADGC08</Link>
                                      }} />;

          } else if (result.sum > 15 && result.sum <= 20) {
            
            result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Innovación en seguimiento, comunicación y productos de conocimiento sobre I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_09_Innov_Seguim_Com_Prod_I%2BD%2Bi.pdf')}>MADGC09</Link>
                                    }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Innovación en seguimiento, comunicación y productos de conocimiento sobre I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_09_Innov_Seguim_Com_Prod_I%2BD%2Bi.pdf')}>MADGC09</Link>
                                      }} />;

          }

        } else if (row.topic.split('.')[0] === 'sort') {

          if (result.sum >= 0 && result.sum <= 10) {

            if ((item === 'sort.item_one')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema centralizado de información sobre I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_10_Sist_Info_Centra_I%2BD%2Bi.pdf')}>MADGC10</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema centralizado de información sobre I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_10_Sist_Info_Centra_I%2BD%2Bi.pdf')}>MADGC10</Link>
                                        }} />;

            } else if ((item === 'sort.item_two') || (item === 'sort.item_four')) {
  
              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción de la I+D+i en el sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_11_Prom_I%2BD%2Bi_SPubreg.pdf')}>MADGC11</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción de la I+D+i en el sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_11_Prom_I%2BD%2Bi_SPubreg.pdf')}>MADGC11</Link>
                                        }} />;

            } else if ((item === 'sort.item_three')) {
  
              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción de la I+D+i en el sector privado regional (ya hecha en MCIREG17)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_12_Promoc_I%2BD%2BiSPriv.pdf')}>MADGC12</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción de la I+D+i en el sector privado regional (ya hecha en MCIREG17)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_12_Promoc_I%2BD%2BiSPriv.pdf')}>MADGC12</Link>
                                        }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if ((item === 'sort.item_one')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Mapa de sitios web regionales de referencia en I+D+i (y sinergias)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_13_Mapa_S_Web.pdf')}>MADGC13</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Mapa de sitios web regionales de referencia en I+D+i (y sinergias)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_13_Mapa_S_Web.pdf')}>MADGC13</Link>
                                        }} />;

            } else if ((item === 'sort.item_two') || (item === 'sort.item_four')) {
  
              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Casos de éxito de la innovación en el sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_14_%20Casos_exito_SPub.pdf')}>MADGC14</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Casos de éxito de la innovación en el sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_14_%20Casos_exito_SPub.pdf')}>MADGC14</Link>
                                        }} />;

            } else if ((item === 'sort.item_three')) {
  
              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Casos de éxito de la innovación en el sector privado regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_15_Casos_Exito_Innov_SecPrivReg.pdf')}>MADGC15</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Casos de éxito de la innovación en el sector privado regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_15_Casos_Exito_Innov_SecPrivReg.pdf')}>MADGC15</Link>
                                        }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            if ((item === 'sort.item_one')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Certificaciones y premios relativos al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_18_Certif_Premios_I%2BD%2Bi.pdf')}>MADGC18</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Certificaciones y premios relativos al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_18_Certif_Premios_I%2BD%2Bi.pdf')}>MADGC18</Link>
                                        }} />;

            } else if ((item === 'sort.item_two') || (item === 'sort.item_four')) {
  
              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Estrategia de comportamiento innovador para las organizaciones del sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_16_Estrat_Comport_Innov_Orgs_SPub.pdf')}>MADGC16</Link>,
                                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Certificaciones y premios relativos al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_18_Certif_Premios_I%2BD%2Bi.pdf')}>MADGC18</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Estrategia de comportamiento innovador para las organizaciones del sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_16_Estrat_Comport_Innov_Orgs_SPub.pdf')}>MADGC16</Link>,
                                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Certificaciones y premios relativos al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_18_Certif_Premios_I%2BD%2Bi.pdf')}>MADGC18</Link>
                                        }} />;

            } else if ((item === 'sort.item_three')) {
  
              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Estrategia de comportamiento innovador para las empresas del sector privado regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_17_Estrat_Comport_Innov_SPrivReg.pdf')}>MADGC17</Link>,
                                        sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Certificaciones y premios relativos al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_18_Certif_Premios_I%2BD%2Bi.pdf')}>MADGC18</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Estrategia de comportamiento innovador para las empresas del sector privado regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_17_Estrat_Comport_Innov_SPrivReg.pdf')}>MADGC17</Link>,
                                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Certificaciones y premios relativos al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_18_Certif_Premios_I%2BD%2Bi.pdf')}>MADGC18</Link>
                                        }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'socialize') {

          if (result.sum >= 0 && result.sum <= 10) {

            result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Programas de modernización y competitividad de las organizaciones regionales', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_20_Prog_Moderniz_Competi.pdf')}>MADGC20</Link>
                                      }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Programas de modernización y competitividad de las organizaciones regionales', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_20_Prog_Moderniz_Competi.pdf')}>MADGC20</Link>
                                      }} />;

          } else if (result.sum > 10 && result.sum <= 15) {

            result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Fomento del desarrollo clusters con vocación de I+D+i, agrupaciones empresariales, mesas de trabajo, fundaciones', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_19_Fom_Desar_Cluster_etc_I%2BD%2Bi.pdf')}>MADGC19</Link>,
                                        sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Posicionamiento internacional de estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones) con vocación de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_21_Posic_Internac_Clusters_etc.pdf')}>MADGC21</Link>
                                      }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Fomento del desarrollo clusters con vocación de I+D+i, agrupaciones empresariales, mesas de trabajo, fundaciones', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_19_Fom_Desar_Cluster_etc_I%2BD%2Bi.pdf')}>MADGC19</Link>,
                                        sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Posicionamiento internacional de estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones) con vocación de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_21_Posic_Internac_Clusters_etc.pdf')}>MADGC21</Link>
                                      }} />;

          } else if (result.sum > 15 && result.sum <= 20) {
            
            result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Scoreboard vinculado con la labor de las estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_22_Score_Estruc_Reg_I%2BD%2Bi.pdf')}>MADGC22</Link>
                                    }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Scoreboard vinculado con la labor de las estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_22_Score_Estruc_Reg_I%2BD%2Bi.pdf')}>MADGC22</Link>
                                      }} />;

          }

        } else if (row.topic.split('.')[0] === 'develop') {

          if (result.sum >= 0 && result.sum <= 10) {

            if ((item === 'develop.item_one') || (item === 'develop.item_two')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Nuevas titulaciones disponibles en la región (grados y postgrados incluyendo un perfil de mayor I+D+i)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_23_Nuevas_Titulac_PerfilI%2BD%2Bi.pdf')}>MADGC23</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Nuevas titulaciones disponibles en la región (grados y postgrados incluyendo un perfil de mayor I+D+i)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_23_Nuevas_Titulac_PerfilI%2BD%2Bi.pdf')}>MADGC23</Link>
                                        }} />;

            } else if (item === 'develop.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de selección de directrices estratégicas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_32_Sist_Selecc_Direc_Estra_I%2BD%2Bi.pdf')}>MADGC32</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de selección de directrices estratégicas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_32_Sist_Selecc_Direc_Estra_I%2BD%2Bi.pdf')}>MADGC32</Link>
                                        }} />;

            } else if (item === 'develop.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción del emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_24_Promoc_Esp_Emprend_Innov.pdf')}>MADGC24</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción del emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_24_Promoc_Esp_Emprend_Innov.pdf')}>MADGC24</Link>
                                        }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'develop.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Titulaciones con núcleo emprendedor innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_25_Titulac_Perfil_EmprenInnov.pdf')}>MADGC25</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Titulaciones con núcleo emprendedor innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_25_Titulac_Perfil_EmprenInnov.pdf')}>MADGC25</Link>
                                        }} />;

            } else if (item === 'develop.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Mercado para el emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_26_Mercado_Empren_Innov.pdf')}>MADGC26</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Mercado para el emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_26_Mercado_Empren_Innov.pdf')}>MADGC26</Link>
                                        }} />;

            } else if ((item === 'develop.item_three') || (item === 'develop.item_four')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Mapa de apoyo del sector público a la I+D+i (incluyendo emprendimiento)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_27_Mapa_SP%C3%BAblico.pdf')}>MADGC27</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Mapa de apoyo del sector público a la I+D+i (incluyendo emprendimiento)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_27_Mapa_SP%C3%BAblico.pdf')}>MADGC27</Link>
                                        }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            if (item === 'develop.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Estrategia de identificación, retención y atracción de talento emprendedor (atracción)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_29_Estrat_Ident_Reten_Atracc_Talento_Emprend.pdf')}>MADGC29</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Estrategia de identificación, retención y atracción de talento emprendedor (atracción)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_29_Estrat_Ident_Reten_Atracc_Talento_Emprend.pdf')}>MADGC29</Link>
                                        }} />;

            } else if (item === 'develop.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Modelo de aceleración de emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_28_Mod.Aceler_EmprInnov.pdf')}>MADGC28</Link>,
                                                      sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                                  onClick={event => this.handlePDFClickOpen('Lanzadera de proyectos emprendedores innovadores', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_30_Lanzadera_Proy_Empren_Innov.pdf')}>MADGC30</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Modelo de aceleración de emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_28_Mod.Aceler_EmprInnov.pdf')}>MADGC28</Link>,
                                                      sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                                  onClick={event => this.handlePDFClickOpen('Lanzadera de proyectos emprendedores innovadores', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_30_Lanzadera_Proy_Empren_Innov.pdf')}>MADGC30</Link>
                                        }} />;

            } else if ((item === 'develop.item_three') || (item === 'develop.item_four')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Scoreboard sobre emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_31_Score_Emp_Innov.pdf')}>MADGC31</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Scoreboard sobre emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_31_Score_Emp_Innov.pdf')}>MADGC31</Link>
                                        }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'policies') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'policies.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de selección de directrices estratégicas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_32_Sist_Selecc_Direc_Estra_I%2BD%2Bi.pdf')}>MADGC32</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de selección de directrices estratégicas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_32_Sist_Selecc_Direc_Estra_I%2BD%2Bi.pdf')}>MADGC32</Link>
                                        }} />;

            } else if (item === 'policies.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Diseño de esquemas de cofinanciación del I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_33_Dise%C3%B1o_Esq_Cofinan_I%2BD%2Bi.pdf')}>MADGC33</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Diseño de esquemas de cofinanciación del I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_33_Dise%C3%B1o_Esq_Cofinan_I%2BD%2Bi.pdf')}>MADGC33</Link>
                                        }} />;

            } else if (item === 'policies.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Diseño de esquemas de créditos reembolsables para I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_34_Dise%C3%B1o_Esq_Cred_Reemb.pdf')}>MADGC34</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Diseño de esquemas de créditos reembolsables para I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_34_Dise%C3%B1o_Esq_Cred_Reemb.pdf')}>MADGC34</Link>
                                        }} />;

            } else if (item === 'policies.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilidad de indicios de patentabilidad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_35_Sensib_Indicios_Patent.pdf')}>MADGC35</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilidad de indicios de patentabilidad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_35_Sensib_Indicios_Patent.pdf')}>MADGC35</Link>
                                        }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'policies.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de asignación y control de ayudas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_36_Sist_Asig_Ayu_I%2BD%2Bi.pdf')}>MADGC36</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de asignación y control de ayudas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_36_Sist_Asig_Ayu_I%2BD%2Bi.pdf')}>MADGC36</Link>
                                        }} />;
                                
            } else if (item === 'policies.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Lanzamiento de ayudas de I+D+i en régimen de confinanciacion', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_37_Lanz_Ayudas_I%2BD%2Bi_Cofinan.pdf')}>MADGC37</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Lanzamiento de ayudas de I+D+i en régimen de confinanciacion', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_37_Lanz_Ayudas_I%2BD%2Bi_Cofinan.pdf')}>MADGC37</Link>
                                        }} />;

            } else if (item === 'policies.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Lanzamiento de ayudas de I+D+i en régimen de créditos reembolsables', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_38_Lanz_Ayud_I%2BD%2Bi_CredReem.pdf')}>MADGC38</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer' }}
                                        onClick={event => this.handlePDFClickOpen('Lanzamiento de ayudas de I+D+i en régimen de créditos reembolsables', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_38_Lanz_Ayud_I%2BD%2Bi_CredReem.pdf')}>MADGC38</Link>
                          }} />;

            } else if (item === 'policies.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC43</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer' }}
                                        onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC43</Link>
                          }} />;


            }

          } else if (result.sum > 15 && result.sum <= 20) {

            if (item === 'policies.item_one') {
            
              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de control de impactos derivados de las ayudas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_39_Sist_Contr_Impac_Ayu_I%2BD%2Bi.pdf')}>MADGC39</Link>
                                      }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de control de impactos derivados de las ayudas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_39_Sist_Contr_Impac_Ayu_I%2BD%2Bi.pdf')}>MADGC39</Link>
                                        }} />;

            } else if (item === 'policies.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Diseño de esquemas de alta cofinanciación empresarial para el sector público en el ámbito del I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_40_Dise%C3%B1o_Lanz_Alta_Cofinan.pdf')}>MADGC40</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Diseño de esquemas de alta cofinanciación empresarial para el sector público en el ámbito del I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_40_Dise%C3%B1o_Lanz_Alta_Cofinan.pdf')}>MADGC40</Link>
                                        }} />;

            } else if (item === 'policies.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Control de riesgos y morosidad para créditos reembolsables de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_41_Control_Riesgos_Morosidad_CredReem.pdf')}>MADGC41</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Control de riesgos y morosidad para créditos reembolsables de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_41_Control_Riesgos_Morosidad_CredReem.pdf')}>MADGC41</Link>
                                        }} />;

            } else if (item === 'policies.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción de casos de éxito de protección de resultados I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_42_Prom_Casos_Exito_Protecc_Rdos.pdf')}>MADGC42</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer' }}
                                        onClick={event => this.handlePDFClickOpen('Promoción de casos de éxito de protección de resultados I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_42_Prom_Casos_Exito_Protecc_Rdos.pdf')}>MADGC42</Link>
                          }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'structures') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'structures.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Selección, orientación y evolución de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_44_Selecc_Orientac_Evol_Estruc_Apoyo.pdf')}>MADGC44</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Selección, orientación y evolución de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_44_Selecc_Orientac_Evol_Estruc_Apoyo.pdf')}>MADGC44</Link>
                                        }} />;

            } else if ((item === 'structures.item_two') || (item === 'structures.item_three')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización de portafolio de servicios para las estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_45_Sensib_Serv_EstrucApoyo_I%2BD%2Bi.pdf')}>MADGC45</Link>
                                        }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Sensibilización de portafolio de servicios para las estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_45_Sensib_Serv_EstrucApoyo_I%2BD%2Bi.pdf')}>MADGC45</Link>
                                      }} />;

            } else if (item === 'structures.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Programa apoyo transferencia resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_46_Prog_Apoyo_Transf_Rdos.pdf')}>MADGC46</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Programa apoyo transferencia resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_46_Prog_Apoyo_Transf_Rdos.pdf')}>MADGC46</Link>
                                        }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if ((item === 'structures.item_one') || (item === 'structures.item_two') || (item === 'structures.item_three')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Espacio de coordinación de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_47_Espac_Coord_Estruc_Apoyo.pdf')}>MADGC47</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Espacio de coordinación de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_47_Espac_Coord_Estruc_Apoyo.pdf')}>MADGC47</Link>
                                        }} />;

            } else if (item === 'structures.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Programa de comercialización de resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_48_Prog_Comerc_Rdos.pdf')}>MADGC48</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Programa de comercialización de resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_48_Prog_Comerc_Rdos.pdf')}>MADGC48</Link>
                                        }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Sistema de seguimiento e integración de resultados derivados de las estructuras', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_49_Sist_Seg_Integ_Estruc_I%2BD%2Bi.pdf')}>MADGC49</Link>
                                    }} />;

            result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                      values={{
                                        sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Sistema de seguimiento e integración de resultados derivados de las estructuras', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_49_Sist_Seg_Integ_Estruc_I%2BD%2Bi.pdf')}>MADGC49</Link>
                                      }} />;

          }

        } else if (row.topic.split('.')[0] === 'technology') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'technology.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre virtualización de servicios de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_50_Sensib_Virtualiz_Serv_Apoyo_I%2BD%2Bi.pdf')}>MADGC50</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre virtualización de servicios de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_50_Sensib_Virtualiz_Serv_Apoyo_I%2BD%2Bi.pdf')}>MADGC50</Link>
                                        }} />;

            } else if (item === 'technology.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre el uso de bases de datos y publicaciones de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_51_Sensib_Uso_BBDDyPub.pdf')}>MADGC51</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre el uso de bases de datos y publicaciones de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_51_Sensib_Uso_BBDDyPub.pdf')}>MADGC51</Link>
                                        }} />;

            } else if (item === 'technology.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción del emparejamiento de oferta y demanda sobre I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_52_Prom_Emparej_Ofer_Demand.pdf')}>MADGC52</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Promoción del emparejamiento de oferta y demanda sobre I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_52_Prom_Emparej_Ofer_Demand.pdf')}>MADGC52</Link>
                                        }} />;

            } else if (item === 'technology.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre espacios de crowdsourcing', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_53_Sensib_Espac_Crowdsourc.pdf')}>MADGC53</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre espacios de crowdsourcing', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_53_Sensib_Espac_Crowdsourc.pdf')}>MADGC53</Link>
                                        }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'technology.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Portal web de servicios virtuales de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_54_Portal_web_servicios_I%2BD%2Bi.pdf')}>MADGC54</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Portal web de servicios virtuales de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_54_Portal_web_servicios_I%2BD%2Bi.pdf')}>MADGC54</Link>
                                        }} />;

            } else if (item === 'technology.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Portal de servicios de acceso a bases de datos y publicaciones relacionadas con el I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_55_Portal_Serv_BBDD_I%2BD%2Bi.pdf')}>MADGC55</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Portal de servicios de acceso a bases de datos y publicaciones relacionadas con el I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_55_Portal_Serv_BBDD_I%2BD%2Bi.pdf')}>MADGC55</Link>
                                        }} />;

            } else if (item === 'technology.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Portal maketplace de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_56_Portal_Marketplace_I%2BD%2Bi.pdf')}>MADGC56</Link>,
                                                      sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                                  onClick={event => this.handlePDFClickOpen('Incentivos y referencias para promover un marketplace de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_57_Incentiv_Refer_Marketplace.pdf')}>MADGC57</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Portal maketplace de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_56_Portal_Marketplace_I%2BD%2Bi.pdf')}>MADGC56</Link>,
                                                      sheetTwo: <Link style={{ cursor: 'pointer' }}
                                                                  onClick={event => this.handlePDFClickOpen('Incentivos y referencias para promover un marketplace de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_57_Incentiv_Refer_Marketplace.pdf')}>MADGC57</Link>
                                        }} />;

            } else if (item === 'technology.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Portal de crowdsourcing', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_58_Portal_Crowdsourcing_I%2BD%2Bi.pdf')}>MADGC58</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Portal de crowdsourcing', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_58_Portal_Crowdsourcing_I%2BD%2Bi.pdf')}>MADGC58</Link>
                                        }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            if ((item === 'technology.item_one') || (item === 'technology.item_three') || (item === 'technology.item_four')) {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de reporting de I+D+i para policy makers', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_59_Sist_Report_I%2BD%2Bi_PMakers.pdf')}>MADGC59</Link>
                                      }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sistema de reporting de I+D+i para policy makers', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_59_Sist_Report_I%2BD%2Bi_PMakers.pdf')}>MADGC59</Link>
                                        }} />;

            } else if (item === 'technology.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Biblioteca de bases de datos y publicaciones top', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_60_Bibliot_BBDD_Pub.pdf')}>MADGC60</Link>
                                      }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Biblioteca de bases de datos y publicaciones top', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_60_Bibliot_BBDD_Pub.pdf')}>MADGC60</Link>
                                        }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'results') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'results.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Análisis sobre I+D+i territorial', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_61_Analisis_I%2BD%2Bi_Territorial.pdf')}>MADGC61</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Análisis sobre I+D+i territorial', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_61_Analisis_I%2BD%2Bi_Territorial.pdf')}>MADGC61</Link>
                                        }} />;

            } else if (item === 'results.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Metas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_62_Metas_I%2BD%2Bi.pdf')}>MADGC62</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Metas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_62_Metas_I%2BD%2Bi.pdf')}>MADGC62</Link>
                                        }} />;

            } else if (item === 'results.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre la transparencia en las ayudas e inversiones relacionadas con la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_63_Sensib_Transparencia_Ayudas.pdf')}>MADGC63</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre la transparencia en las ayudas e inversiones relacionadas con la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_63_Sensib_Transparencia_Ayudas.pdf')}>MADGC63</Link>
                                        }} />;

            } else if (item === 'results.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre criterios para el prestigio regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_64_Sensib_Criterios_Prestig_Reg_I%2BD%2Bi.pdf')}>MADGC64</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Sensibilización sobre criterios para el prestigio regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_64_Sensib_Criterios_Prestig_Reg_I%2BD%2Bi.pdf')}>MADGC64</Link>
                                        }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'results.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC65</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC65</Link>
                                        }} />;

            } else if (item === 'results.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC66</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC66</Link>
                                        }} />;

            } else if (item === 'results.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC67</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC67</Link>
                                        }} />;

            } else if (item === 'results.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC68</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC68</Link>
                                        }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            if (item === 'results.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC69</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC69</Link>
                                        }} />;

            } else if (item === 'results.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC70</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC70</Link>
                                        }} />;

            } else if (item === 'results.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC71</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC71</Link>
                                        }} />;

            } else if (item === 'results.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADGC72</Link>
                                        }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three'}
                                        values={{
                                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                                      onClick={event => this.handlePDFClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>MADG72</Link>
                                        }} />;

            }

          }

        }
      }
      return true
    });

    return result;
  }

  makeFocusResult(item) {

    var result = this.makeResult(item + '.item_one').sum + this.makeResult(item + '.item_two').sum + this.makeResult(item + '.item_three').sum + this.makeResult(item + '.item_four').sum;
    var recommendation = '';

    if (result >= 0 && result <= 30) {
      
      if (item === 'connect') {

        recommendation = <FormattedMessage id={item + '.recommendation_one'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sensibilización de policy makers sobre valor de la GC en la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_01_SensibPMakers.pdf')}>MADGC01</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Comunicación y publicaciones divulgativas de I+D+i a stakeholders y sociedad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_02_ComPubStakeholders.pdf')}>MADGC02</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Benchmarking sobre estrategias de I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_05_Bench_%20Estrat_I%2BD%2Bi_Reg.pdf')}>MADGC05</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Benchmarking sobre observatorios regionales de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_06_Bench_Observat_Reg_I%2BD%2Bi.pdf')}>MADGC06</Link>
                          }} />;

      } else if (item === 'sort') {

        recommendation = <FormattedMessage id={item + '.recommendation_one'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema centralizado de información sobre I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_10_Sist_Info_Centra_I%2BD%2Bi.pdf')}>MADGC10</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Promoción de la I+D+i en el sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_11_Prom_I%2BD%2Bi_SPubreg.pdf')}>MADGC11</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Promoción de la I+D+i en el sector privado regional (ya hecha en MCIREG17)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_12_Promoc_I%2BD%2BiSPriv.pdf')}>MADGC12</Link>
                          }} />;
      
      } else if (item === 'socialize') {

        recommendation = <FormattedMessage id={item + '.recommendation_one'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Programas de modernización y competitividad de las organizaciones regionales', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_20_Prog_Moderniz_Competi.pdf')}>MADGC20</Link>
                          }} />;

      } else if (item === 'develop') {

        recommendation = <FormattedMessage id={item + '.recommendation_one'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Nuevas titulaciones disponibles en la región (grados y postgrados incluyendo un perfil de mayor I+D+i)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_23_Nuevas_Titulac_PerfilI%2BD%2Bi.pdf')}>MADGC23</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Promoción del emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_24_Promoc_Esp_Emprend_Innov.pdf')}>MADGC24</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema de selección de directrices estratégicas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_32_Sist_Selecc_Direc_Estra_I%2BD%2Bi.pdf')}>MADGC32</Link>
                          }} />;
      
      } else if (item === 'policies') {

        recommendation = <FormattedMessage id={item + '.recommendation_one'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema de selección de directrices estratégicas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_32_Sist_Selecc_Direc_Estra_I%2BD%2Bi.pdf')}>MADGC32</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Diseño de esquemas de cofinanciación del I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_33_Dise%C3%B1o_Esq_Cofinan_I%2BD%2Bi.pdf')}>MADGC33</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Diseño de esquemas de créditos reembolsables para I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_34_Dise%C3%B1o_Esq_Cred_Reemb.pdf')}>MADGC34</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sensibilidad de indicios de patentabilidad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_35_Sensib_Indicios_Patent.pdf')}>MADGC35</Link>
                          }} />;

      } else if (item === 'structures') {

        recommendation = <FormattedMessage id={item + '.recommendation_one'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Selección, orientación y evolución de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_44_Selecc_Orientac_Evol_Estruc_Apoyo.pdf')}>MADGC44</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sensibilización de portafolio de servicios para las estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_45_Sensib_Serv_EstrucApoyo_I%2BD%2Bi.pdf')}>MADGC45</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Programa apoyo transferencia resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_46_Prog_Apoyo_Transf_Rdos.pdf')}>MADGC46</Link>
                          }} />;

      } else if (item === 'technology') {

        recommendation = <FormattedMessage id={item + '.recommendation_one'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Selección, orientación y evolución de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_44_Selecc_Orientac_Evol_Estruc_Apoyo.pdf')}>MADGC44</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sensibilización de portafolio de servicios para las estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_45_Sensib_Serv_EstrucApoyo_I%2BD%2Bi.pdf')}>MADGC45</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Programa apoyo transferencia resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_46_Prog_Apoyo_Transf_Rdos.pdf')}>MADGC46</Link>
                          }} />;

      } else if (item === 'results') {

        recommendation = <FormattedMessage id={item + '.recommendation_one'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Análisis sobre I+D+i territorial', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_61_Analisis_I%2BD%2Bi_Territorial.pdf')}>MADGC61</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Metas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_62_Metas_I%2BD%2Bi.pdf')}>MADGC62</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sensibilización sobre la transparencia en las ayudas e inversiones relacionadas con la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_63_Sensib_Transparencia_Ayudas.pdf')}>MADGC63</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sensibilización sobre criterios para el prestigio regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_64_Sensib_Criterios_Prestig_Reg_I%2BD%2Bi.pdf')}>MADGC64</Link>
                          }} />;
      
      }
    
    } else if (result > 30 && result <= 60) {
    
      if (item === 'connect') {

        recommendation = <FormattedMessage id={item + '.recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema de seguimiento y control del I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_07_Sist_Seg_Contr_I%2BD%2Bi.pdf')}>MADGC07</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Análisis de rendimientos del I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_08_An%C3%A1lisis_Rtos_I%2BD%2Bi.pdf')}>MADGC08</Link>
                          }} />;

      } else if (item === 'sort') {

        recommendation = <FormattedMessage id={item + '.recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Mapa de sitios web regionales de referencia en I+D+i (y sinergias)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_13_Mapa_S_Web.pdf')}>MADGC13</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Casos de éxito de la innovación en el sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_14_%20Casos_exito_SPub.pdf')}>MADGC14</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Casos de éxito de la innovación en el sector privado regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_15_Casos_Exito_Innov_SecPrivReg.pdf')}>MADGC15</Link>
                          }} />;
      
      } else if (item === 'socialize') {

        recommendation = <FormattedMessage id={item + '.recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Fomento del desarrollo clusters con vocación de I+D+i, agrupaciones empresariales, mesas de trabajo, fundaciones', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_19_Fom_Desar_Cluster_etc_I%2BD%2Bi.pdf')}>MADGC19</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Posicionamiento internacional de estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones) con vocación de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_21_Posic_Internac_Clusters_etc.pdf')}>MADGC21</Link>
                          }} />;

      } else if (item === 'develop') {

        recommendation = <FormattedMessage id={item + '.recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Titulaciones con núcleo emprendedor innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_25_Titulac_Perfil_EmprenInnov.pdf')}>MADGC25</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Mercado para el emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_26_Mercado_Empren_Innov.pdf')}>MADGC26</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Mapa de apoyo del sector público a la I+D+i (incluyendo emprendimiento)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_27_Mapa_SP%C3%BAblico.pdf')}>MADGC27</Link>
                          }} />;
      
      } else if (item === 'policies') {

        recommendation = <FormattedMessage id={item + '.recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema de asignación y control de ayudas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_36_Sist_Asig_Ayu_I%2BD%2Bi.pdf')}>MADGC36</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Lanzamiento de ayudas de I+D+i en régimen de confinanciacion', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_37_Lanz_Ayudas_I%2BD%2Bi_Cofinan.pdf')}>MADGC37</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Lanzamiento de ayudas de I+D+i en régimen de créditos reembolsables', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_38_Lanz_Ayud_I%2BD%2Bi_CredReem.pdf')}>MADGC38</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Oferta servicios de protección de resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_43_Ofer_Serv_Protecc_Rdos.pdf')}>MADGC43</Link>
                          }} />;

      } else if (item === 'structures') {

        recommendation = <FormattedMessage id={item + '.recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Espacio de coordinación de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_47_Espac_Coord_Estruc_Apoyo.pdf')}>MADGC47</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Programa de comercialización de resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_48_Prog_Comerc_Rdos.pdf')}>MADGC48</Link>
                          }} />;

      } else if (item === 'technology') {

        recommendation = <FormattedMessage id={item + '.recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Portal web de servicios virtuales de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_54_Portal_web_servicios_I%2BD%2Bi.pdf')}>MADGC54</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Portal de servicios de acceso a bases de datos y publicaciones relacionadas con el I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_55_Portal_Serv_BBDD_I%2BD%2Bi.pdf')}>MADGC55</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Portal maketplace de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_56_Portal_Marketplace_I%2BD%2Bi.pdf')}>MADGC56</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Incentivos y referencias para promover un marketplace de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_57_Incentiv_Refer_Marketplace.pdf')}>MADGC57</Link>,
                            sheetFive: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Portal de crowdsourcing', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_58_Portal_Crowdsourcing_I%2BD%2Bi.pdf')}>MADGC58</Link>
                          }} />;

      } else if (item === 'results') {

        recommendation = <FormattedMessage id={item + '.recommendation_two'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Colección de publicaciones regionales de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_65_Colecc_Pub_Reg_I%2BD%2Bi.pdf')}>MADGC65</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema de control y seguimiento de metas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_66_Sist_Cont_Seg_Metas_I%2BD%2Bi.pdf')}>MADGC66</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Portal de transparencia sobre ayudas e inversiones de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_67_Portal_Transparencia_Ayudas.pdf')}>MADGC67</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Branding regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_68_Branding_Reg_I%2BD%2Bi.pdf')}>MADGC68</Link>
                          }} />;
      
      }
    
    } else if (result > 60 && result <= 80) {
    
      if (item === 'connect') {

        recommendation = <FormattedMessage id={item + '.recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Innovación en seguimiento, comunicación y productos de conocimiento sobre I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_09_Innov_Seguim_Com_Prod_I%2BD%2Bi.pdf')}>MADGC09</Link>
                          }} />;

      } else if (item === 'sort') {

        recommendation = <FormattedMessage id={item + '.recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Estrategia de comportamiento innovador para las organizaciones del sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_16_Estrat_Comport_Innov_Orgs_SPub.pdf')}>MADGC16</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Estrategia de comportamiento innovador para las empresas del sector privado regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_17_Estrat_Comport_Innov_SPrivReg.pdf')}>MADGC17</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Certificaciones y premios relativos al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_18_Certif_Premios_I%2BD%2Bi.pdf')}>MADGC18</Link>
                          }} />;
      
      } else if (item === 'socialize') {

        recommendation = <FormattedMessage id={item + '.recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Scoreboard vinculado con la labor de las estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_22_Score_Estruc_Reg_I%2BD%2Bi.pdf')}>MADGC22</Link>
                          }} />;

      } else if (item === 'develop') {

        recommendation = <FormattedMessage id={item + '.recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Modelo de aceleración de emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_28_Mod.Aceler_EmprInnov.pdf')}>MADGC28</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Estrategia de identificación, retención y atracción de talento emprendedor (atracción)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_29_Estrat_Ident_Reten_Atracc_Talento_Emprend.pdf')}>MADGC29</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Lanzadera de proyectos emprendedores innovadores', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_30_Lanzadera_Proy_Empren_Innov.pdf')}>MADGC30</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                          onClick={event => this.handlePDFClickOpen('Sistema de selección de directrices estratégicas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_32_Sist_Selecc_Direc_Estra_I%2BD%2Bi.pdf')}>MADGC32</Link>
                          }} />;
      
      } else if (item === 'policies') {

        recommendation = <FormattedMessage id={item + '.recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema de control de impactos derivados de las ayudas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_39_Sist_Contr_Impac_Ayu_I%2BD%2Bi.pdf')}>MADGC39</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Diseño de esquemas de alta cofinanciación empresarial para el sector público en el ámbito del I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_40_Dise%C3%B1o_Lanz_Alta_Cofinan.pdf')}>MADGC40</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Control de riesgos y morosidad para créditos reembolsables de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_41_Control_Riesgos_Morosidad_CredReem.pdf')}>MADGC41</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Promoción de casos de éxito de protección de resultados I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_42_Prom_Casos_Exito_Protecc_Rdos.pdf')}>MADGC42</Link>
                          }} />;

      } else if (item === 'structures') {

        recommendation = <FormattedMessage id={item + '.recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema de seguimiento e integración de resultados derivados de las estructuras', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_49_Sist_Seg_Integ_Estruc_I%2BD%2Bi.pdf')}>MADGC49</Link>
                          }} />;

      } else if (item === 'technology') {

        recommendation = <FormattedMessage id={item + '.recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Sistema de reporting de I+D+i para policy makers', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_59_Sist_Report_I%2BD%2Bi_PMakers.pdf')}>MADGC59</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Biblioteca de bases de datos y publicaciones top', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_60_Bibliot_BBDD_Pub.pdf')}>MADGC60</Link>
                          }} />;

      } else if (item === 'results') {

        recommendation = <FormattedMessage id={item + '.recommendation_three'}
                          values={{
                            sheetOne: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Personalización de análisis para diferentes colectivos', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_69_Personaliz_Analisis_Colectivos.pdf')}>MADGC69</Link>,
                            sheetTwo: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Factores clave de éxito para la consecución de las metas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_70_FCE_Consec_Metas.pdf')}>MADGC70</Link>,
                            sheetThree: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Índice de transparencia de I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_71_Indice_Transpar_I%2BD%2Bi.pdf')}>MADGC71</Link>,
                            sheetFour: <Link style={{ cursor: 'pointer', color: '#fff' }}
                                        onClick={event => this.handlePDFClickOpen('Capitalización de logros en branding regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_72_Capitaliz_Logros_Branding_Reg_I%2BD%2Bi.pdf')}>MADGC72</Link>
                          }} />;
      
      }
    
    }
    return recommendation;
  }

  handlePDFClickOpen(title, url) {
    this.setState({ openPDFDialog: true, title: title, url: url });
  }

  handlePDFClickClose() {
    this.setState({ openPDFDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          { 'Valoración y Análisis de Madurez de la Gestión de Conocimiento' }
        </Typography>

        <Dialog
          fullWidth={true}
          open={this.state.openPDFDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handlePDFClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Valoración</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_title' } /> : null }</b>
              <br />{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_desc' } /> : null }
            </DialogContentText>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><b>{ 'Relevancia'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '1'} value={1} onChange={this.handleChange('relevance')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '2'} value={2} onChange={this.handleChange('relevance')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '3'} value={3} onChange={this.handleChange('relevance')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '4'} value={4} onChange={this.handleChange('relevance')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '5'} value={5} onChange={this.handleChange('relevance')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Especificidad'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '1'} value={1} onChange={this.handleChange('specificity')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '2'} value={2} onChange={this.handleChange('specificity')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '3'} value={3} onChange={this.handleChange('specificity')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '4'} value={4} onChange={this.handleChange('specificity')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '5'} value={5} onChange={this.handleChange('specificity')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Control'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '1'} value={1} onChange={this.handleChange('control')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '2'} value={2} onChange={this.handleChange('control')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '3'} value={3} onChange={this.handleChange('control')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '4'} value={4} onChange={this.handleChange('control')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '5'} value={5} onChange={this.handleChange('control')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Impactos'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '1'} value={1} onChange={this.handleChange('impacts')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '2'} value={2} onChange={this.handleChange('impacts')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '3'} value={3} onChange={this.handleChange('impacts')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '4'} value={4} onChange={this.handleChange('impacts')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '5'} value={5} onChange={this.handleChange('impacts')} color="primary" />} /> 
                  </RadioGroup>
                  </TableCell>
                </TableRow>                                                
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              disabled={ this.state.currentRating.relevance === 0 || this.state.currentRating.specificity === 0 || this.state.currentRating.control === 0 || this.state.currentRating.impacts === 0 }
              onClick={this.handleSave}
              color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '20%' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '2%' }} align={'left'}>{ ' Valoración' }</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '45%' }} align={'left'}>{ ' Interpretación' }</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '33%' }} align={'left'}>{ ' Recomendaciones' }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="strategy.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="strategy.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('strategy.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="strategy.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('strategy.item_one')} style={{ cursor: 'pointer' }}  className={ classes.colOne } align={'left'}>{ this.makeResult('strategy.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('strategy.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('strategy.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('strategy.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="strategy.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('strategy.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('strategy.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('strategy.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('strategy.item_two').recommendation }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="connect.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="connect.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('connect.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="connect.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('connect.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('connect.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('connect.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="connect.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('connect.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('connect.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('connect.item_three')} className={ classes.colPointer } align={'left'}><FormattedMessage id="connect.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('connect.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('connect.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('connect.item_four')} className={ classes.colPointer } align={'left'}><FormattedMessage id="connect.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('connect.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('connect.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                { this.makeResult('connect.item_one').sum + this.makeResult('connect.item_two').sum + this.makeResult('connect.item_three').sum + this.makeResult('connect.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('connect') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="sort.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="sort.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('sort.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="sort.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('sort.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('sort.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('sort.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="sort.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('sort.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('sort.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('sort.item_three')} className={ classes.colPointer } align={'left'}><FormattedMessage id="sort.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('sort.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('sort.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('sort.item_four')} className={ classes.colPointer } align={'left'}><FormattedMessage id="sort.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('sort.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('sort.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('sort.item_one').sum + this.makeResult('sort.item_two').sum + this.makeResult('sort.item_three').sum + this.makeResult('sort.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('sort') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="socialize.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="socialize.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="socialize.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('socialize.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="socialize.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('socialize.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_three')} className={ classes.colPointer } align={'left'}><FormattedMessage id="socialize.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('socialize.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_four')} className={ classes.colPointer } align={'left'}><FormattedMessage id="socialize.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('socialize.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('socialize.item_one').sum + this.makeResult('socialize.item_two').sum + this.makeResult('socialize.item_three').sum + this.makeResult('socialize.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('socialize') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="develop.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="develop.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('develop.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="develop.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('develop.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('develop.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('develop.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="develop.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('develop.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('develop.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('develop.item_three')} className={ classes.colPointer } align={'left'}><FormattedMessage id="develop.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('develop.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('develop.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('develop.item_four')} className={ classes.colPointer } align={'left'}><FormattedMessage id="develop.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('develop.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('develop.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="develop.title" /></TableCell>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('develop.item_one').sum + this.makeResult('develop.item_two').sum + this.makeResult('develop.item_three').sum + this.makeResult('develop.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('develop') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="policies.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="policies.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('policies.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="policies.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('policies.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('policies.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('policies.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="policies.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('policies.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('policies.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('policies.item_three')} className={ classes.colPointer } align={'left'}><FormattedMessage id="policies.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('policies.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('policies.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('policies.item_four')} className={ classes.colPointer } align={'left'}><FormattedMessage id="policies.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('policies.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('policies.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_four').recommendation }</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('policies.item_one').sum + this.makeResult('policies.item_two').sum + this.makeResult('policies.item_three').sum + this.makeResult('policies.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('policies') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="structures.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="structures.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('structures.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="structures.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('structures.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('structures.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('structures.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="structures.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('structures.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('structures.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('structures.item_three')} className={ classes.colPointer } align={'left'}><FormattedMessage id="structures.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('structures.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('structures.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('structures.item_four')} className={ classes.colPointer } align={'left'}><FormattedMessage id="structures.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('structures.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('structures.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('structures.item_one').sum + this.makeResult('structures.item_two').sum + this.makeResult('structures.item_three').sum + this.makeResult('structures.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('structures') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="technology.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="technology.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('technology.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="technology.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('technology.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('technology.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('technology.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="technology.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('technology.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('technology.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('technology.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer'  }} align={'left'}><FormattedMessage id="technology.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('technology.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('technology.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('technology.item_four')} className={ classes.colPointer } align={'left'}><FormattedMessage id="technology.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('technology.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('technology.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('technology.item_one').sum + this.makeResult('technology.item_two').sum + this.makeResult('technology.item_three').sum + this.makeResult('technology.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('technology') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="results.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="results.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('results.item_one')} className={ classes.colPointer } align={'left'}><FormattedMessage id="results.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('results.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('results.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo }align={'left'}>{ this.makeResult('results.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('results.item_two')} className={ classes.colPointer } align={'left'}><FormattedMessage id="results.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('results.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('results.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('results.item_three')} className={ classes.colPointer } align={'left'}><FormattedMessage id="results.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('results.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('results.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('results.item_four')} className={ classes.colPointer } align={'left'}><FormattedMessage id="results.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('results.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('results.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('results.item_one').sum + this.makeResult('results.item_two').sum + this.makeResult('results.item_three').sum + this.makeResult('results.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('results') }</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

Model.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Model);
/* eslint-enable no-unused-vars */