/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Filter1Icon from '@material-ui/icons/Filter1';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, FormattedTime } from 'react-intl';
import ReferenceOne from './ReferenceOne';
import ReferenceZero from './ReferenceZero';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 0,
  },
  colOne: {
    cursor: 'pointer'
  }
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function LinkTab(props) {
  return <Tab component="a" onClick={event => event.preventDefault()} {...props} />;
}

class Reference extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      areaData: {},
      value: 0,
      openDialog: false,
      title: '',
      url: ''
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
  } 

  componentDidMount() {

  }

  handleClickOpen(title, url) {
    this.setState({ openDialog: true, title: title, url: url });
  }

  handleClickClose() {
    this.setState({ openDialog: false, title: '', url: '' });
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <AppBar
          component="div"
          className={classes.secondaryBar}
          color="inherit"
          position="static"
          elevation={0}
          children={''}
        >
          <Tabs variant="fullWidth" value={value} textColor="inherit" onChange={this.handleTabChange}>
            <Tab icon={<Filter1Icon />} textColor="inherit" label="Nivel 1" />
            <Tab icon={<FilterNoneIcon />} textColor="inherit" label="Nivel 0" />
          </Tabs>
        </AppBar>
        <main className={classes.main}>

          {value === 0 && <TabContainer>
            <ReferenceOne />                           
          </TabContainer>}

          {value === 1 && <TabContainer>
            <ReferenceZero />
          </TabContainer>}
          
        </main>

      </React.Fragment>
    );
  }
}

Reference.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Reference);
/* eslint-enable no-unused-vars */