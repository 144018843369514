/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, FormattedTime } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 0,
  },
  colOne: {
    cursor: 'pointer'
  }
});

class ReferenceOne extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      areaData: {},
      value: 0,
      openDialog: false,
      title: '',
      url: ''
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
  } 

  componentDidMount() {

  }

  handleClickOpen(title, url) {
    this.setState({ openDialog: true, title: title, url: url });
  }

  handleClickClose() {
    this.setState({ openDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Dialog
          fullWidth={true}
          open={this.state.openDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handleClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>
        
        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Madurez GC regional</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>Conceptos clave</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Estrategia Regional</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sensibilización de policy makers sobre valor de la GC en la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_01_SensibPMakers.pdf')}>Sensibilización de policy makers sobre valor de la GC en la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sensibilización</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Comunicación y publicaciones divulgativas de I+D+i a stakeholders y sociedad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_02_ComPubStakeholders.pdf')}>Comunicación y publicaciones divulgativas de I+D+i a stakeholders y sociedad</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Comunicación y Publicaciones</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Scoreboard GC e innovación (impactos)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_03_Score_GC_Innov.pdf')}>Scoreboard GC e innovación (impactos)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Scoreboard</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Innovación en estrategia regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_04_Innov_Est_Reg_I%2BD%2Bi.pdf')}>Innovación en estrategia regional de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Innovación estratégica</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Conectar</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Benchmarking sobre estrategias de I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_05_Bench_%20Estrat_I%2BD%2Bi_Reg.pdf')}>Benchmarking sobre estrategias de I+D+i regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Benchmarking</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Benchmarking sobre observatorios regionales de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_06_Bench_Observat_Reg_I%2BD%2Bi.pdf')}>Benchmarking sobre observatorios regionales de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Benchmarking</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sistema de seguimiento y control del I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_07_Sist_Seg_Contr_I%2BD%2Bi.pdf')}>Sistema de seguimiento y control del I+D+i regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Seguimiento y control</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis de rendimientos del I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_08_An%C3%A1lisis_Rtos_I%2BD%2Bi.pdf')}>Análisis de rendimientos del I+D+i regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Seguimiento y control</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Innovación en seguimiento, comunicación y productos de conocimiento sobre I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_09_Innov_Seguim_Com_Prod_I%2BD%2Bi.pdf')}>Innovación en seguimiento, comunicación y productos de conocimiento sobre I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Innovación en monitorización</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Ordenar</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sistema centralizado de información sobre I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_10_Sist_Info_Centra_I%2BD%2Bi.pdf')}>Sistema centralizado de información sobre I+D+i regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sistema de información</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Promoción de la I+D+i en el sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_11_Prom_I%2BD%2Bi_SPubreg.pdf')}>Promoción de la I+D+i en el sector público regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Promoción</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Promoción de la I+D+i en el sector privado regional (ya hecha en MCIREG17)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_12_Promoc_I%2BD%2BiSPriv.pdf')}>Promoción de la I+D+i en el sector privado regional (ya hecha en MCIREG17)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Promoción</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={3} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de sitios web regionales de referencia en I+D+i (y sinergias)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_13_Mapa_S_Web.pdf')}>Mapa de sitios web regionales de referencia en I+D+i (y sinergias)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sistema de información</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Casos de éxito de la innovación en el sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_14_%20Casos_exito_SPub.pdf')}>Casos de éxito de la innovación en el sector público regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Referencias y casos de éxito</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Casos de éxito de la innovación en el sector privado regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_15_Casos_Exito_Innov_SecPrivReg.pdf')}>Casos de éxito de la innovación en el sector privado regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Referencias y casos de éxito</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={3} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Estrategia de comportamiento innovador para las organizaciones del sector público regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_16_Estrat_Comport_Innov_Orgs_SPub.pdf')}>Estrategia de comportamiento innovador para las organizaciones del sector público regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Comportamiento innovador</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Estrategia de comportamiento innovador para las empresas del sector privado regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_17_Estrat_Comport_Innov_SPrivReg.pdf')}>Estrategia de comportamiento innovador para las empresas del sector privado regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Comportamiento innovador</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Certificaciones y premios relativos al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_18_Certif_Premios_I%2BD%2Bi.pdf')}>Certificaciones y premios relativos al I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Reconocimientos</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Socializar</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Fomento del desarrollo clusters con vocación de I+D+i, agrupaciones empresariales, mesas de trabajo, fundaciones', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_19_Fom_Desar_Cluster_etc_I%2BD%2Bi.pdf')}>Fomento del desarrollo clusters con vocación de I+D+i, agrupaciones empresariales, mesas de trabajo, fundaciones</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Estructuras de apoyo</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Programas de modernización y competitividad de las organizaciones regionales', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_20_Prog_Moderniz_Competi.pdf')}>Programas de modernización y competitividad de las organizaciones regionales</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Modernización y competitividad</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Posicionamiento internacional de estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones) con vocación de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_21_Posic_Internac_Clusters_etc.pdf')}>Posicionamiento internacional de estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones) con vocación de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Promoción</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Scoreboard vinculado con la labor de las estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_22_Score_Estruc_Reg_I%2BD%2Bi.pdf')}>Scoreboard vinculado con la labor de las estructuras regionales existentes (clusters, agrupaciones empresariales, mesas de trabajo, fundaciones)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Scoreboard</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Desarrollar</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Nuevas titulaciones disponibles en la región (grados y postgrados incluyendo un perfil de mayor I+D+i)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_23_Nuevas_Titulac_PerfilI%2BD%2Bi.pdf')}>Nuevas titulaciones disponibles en la región (grados y postgrados incluyendo un perfil de mayor I+D+i)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Formación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Promoción del emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_24_Promoc_Esp_Emprend_Innov.pdf')}>Promoción del emprendimiento innovador</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Emprendimiento </TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={3} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Titulaciones con núcleo emprendedor innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_25_Titulac_Perfil_EmprenInnov.pdf')}>Titulaciones con núcleo emprendedor innovador</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Formación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mercado para el emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_26_Mercado_Empren_Innov.pdf')}>Mercado para el emprendimiento innovador</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Emprendimiento</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Mapa de apoyo del sector público a la I+D+i (incluyendo emprendimiento)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_27_Mapa_SP%C3%BAblico.pdf')}>Mapa de apoyo del sector público a la I+D+i (incluyendo emprendimiento)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sistema de I+D+i</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Modelo de aceleración de emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_28_Mod.Aceler_EmprInnov.pdf')}>Modelo de aceleración de emprendimiento innovador</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Emprendimiento </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Estrategia de identificación, retención y atracción de talento emprendedor (atracción)', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_29_Estrat_Ident_Reten_Atracc_Talento_Emprend.pdf')}>Estrategia de identificación, retención y atracción de talento emprendedor (atracción)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Talento</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Lanzadera de proyectos emprendedores innovadores', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_30_Lanzadera_Proy_Empren_Innov.pdf')}>Lanzadera de proyectos emprendedores innovadores</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Emprendimiento</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Scoreboard sobre emprendimiento innovador', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_31_Score_Emp_Innov.pdf')}>Scoreboard sobre emprendimiento innovador</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Scoreboard</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Políticas</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sistema de selección de directrices estratégicas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_32_Sist_Selecc_Direc_Estra_I%2BD%2Bi.pdf')}>Sistema de selección de directrices estratégicas de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Estrategia</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Diseño de esquemas de cofinanciación del I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_33_Dise%C3%B1o_Esq_Cofinan_I%2BD%2Bi.pdf')}>Diseño de esquemas de cofinanciación del I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Financiación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Diseño de esquemas de créditos reembolsables para I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_34_Dise%C3%B1o_Esq_Cred_Reemb.pdf')}>Diseño de esquemas de créditos reembolsables para I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Financiación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sensibilidad de indicios de patentabilidad', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_35_Sensib_Indicios_Patent.pdf')}>Sensibilidad de indicios de patentabilidad</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Protección de resultados</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={3} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sistema de asignación y control de ayudas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_36_Sist_Asig_Ayu_I%2BD%2Bi.pdf')}>Sistema de asignación y control de ayudas de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Financiación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Lanzamiento de ayudas de I+D+i en régimen de confinanciacion', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_37_Lanz_Ayudas_I%2BD%2Bi_Cofinan.pdf')}>Lanzamiento de ayudas de I+D+i en régimen de confinanciacion</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Financiación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Lanzamiento de ayudas de I+D+i en régimen de créditos reembolsables', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_38_Lanz_Ayud_I%2BD%2Bi_CredReem.pdf')}>Lanzamiento de ayudas de I+D+i en régimen de créditos reembolsables</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Financiación</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={5} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sistema de control de impactos derivados de las ayudas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_39_Sist_Contr_Impac_Ayu_I%2BD%2Bi.pdf')}>Sistema de control de impactos derivados de las ayudas de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Financiación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Diseño de esquemas de alta cofinanciación empresarial para el sector público en el ámbito del I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_40_Dise%C3%B1o_Lanz_Alta_Cofinan.pdf')}>Diseño de esquemas de alta cofinanciación empresarial para el sector público en el ámbito del I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Financiación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Control de riesgos y morosidad para créditos reembolsables de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_41_Control_Riesgos_Morosidad_CredReem.pdf')}>Control de riesgos y morosidad para créditos reembolsables de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Financiación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Promoción de casos de éxito de protección de resultados I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_42_Prom_Casos_Exito_Protecc_Rdos.pdf')}>Promoción de casos de éxito de protección de resultados I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Protección de resultados</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Oferta servicios de protección de resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_43_Ofer_Serv_Protecc_Rdos.pdf')}>Oferta servicios de protección de resultados de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Protección de resultados</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Estructuras</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Selección, orientación y evolución de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_44_Selecc_Orientac_Evol_Estruc_Apoyo.pdf')}>Selección, orientación y evolución de estructuras de apoyo al I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Agentes</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sensibilización de portafolio de servicios para las estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_45_Sensib_Serv_EstrucApoyo_I%2BD%2Bi.pdf')}>Sensibilización de portafolio de servicios para las estructuras de apoyo al I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Agentes</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Programa apoyo transferencia resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_46_Prog_Apoyo_Transf_Rdos.pdf')}>Programa apoyo transferencia resultados de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Transferencia</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Espacio de coordinación de estructuras de apoyo al I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_47_Espac_Coord_Estruc_Apoyo.pdf')}>Espacio de coordinación de estructuras de apoyo al I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Coordinación</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Programa de comercialización de resultados de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_48_Prog_Comerc_Rdos.pdf')}>Programa de comercialización de resultados de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Comercialización</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={1} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sistema de seguimiento e integración de resultados derivados de las estructuras', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_49_Sist_Seg_Integ_Estruc_I%2BD%2Bi.pdf')}>Sistema de seguimiento e integración de resultados derivados de las estructuras</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Coordinación</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Tecnología</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sensibilización sobre virtualización de servicios de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_50_Sensib_Virtualiz_Serv_Apoyo_I%2BD%2Bi.pdf')}>Sensibilización sobre virtualización de servicios de apoyo a la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Servicios</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sensibilización sobre el uso de bases de datos y publicaciones de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_51_Sensib_Uso_BBDDyPub.pdf')}>Sensibilización sobre el uso de bases de datos y publicaciones de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sistemas de información</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Promoción del emparejamiento de oferta y demanda sobre I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_52_Prom_Emparej_Ofer_Demand.pdf')}>Promoción del emparejamiento de oferta y demanda sobre I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Transferencia</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sensibilización sobre espacios de crowdsourcing', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_53_Sensib_Espac_Crowdsourc.pdf')}>Sensibilización sobre espacios de crowdsourcing</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Crowdsourcing</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={5} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Portal web de servicios virtuales de apoyo a la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_54_Portal_web_servicios_I%2BD%2Bi.pdf')}>Portal web de servicios virtuales de apoyo a la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Servicios</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Portal de servicios de acceso a bases de datos y publicaciones relacionadas con el I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_55_Portal_Serv_BBDD_I%2BD%2Bi.pdf')}>Portal de servicios de acceso a bases de datos y publicaciones relacionadas con el I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Servicios</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Portal maketplace de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_56_Portal_Marketplace_I%2BD%2Bi.pdf')}>Portal maketplace de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Servicios</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Incentivos y referencias para promover un marketplace de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_57_Incentiv_Refer_Marketplace.pdf')}>Incentivos y referencias para promover un marketplace de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Incentivos</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Portal de crowdsourcing', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_58_Portal_Crowdsourcing_I%2BD%2Bi.pdf')}>Portal de crowdsourcing</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Servicios</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sistema de reporting de I+D+i para policy makers', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_59_Sist_Report_I%2BD%2Bi_PMakers.pdf')}>Sistema de reporting de I+D+i para policy makers</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Reporting</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Biblioteca de bases de datos y publicaciones top', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_60_Bibliot_BBDD_Pub.pdf')}>Biblioteca de bases de datos y publicaciones top</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sistemas de información</TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>Resultados</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis sobre I+D+i territorial', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_61_Analisis_I%2BD%2Bi_Territorial.pdf')}>Análisis sobre I+D+i territorial</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Análisis</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Metas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_62_Metas_I%2BD%2Bi.pdf')}>Metas de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Estrategia</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sensibilización sobre la transparencia en las ayudas e inversiones relacionadas con la I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_63_Sensib_Transparencia_Ayudas.pdf')}>Sensibilización sobre la transparencia en las ayudas e inversiones relacionadas con la I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Transparencia</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sensibilización sobre criterios para el prestigio regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_64_Sensib_Criterios_Prestig_Reg_I%2BD%2Bi.pdf')}>Sensibilización sobre criterios para el prestigio regional de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Imagen</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Colección de publicaciones regionales de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_65_Colecc_Pub_Reg_I%2BD%2Bi.pdf')}>Colección de publicaciones regionales de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Publicaciones</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Sistema de control y seguimiento de metas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_66_Sist_Cont_Seg_Metas_I%2BD%2Bi.pdf')}>Sistema de control y seguimiento de metas de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Control y seguimiento</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Portal de transparencia sobre ayudas e inversiones de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_67_Portal_Transparencia_Ayudas.pdf')}>Portal de transparencia sobre ayudas e inversiones de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Transparencia</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Branding regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_68_Branding_Reg_I%2BD%2Bi.pdf')}>Branding regional de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Imagen</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Personalización de análisis para diferentes colectivos', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_69_Personaliz_Analisis_Colectivos.pdf')}>Personalización de análisis para diferentes colectivos</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Análisis</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Factores clave de éxito para la consecución de las metas de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_70_FCE_Consec_Metas.pdf')}>Factores clave de éxito para la consecución de las metas de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Estrategia</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Índice de transparencia de I+D+i regional', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_71_Indice_Transpar_I%2BD%2Bi.pdf')}>Índice de transparencia de I+D+i regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Transparencia</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Capitalización de logros en branding regional de I+D+i', 'https://kitmake.ica2.com/resources/m/ficha_MADGC_72_Capitaliz_Logros_Branding_Reg_I%2BD%2Bi.pdf')}>Capitalización de logros en branding regional de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Imagen</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

ReferenceOne.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReferenceOne);
/* eslint-enable no-unused-vars */