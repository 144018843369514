/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, FormattedTime } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 0,
  },
  colOne: {
    cursor: 'pointer'
  }
});

class ReferenceZero extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      areaData: {},
      value: 0,
      openDialog: false,
      title: '',
      url: ''
    }

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);

  } 

  componentDidMount() {

  }

  handleClickOpen(title, url) {
    this.setState({ openDialog: true, title: title, url: url });
  }

  handleClickClose() {
    this.setState({ openDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Dialog
          fullWidth={true}
          open={this.state.openDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handleClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>CHR</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>Conceptos clave</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Estrategia de especialización regional I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_01_Estrat_Especializaci%C3%B3n.pdf')}>Estrategia de especialización regional I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Especialización</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Incorporación de la innovación social en la estrategia de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_02_Innov_Social_EstratI%2BD%2Bi.pdf')}>Incorporación de la innovación social en la estrategia de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Innovación social</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Configuración del sistema I+D+i (Mapa del sistema)', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_03_Config_Sist_I%2BD%2Bi.pdf')}>Configuración del sistema I+D+i (Mapa del sistema)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sistema I+D+i</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Análisis del sistema I+D+i (visión sistémica)', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_04_Analisis_Sist_I%2BD%2Bi.pdf')}>Análisis del sistema I+D+i (visión sistémica)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sistema I+D+i</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Configuración de nodo temático de I+D+i internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_05_Config_Nodo_Internac_I%2BD%2Bi.pdf')}>Configuración de nodo temático de I+D+i internacional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Posicionamiento internacional</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Estrategia de atracción y retención de talento', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_06_Estrat_Ident_Reten_Atracc_Talento.pdf')}>Estrategia de atracción y retención de talento</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Talento</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>CER</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>Conceptos clave</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Dotación de recursos y capacidades de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_07_Dotac_RRs_Capacs_I%2BD%2Bi.pdf')}>Dotación de recursos y capacidades de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Sistema I+D+i</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Configuración de sinergias intersectoriales de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_08_Config_Sinerg_Intersector_I%2BD%2Bi.pdf')}>Configuración de sinergias intersectoriales de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Cadenas de valor</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Configuración de cadenas de valor en el I+D+i regional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_09_Config_Cadenas_Valor_I%2BD%2BiReg.pdf')}>Configuración de cadenas de valor en el I+D+i regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Cadenas de valor</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Identificación de referencias y casos de éxito de I+D+i regional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_10_Identif_Refer_CExito_I%2BD%2BiReg.pdf')}>Identificación de referencias y casos de éxito de I+D+i regional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Referencias y casos de éxito</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Posicionamiento internacional en temáticas de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_11_Posic_Inter_tem%C3%A1ticas_I%2BD%2Bi.pdf')}>Posicionamiento internacional en temáticas de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Posicionamiento internacional</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Tangibilización de la I+D+i (productos, impactos, logros)', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_12_Tangibiliz_I%2BD%2Bi.pdf')}>Tangibilización de la I+D+i (productos, impactos, logros)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Resultados de I+D+i</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}>CRR</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>Conceptos clave</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Básico</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Benchmarking, estancias, pasantías casos de referencia I+D+i internacionales', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_13_Bench_Estancias_Org_Ref_Inter_I%2BD%2Bi.pdf')}>Benchmarking, estancias, pasantías casos de referencia I+D+i internacionales</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Benchmarking, estancias</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Apertura del I+D+i regional (educación, investigación, inversión)', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_14_Apert_I%2BD%2BiReg.pdf')}>Apertura del I+D+i regional (educación, investigación, inversión)</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Networking internacional</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Intermedio</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Identificación de "burbujas de I+D+i" con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_15_Identif_Burbujas_I%2BD%2Bi_PerfInternac.pdf')}>Identificación de "burbujas de I+D+i" con perfil internacional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Referencias y casos de éxito</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Promoción de casos de éxito I+D+i con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_16_Promoc_Casos_Exito_I%2BD%2Bi_PerfInternac.pdf')}>Promoción de casos de éxito I+D+i con perfil internacional</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Referencias y casos de éxito</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>Avanzado</TableCell>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Promoción de I+D+i privado', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_17_Promoc_I%2BD%2BiPriv.pdf')}>Promoción de I+D+i privado</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Promoción I+D+i</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={ classes.colOne } align={'left'} onClick={event => this.handleClickOpen('Posicionamiento internacional de liderazgo de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_18_Posic_Inter_Liderazgo_I%2BD%2Bi.pdf')}>Posicionamiento internacional de liderazgo de I+D+i</TableCell>
                <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>Posicionamiento internacional</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

ReferenceZero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReferenceZero);
/* eslint-enable no-unused-vars */