import auth from '../../../Auth';

class Activity {
  constructor() {
    this.getList = this.getList.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(area_id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'areas/' + area_id + '/activities', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  async create(title, description, area_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'activities', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          activity: { title: title, description: description, area_id: area_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async update(id, title, description, area_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'activities', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, activity: { title: title, description: description, area_id: area_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  show(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'activities/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  delete(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'activities', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
        id: id
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

}

export default new Activity();