/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import agent from './Agent'; 

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
  colOne: {
    border: '1px solid #cfd8dc', color: '#37474f', fontStyle: 'bold', fontSize: '17px', textAlign: 'left', paddingLeft: 55
  },
});

class Support extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      currentTopic: '',
      support: [],
      currentRating: 0,
      openPDFDialog: false,
      title: '',
      url: '',
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handlePDFClickOpen = this.handlePDFClickOpen.bind(this);
    this.handlePDFClickClose = this.handlePDFClickClose.bind(this);
  } 

  componentDidMount() {
    agent.getSupport(this.props.agentId).then((result) =>
      this.setState({ support: result })
    );
  } 

  handleClickOpen(currentTopic) {
    let rating = 0;
    agent.getSupport(this.props.agentId).then((result) => {
      result.map((row) => {
        if (row.topic === currentTopic) {
          rating = row.supportvalue.toString();
        }
        return true;
      });
      this.setState({ openDialog: true, currentTopic: currentTopic, currentRating: rating });
    });
    
  }

  handleClickClose() {
    this.setState({ openDialog: false, currentTopic: '', currentRating: 0 });
    agent.getSupport(this.props.agentId).then((result) => {
      this.setState({ support: result });
      this.forceUpdate();
    });
  }

  handleChange = (event) => {
    this.setState({ currentRating: event.target.value });
  }

  handleSave(e) {
    e.preventDefault();
    agent.saveSupport(this.props.agentId, this.state.currentTopic, this.state.currentRating).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="CHR.updated" />,
        openSnack: true
      });
      this.handleClickClose();
    })
  }

  makeItemValue(item) {

    var value = 0;

    this.state.support.map((row) => {
      if (row.topic === item) {
        value = row.supportvalue;
      }
      return true
    });

    return value;

  }

  makeRatingResult(item) {

    var stat = { sum: 0, recommendation: '' }

    this.state.support.map((row) => {
      if (row.topic.includes(item)) {
        stat.sum += row.supportvalue;
      }
      return true
    });

    if (item === 'agent_strategy') {

      if (stat.sum >= 0 && stat.sum <= 5) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP001', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP001.pdf')}>NIVAP001</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP004', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP004.pdf')}>NIVAP004</Link>           
        }} />;  
      } else if (stat.sum > 5 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP002', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP002.pdf')}>NIVAP002</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP003', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP003.pdf')}>NIVAP003</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP005', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP005.pdf')}>NIVAP005</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP006', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP006.pdf')}>NIVAP006</Link>
        }} />;  
      }

    } else if (item === 'agent_connect') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP007', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP007.pdf')}>NIVAP007</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP010', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP010.pdf')}>NIVAP010</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP013', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP013.pdf')}>NIVAP013</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP016', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP016.pdf')}>NIVAP016</Link>
        }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP008', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP008.pdf')}>NIVAP008</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP011', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP011.pdf')}>NIVAP011</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP014', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP014.pdf')}>NIVAP014</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP017', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP017.pdf')}>NIVAP017</Link>
        }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP009', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP009.pdf')}>NIVAP009</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP012', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP012.pdf')}>NIVAP012</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP015', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP015.pdf')}>NIVAP015</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP018', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP018.pdf')}>NIVAP018</Link>
        }} />;
      }

    } else if (item === 'agent_sort') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP019', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP019.pdf')}>NIVAP019</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP022', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP022.pdf')}>NIVAP022</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP025', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP025.pdf')}>NIVAP025</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP028', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP028.pdf')}>NIVAP028</Link>
        }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP020', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP020.pdf')}>NIVAP020</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP023', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP023.pdf')}>NIVAP023</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP026', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP026.pdf')}>NIVAP026</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP029', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP029.pdf')}>NIVAP029</Link>
        }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP021', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP021.pdf')}>NIVAP021</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP024', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP024.pdf')}>NIVAP024</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP027', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP027.pdf')}>NIVAP027</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP030', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP030.pdf')}>NIVAP030</Link>
        }} />;      
      }

    } else if (item === 'agent_socialize') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP031', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP031.pdf')}>NIVAP031</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP034', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP034.pdf')}>NIVAP034</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP037', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP037.pdf')}>NIVAP037</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP040', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP040.pdf')}>NIVAP040</Link>
        }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP032', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP032.pdf')}>NIVAP032</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP035', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP035.pdf')}>NIVAP035</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP038', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP038.pdf')}>NIVAP038</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP041', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP041.pdf')}>NIVAP041</Link>
        }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP033', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP033.pdf')}>NIVAP033</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP036', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP036.pdf')}>NIVAP036</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP039', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP039.pdf')}>NIVAP039</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP042', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP042.pdf')}>NIVAP042</Link>
        }} />;      
      }

    } else if (item === 'agent_develop') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP043', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP043.pdf')}>NIVAP043</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP046', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP046.pdf')}>NIVAP046</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP049', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP049.pdf')}>NIVAP049</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP052', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP052.pdf')}>NIVAP052</Link>
        }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP044', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP044.pdf')}>NIVAP044</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP047', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP047.pdf')}>NIVAP047</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP050', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP050.pdf')}>NIVAP050</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP053', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP053.pdf')}>NIVAP053</Link>
        }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP045', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP045.pdf')}>NIVAP045</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP048', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP048.pdf')}>NIVAP048</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP051', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP051.pdf')}>NIVAP051</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP054', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP054.pdf')}>NIVAP054</Link>
        }} />;      
      }

    } else if (item === 'agent_policies') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP055', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP055.pdf')}>NIVAP055</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP058', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP058.pdf')}>NIVAP058</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP061', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP061.pdf')}>NIVAP061</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP064', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP064.pdf')}>NIVAP064</Link>
        }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP056', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP056.pdf')}>NIVAP056</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP059', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP059.pdf')}>NIVAP059</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP062', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP062.pdf')}>NIVAP062</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP065', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP065.pdf')}>NIVAP065</Link>
        }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP057', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP057.pdf')}>NIVAP057</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP060', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP060.pdf')}>NIVAP060</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP063', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP063.pdf')}>NIVAP063</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP066', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP066.pdf')}>NIVAP066</Link>
        }} />;      
      }

    } else if (item === 'agent_structures') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP067', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP067.pdf')}>NIVAP067</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP070', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP070.pdf')}>NIVAP070</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP073', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP073.pdf')}>NIVAP073</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP076', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP076.pdf')}>NIVAP076</Link>
        }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP068', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP068.pdf')}>NIVAP068</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP071', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP071.pdf')}>NIVAP071</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP074', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP074.pdf')}>NIVAP074</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP077', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP077.pdf')}>NIVAP077</Link>
        }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP069', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP069.pdf')}>NIVAP069</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP072', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP072.pdf')}>NIVAP072</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP075', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP075.pdf')}>NIVAP075</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP078', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP078.pdf')}>NIVAP078</Link>
        }} />;      
      }

    } else if (item === 'agent_technology') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP079', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP079.pdf')}>NIVAP079</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP082', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP082.pdf')}>NIVAP082</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP085', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP085.pdf')}>NIVAP085</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP088', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP088.pdf')}>NIVAP088</Link>
        }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP080', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP080.pdf')}>NIVAP080</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP083', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP083.pdf')}>NIVAP083</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP086', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP086.pdf')}>NIVAP086</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP089', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP089.pdf')}>NIVAP089</Link>
        }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP081', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP081.pdf')}>NIVAP081</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP084', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP084.pdf')}>NIVAP084</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP087', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP087.pdf')}>NIVAP087</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP090', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP090.pdf')}>NIVAP090</Link>
        }} />;      
      }

    } else if (item === 'agent_results') {

      if (stat.sum >= 0 && stat.sum <= 10) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_one_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP091', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP091.pdf')}>NIVAP091</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP094', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP094.pdf')}>NIVAP094</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP097', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP097.pdf')}>NIVAP097</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP0101', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP0101.pdf')}>NIVAP0101</Link>
        }} />;
      } else if (stat.sum > 10 && stat.sum <= 15) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_two_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP092', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP092.pdf')}>NIVAP092</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP095', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP095.pdf')}>NIVAP095</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP098', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP098.pdf')}>NIVAP098</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP0101', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP0101.pdf')}>NIVAP0101</Link>
        }} />;
      } else if (stat.sum > 15 && stat.sum <= 20) {
        stat.recommendation = <FormattedMessage id={item + '.interpretation_three_lz'}
        values={{
          sheetOne: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP093', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP093.pdf')}>NIVAP093</Link>,
          sheetTwo: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP096', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP096.pdf')}>NIVAP096</Link>,
          sheetThree: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP099', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP099.pdf')}>NIVAP099</Link>,
          sheetFour: <Link style={{ cursor: 'pointer' }}
                      onClick={event => this.handlePDFClickOpen('NIVAP0102', 'https://kitmake.ica2.com/resources/zero/ea/ficha_NIVAP0102.pdf')}>NIVAP0102</Link>
        }} />;      
      }

    }

    return stat;    
  }

  handlePDFClickOpen(title, url) {
    this.setState({ openPDFDialog: true, title: title, url: url });
  }

  handlePDFClickClose() {
    this.setState({ openPDFDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          { 'Nivel de Apoyo a la Gestión de Conocimiento' }
        </Typography>

        <Dialog
          fullWidth={true}
          open={this.state.openPDFDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handlePDFClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Valoración</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_title' } /> : null }</b>
              <br />{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_desc' } /> : null }
            </DialogContentText>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><b>{ 'Valoración'}</b></TableCell>
                  <TableCell style={ { width: '30%' } }>
                  <RadioGroup row>
                    <Radio checked={this.state.currentRating === '1'} value={1} onChange={this.handleChange} color="primary" />
                    <Radio checked={this.state.currentRating === '2'} value={2} onChange={this.handleChange} color="primary" />
                    <Radio checked={this.state.currentRating === '3'} value={3} onChange={this.handleChange} color="primary" />
                    <Radio checked={this.state.currentRating === '4'} value={4} onChange={this.handleChange} color="primary" />
                    <Radio checked={this.state.currentRating === '5'} value={5} onChange={this.handleChange} color="primary" />
                  </RadioGroup>
                  </TableCell>
                </TableRow>                                                
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              disabled={ this.state.currentRating === 0 }
              onClick={this.handleSave}
              color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Valoración' }</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Interpretación' }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '25%' }} align={'left'}><FormattedMessage id="agent_strategy.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_strategy.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_strategy.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_strategy.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_strategy.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_strategy.item_one') }</TableCell>
                <TableCell rowSpan={2} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_strategy').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_strategy.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_strategy.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_strategy.item_two')}  style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_strategy.item_two') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_connect.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_connect.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_connect.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_connect.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_connect').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_connect.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_connect.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_connect.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_connect.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_connect.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_connect.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_connect.item_four') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_sort.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_sort.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_sort.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_sort.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_sort').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_sort.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_sort.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_sort.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_sort.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_sort.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_sort.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_sort.item_four') }</TableCell>
              </TableRow>  

              <TableRow>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_socialize.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_socialize.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_socialize.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_socialize.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_socialize').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_socialize.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_socialize.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_socialize.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_socialize.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_socialize.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_socialize.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_socialize.item_four') }</TableCell>
              </TableRow>  

              <TableRow>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_develop.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_develop.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_develop.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_develop.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_develop').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_develop.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_develop.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_develop.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_develop.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_develop.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_develop.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_develop.item_four') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_policies.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_policies.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_policies.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_policies.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_policies').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_policies.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_policies.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_policies.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_policies.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_policies.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_policies.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_policies.item_four') }</TableCell>
              </TableRow> 

              <TableRow>
                <TableCell style={{ backgroundColor: '#ffeb3b', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_structures.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ffeb3b', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_structures.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_structures.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_structures.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_structures').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_structures.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_structures.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_structures.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_structures.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_structures.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_structures.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_structures.item_four') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_technology.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_technology.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_technology.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_technology.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_technology').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_technology.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_technology.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_technology.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_technology.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_technology.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_technology.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_technology.item_four') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_results.title" /></TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="agent_results.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_results.item_one_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_results.item_one') }</TableCell>
                <TableCell rowSpan={4} style={{ border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top', backgroundColor: '#FFFFFF' }} align={'left'}>{ this.makeRatingResult('agent_results').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_results.item_two_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_results.item_two') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_results.item_three_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_results.item_three') }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="agent_results.item_four_title" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('agent_results.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('agent_results.item_four') }</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

Support.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Support);
/* eslint-enable no-unused-vars */