import React from 'react';
import { MakeContext } from '../MakeProvider';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AreaDash from './AreaDash';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: 20
  },
  orgCard: {
    minWidth: '50%',
  },
  organizationCard: {
    //borderBottom: '1px solid red'
  },
  card: {
    minWidth: 250,
    maxWidth: 270,
    minHeight: 220,
    maxHeight: 270,
    backgroundColor: '#e1f5fe'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dialogStyle: {
    borderRadius: 0
  },
  nameLink: {
    cursor: 'pointer',
  }
});

class Dashboard extends React.Component{

  static contextType = MakeContext;

  componentDidMount() {

  } 

  render() {

    const { classes } = this.props;

    return (
      <div>
        {/* <Header onDrawerToggle={this.handleDrawerToggle} history={this.props.history} /> */}
        <main className={classes.main}>
          <AreaDash history={this.props.history} />
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
