/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import area from '../../areas/Area'; 

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
  colOne: {
    border: '1px solid #cfd8dc', color: '#37474f', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0
  },
  colTwo: {
    border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top'
  }
});

class ModelZero extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      currentTopic: '',
      currentRating: { relevance: 0, specificity: 0, control: 0, impacts: 0 },
      maturity: [],
      openPDFDialog: false,
      title: '',
      url: '',
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setUp = this.setUp.bind(this);
    this.handlePDFClickOpen = this.handlePDFClickOpen.bind(this);
    this.handlePDFClickClose = this.handlePDFClickClose.bind(this);
  } 

  componentDidMount() {
    this.setUp();
  } 

  componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.areaId !== prevProps.areaId) {
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    area.getMaturity(this.props.areaId).then((result) =>
      this.setState({ maturity: result })
    );
  }

  handleClickOpen(currentTopic) {
    let rating = { relevance: 0, specificity: 0, control: 0, impacts: 0 };
    area.getMaturity(this.props.areaId).then((result) => {
      result.map((row) => {
        if (row.topic === currentTopic) {
          rating = { relevance: row.relevance.toString(), specificity: row.specificity.toString(), control: row.control.toString(), impacts: row.impacts.toString() }
        }
        return true;
      });
      this.setState({ openDialog: true, currentTopic: currentTopic, currentRating: rating });
    });
    
  }

  handleClickClose() {
    this.setState({ openDialog: false, currentTopic: '', currentRating: { relevance: 0, specificity: 0, control: 0, impacts: 0 } });
    area.getMaturity(this.props.areaId).then((result) => {
      this.setState({ maturity: result });
      this.forceUpdate();
    });
  }

  handleChange = (criteria) => (event) => {
    switch (criteria) {
      case 'relevance':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, relevance: event.target.value } });
        break;
      case 'specificity':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, specificity: event.target.value } });
        break;
      case 'control':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, control: event.target.value } });
        break;
      case 'impacts':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, impacts: event.target.value } });
        break;
      default:
        break;
    }
  }

  handleSave(e) {
    e.preventDefault();
    area.saveMaturity(this.props.areaId, this.state.currentTopic, this.state.currentRating).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="maturity.updated" />,
        openSnack: true
      });
      this.handleClickClose();
    })
  }

  makeResult(item) {

    var result = { sum: 0, interpretation: '', recommendation: '' };

    this.state.maturity.map((row) => {

      if (row.topic === item) {

        result.sum = row.relevance + row.specificity + row.control + row.impacts;

        if (row.topic.split('.')[0] === 'strategy') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'strategy.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC001', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC001.pdf')}>MADGC001</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC001', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC001.pdf')}>MADGC001</Link>
              }} />;

            } else if (item === 'strategy.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC004', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC004.pdf')}>MADGC004</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC004', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC004.pdf')}>MADGC004</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'strategy.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC002', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC002.pdf')}>MADGC002</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC002', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC002.pdf')}>MADGC002</Link>
              }} />;

            } else if (item === 'strategy.item_two') {
              
              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC005', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC005.pdf')}>MADGC005</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC005', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC005.pdf')}>MADGC005</Link>
              }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {

            if (item === 'strategy.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC003', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC003.pdf')}>MADGC003</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC003', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC003.pdf')}>MADGC003</Link>
              }} />;

            } else if (item === 'strategy.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC006', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC006.pdf')}>MADGC006</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC006', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC006.pdf')}>MADGC006</Link>
              }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'connect') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'connect.item_one') {
            
              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC007', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC007.pdf')}>MADGC007</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC007', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC007.pdf')}>MADGC007</Link>
              }} />;
                                    
            } else if (item === 'connect.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC010', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC010.pdf')}>MADGC010</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC010', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC010.pdf')}>MADGC010</Link>
              }} />;

            } else if (item === 'connect.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC013', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC013.pdf')}>MADGC013</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC013', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC013.pdf')}>MADGC013</Link>
              }} />;

            } else if (item === 'connect.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC016', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC016.pdf')}>MADGC016</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC016', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC016.pdf')}>MADGC016</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'connect.item_one') {
            
              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC008', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC008.pdf')}>MADGC008</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC008', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC008.pdf')}>MADGC008</Link>
              }} />;
                                    
            } else if (item === 'connect.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC011', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC011.pdf')}>MADGC011</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC011', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC011.pdf')}>MADGC011</Link>
              }} />;

            } else if (item === 'connect.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC014', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC014.pdf')}>MADGC014</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC014', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC014.pdf')}>MADGC014</Link>
              }} />;

            } else if (item === 'connect.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC017', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC017.pdf')}>MADGC017</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC017', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC017.pdf')}>MADGC017</Link>
              }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            if (item === 'connect.item_one') {
            
              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC009', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC009.pdf')}>MADGC009</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC009', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC009.pdf')}>MADGC009</Link>
              }} />;
                                    
            } else if (item === 'connect.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC012', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC012.pdf')}>MADGC012</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC012', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC012.pdf')}>MADGC012</Link>
              }} />;

            } else if (item === 'connect.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC015', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC015.pdf')}>MADGC015</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC015', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC015.pdf')}>MADGC015</Link>
              }} />;

            } else if (item === 'connect.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC018', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC018.pdf')}>MADGC018</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC018', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC018.pdf')}>MADGC018</Link>
              }} />;

            }
          
          }

        } else if (row.topic.split('.')[0] === 'sort') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'sort.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC019', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC019.pdf')}>MADGC019</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC019', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC019.pdf')}>MADGC019</Link>
              }} />;

            } else if (item === 'sort.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC022', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC022.pdf')}>MADGC022</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC022', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC022.pdf')}>MADGC022</Link>
              }} />;

            } else if (item === 'sort.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC025', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC025.pdf')}>MADGC025</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC025', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC025.pdf')}>MADGC025</Link>
              }} />;

            } else if (item === 'sort.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC028', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC028.pdf')}>MADGC028</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC028', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC028.pdf')}>MADGC028</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

              if (item === 'sort.item_one') {

                result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC020', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC020.pdf')}>MADGC020</Link>
                }} />;
  
                result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC020', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC020.pdf')}>MADGC020</Link>
                }} />;

  
              } else if (item === 'sort.item_two') {

                result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC023', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC023.pdf')}>MADGC023</Link>
                }} />;
  
                result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC023', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC023.pdf')}>MADGC023</Link>
                }} />;

  
              } else if (item === 'sort.item_three') {
  
                result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC026', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC026.pdf')}>MADGC026</Link>
                }} />;
  
                result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC026', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC026.pdf')}>MADGC026</Link>
                }} />;
                
              } else if (item === 'sort.item_four') {

                result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC029', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC029.pdf')}>MADGC029</Link>
                }} />;
  
                result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC029', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC029.pdf')}>MADGC029</Link>
                }} />;
                  
              }

          } else if (result.sum > 15 && result.sum <= 20) {
            
              if (item === 'sort.item_one') {

                result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC021', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC021.pdf')}>MADGC021</Link>
                }} />;
  
                result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC021', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC021.pdf')}>MADGC021</Link>
                }} />;
  
              } else if (item === 'sort.item_two') {

                result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC024', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC024.pdf')}>MADGC024</Link>
                }} />;
  
                result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC024', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC024.pdf')}>MADGC024</Link>
                }} />;
  
              } else if (item === 'sort.item_three') {

                result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC027', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC027.pdf')}>MADGC027</Link>
                }} />;
  
                result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC027', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC027.pdf')}>MADGC027</Link>
                }} />;
  
              } else if (item === 'sort.item_four') {

                result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC030', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC030.pdf')}>MADGC030</Link>
                }} />;
  
                result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
                values={{
                  sheetOne: <Link style={{ cursor: 'pointer' }}
                              onClick={event => this.handlePDFClickOpen('MADGC030', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC030.pdf')}>MADGC030</Link>
                }} />;
  
              }

          }

        } else if (row.topic.split('.')[0] === 'socialize') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'socialize.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC031', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC031.pdf')}>MADGC031</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC031', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC031.pdf')}>MADGC031</Link>
              }} />;
  
            } else if (item === 'socialize.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC034', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC034.pdf')}>MADGC034</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC034', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC034.pdf')}>MADGC034</Link>
              }} />;

            } else if (item === 'socialize.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC037', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC037.pdf')}>MADGC037</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC037', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC037.pdf')}>MADGC037</Link>
              }} />;

            } else if (item === 'socialize.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC040', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC040.pdf')}>MADGC040</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC040', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC040.pdf')}>MADGC040</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'socialize.item_one') {
  
              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC032', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC032.pdf')}>MADGC032</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC032', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC032.pdf')}>MADGC032</Link>
              }} />;
  
            } else if (item === 'socialize.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC035', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC035.pdf')}>MADGC035</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC035', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC035.pdf')}>MADGC035</Link>
              }} />;

            } else if (item === 'socialize.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC038', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC038.pdf')}>MADGC038</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC038', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC038.pdf')}>MADGC038</Link>
              }} />;

            } else if (item === 'socialize.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC041', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC041.pdf')}>MADGC041</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC041', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC041.pdf')}>MADGC041</Link>
              }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {

            if (item === 'socialize.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC033', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC033.pdf')}>MADGC033</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC033', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC033.pdf')}>MADGC033</Link>
              }} />;
  
            } else if (item === 'socialize.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC036', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC036.pdf')}>MADGC036</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC036', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC036.pdf')}>MADGC036</Link>
              }} />;

            } else if (item === 'socialize.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC039', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC039.pdf')}>MADGC039</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC039', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC039.pdf')}>MADGC039</Link>
              }} />;

            } else if (item === 'socialize.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC042', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC042.pdf')}>MADGC042</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC042', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC042.pdf')}>MADGC042</Link>
              }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'develop') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'develop.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC043', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC043.pdf')}>MADGC043</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC043', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC043.pdf')}>MADGC043</Link>
              }} />;

            } else if (item === 'develop.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC046', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC046.pdf')}>MADGC046</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC046', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC046.pdf')}>MADGC046</Link>
              }} />;

            } else if (item === 'develop.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC049', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC049.pdf')}>MADGC049</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC049', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC049.pdf')}>MADGC049</Link>
              }} />;

            } else if (item === 'develop.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC052', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC052.pdf')}>MADGC052</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC052', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC052.pdf')}>MADGC052</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'develop.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC044', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC044.pdf')}>MADGC044</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC044', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC044.pdf')}>MADGC044</Link>
              }} />;

            } else if (item === 'develop.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC047', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC047.pdf')}>MADGC047</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC047', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC047.pdf')}>MADGC047</Link>
              }} />;

            } else if (item === 'develop.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC050', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC050.pdf')}>MADGC050</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC050', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC050.pdf')}>MADGC050</Link>
              }} />;

            } else if (item === 'develop.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC053', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC053.pdf')}>MADGC053</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC053', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC053.pdf')}>MADGC053</Link>
              }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            if (item === 'develop.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC045', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC045.pdf')}>MADGC045</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC045', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC045.pdf')}>MADGC045</Link>
              }} />;

            } else if (item === 'develop.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC048', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC048.pdf')}>MADGC048</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC048', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC048.pdf')}>MADGC048</Link>
              }} />;
              
            } else if (item === 'develop.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC051', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC051.pdf')}>MADGC051</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC051', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC051.pdf')}>MADGC051</Link>
              }} />;

            } else if (item === 'develop.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC054', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC054.pdf')}>MADGC054</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC054', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC054.pdf')}>MADGC054</Link>
              }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'policies') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'policies.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC055', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC055.pdf')}>MADGC055</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC055', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC055.pdf')}>MADGC055</Link>
              }} />;

            } else if (item === 'policies.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC058', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC058.pdf')}>MADGC058</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC058', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC058.pdf')}>MADGC058</Link>
              }} />;

            } else if (item === 'policies.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC061', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC061.pdf')}>MADGC061</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC061', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC061.pdf')}>MADGC061</Link>
              }} />;

            } else if (item === 'policies.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC064', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC064.pdf')}>MADGC064</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC064', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC064.pdf')}>MADGC064</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'policies.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC056', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC056.pdf')}>MADGC056</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC056', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC056.pdf')}>MADGC056</Link>
              }} />;
                                
            } else if (item === 'policies.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC059', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC059.pdf')}>MADGC059</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC059', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC059.pdf')}>MADGC059</Link>
              }} />;

            } else if (item === 'policies.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC062', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC062.pdf')}>MADGC062</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC062', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC062.pdf')}>MADGC062</Link>
              }} />;

            } else if (item === 'policies.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC065', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC065.pdf')}>MADGC065</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC065', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC065.pdf')}>MADGC065</Link>
              }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {

            if (item === 'policies.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC057', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC057.pdf')}>MADGC057</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC057', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC057.pdf')}>MADGC057</Link>
              }} />;

            } else if (item === 'policies.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC060', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC060.pdf')}>MADGC060</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC060', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC060.pdf')}>MADGC060</Link>
              }} />;

            } else if (item === 'policies.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC063', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC063.pdf')}>MADGC063</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC063', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC063.pdf')}>MADGC063</Link>
              }} />;

            } else if (item === 'policies.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC066', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC066.pdf')}>MADGC066</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC066', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC066.pdf')}>MADGC066</Link>
              }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'structures') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'structures.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC067', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC067.pdf')}>MADGC067</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC067', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC067.pdf')}>MADGC067</Link>
              }} />;

            } else if (item === 'structures.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC070', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC070.pdf')}>MADGC070</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC070', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC070.pdf')}>MADGC070</Link>
              }} />;
            
            } else if (item === 'structures.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC073', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC073.pdf')}>MADGC073</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC073', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC073.pdf')}>MADGC073</Link>
              }} />;

            } else if (item === 'structures.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC076', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC076.pdf')}>MADGC076</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC076', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC076.pdf')}>MADGC076</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'structures.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC068', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC068.pdf')}>MADGC068</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC068', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC068.pdf')}>MADGC068</Link>
              }} />;

            } else if (item === 'structures.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC071', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC071.pdf')}>MADGC071</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC071', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC071.pdf')}>MADGC071</Link>
              }} />;
            
            } else if (item === 'structures.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC074', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC074.pdf')}>MADGC074</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC074', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC074.pdf')}>MADGC074</Link>
              }} />;

            } else if (item === 'structures.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC077', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC077.pdf')}>MADGC077</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC077', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC077.pdf')}>MADGC077</Link>
              }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {

            if (item === 'structures.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC069', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC069.pdf')}>MADGC069</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC069', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC069.pdf')}>MADGC069</Link>
              }} />;

            } else if (item === 'structures.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC072', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC072.pdf')}>MADGC072</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC072', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC072.pdf')}>MADGC072</Link>
              }} />;
            
            } else if (item === 'structures.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC075', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC075.pdf')}>MADGC075</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC075', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC075.pdf')}>MADGC075</Link>
              }} />;

            } else if (item === 'structures.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC078', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC078.pdf')}>MADGC078</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC078', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC078.pdf')}>MADGC078</Link>
              }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'technology') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'technology.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC079', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC079.pdf')}>MADGC079</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC079', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC079.pdf')}>MADGC079</Link>
              }} />;

            } else if (item === 'technology.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC082', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC082.pdf')}>MADGC082</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC082', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC082.pdf')}>MADGC082</Link>
              }} />;

            } else if (item === 'technology.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC085', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC085.pdf')}>MADGC085</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC085', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC085.pdf')}>MADGC085</Link>
              }} />;

            } else if (item === 'technology.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC088', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC088.pdf')}>MADGC088</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC088', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC088.pdf')}>MADGC088</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'technology.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC080', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC080.pdf')}>MADGC080</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC080', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC080.pdf')}>MADGC080</Link>
              }} />;

            } else if (item === 'technology.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC083', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC083.pdf')}>MADGC083</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC083', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC083.pdf')}>MADGC083</Link>
              }} />;

            } else if (item === 'technology.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC086', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC086.pdf')}>MADGC086</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC086', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC086.pdf')}>MADGC086</Link>
              }} />;

            } else if (item === 'technology.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC089', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC089.pdf')}>MADGC089</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC089', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC089.pdf')}>MADGC089</Link>
              }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            if (item === 'technology.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC081', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC081.pdf')}>MADGC081</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC081', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC081.pdf')}>MADGC081</Link>
              }} />;

            } else if (item === 'technology.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC084', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC084.pdf')}>MADGC084</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC084', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC084.pdf')}>MADGC084</Link>
              }} />;

            } else if (item === 'technology.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC087', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC087.pdf')}>MADGC087</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC087', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC087.pdf')}>MADGC087</Link>
              }} />;

            } else if (item === 'technology.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC090', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC090.pdf')}>MADGC090</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC090', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC090.pdf')}>MADGC090</Link>
              }} />;

            }

          }

        } else if (row.topic.split('.')[0] === 'results') {

          if (result.sum >= 0 && result.sum <= 10) {

            if (item === 'results.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC091', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC091.pdf')}>MADGC091</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC091', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC091.pdf')}>MADGC091</Link>
              }} />;

            } else if (item === 'results.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC094', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC094.pdf')}>MADGC094</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC094', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC094.pdf')}>MADGC094</Link>
              }} />;

            } else if (item === 'results.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC097', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC097.pdf')}>MADGC097</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC097', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC097.pdf')}>MADGC097</Link>
              }} />;

            } else if (item === 'results.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC100', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC100.pdf')}>MADGC100</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_one_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC100', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC100.pdf')}>MADGC100</Link>
              }} />;

            }

          } else if (result.sum > 10 && result.sum <= 15) {

            if (item === 'results.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC092', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC092.pdf')}>MADGC092</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC092', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC092.pdf')}>MADGC092</Link>
              }} />;

            } else if (item === 'results.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC095', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC095.pdf')}>MADGC095</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC095', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC095.pdf')}>MADGC095</Link>
              }} />;

            } else if (item === 'results.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC098', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC098.pdf')}>MADGC098</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC098', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC098.pdf')}>MADGC098</Link>
              }} />;

            } else if (item === 'results.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC098', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC101.pdf')}>MADGC101</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_two_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC098', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC101.pdf')}>MADGC101</Link>
              }} />;

            }

          } else if (result.sum > 15 && result.sum <= 20) {
            
            if (item === 'results.item_one') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC093', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC093.pdf')}>MADGC093</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC093', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC093.pdf')}>MADGC093</Link>
              }} />;

            } else if (item === 'results.item_two') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC096', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC096.pdf')}>MADGC096</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC096', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC096.pdf')}>MADGC096</Link>
              }} />;

            } else if (item === 'results.item_three') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC099', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC099.pdf')}>MADGC099</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC099', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC099.pdf')}>MADGC099</Link>
              }} />;

            } else if (item === 'results.item_four') {

              result.interpretation = <FormattedMessage id={item + '_interpretation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC102', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC102.pdf')}>MADGC102</Link>,
                sheetTwo: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC103', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC103.pdf')}>MADGC103</Link>
              }} />;

              result.recommendation = <FormattedMessage id={item + '_recommendation_three_lz'}
              values={{
                sheetOne: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC102', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC102.pdf')}>MADGC102</Link>,
                sheetTwo: <Link style={{ cursor: 'pointer' }}
                            onClick={event => this.handlePDFClickOpen('MADGC103', 'https://kitmake.ica2.com/resources/zero/m/ficha_MADGC103.pdf')}>MADGC103</Link>
                          }} />;

            }

          }

        }

      }
      return true
    });

    return result;
  }

  makeFocusResult(item) {

    var result = this.makeResult(item + '.item_one').sum + this.makeResult(item + '.item_two').sum + this.makeResult(item + '.item_three').sum + this.makeResult(item + '.item_four').sum;
    var recommendation = '';

    if (result >= 0 && result <= 30) {
      recommendation = <FormattedMessage id={item + '.recommendation_one_lz'} />;;
    } else if (result > 30 && result <= 60) {
      recommendation = <FormattedMessage id={item + '.recommendation_two_lz'} />;;
    } else if (result > 60 && result <= 80) {
      recommendation = <FormattedMessage id={item + '.recommendation_three_lz'} />;;
    }
    return recommendation;
  }

  handlePDFClickOpen(title, url) {
    this.setState({ openPDFDialog: true, title: title, url: url });
  }

  handlePDFClickClose() {
    this.setState({ openPDFDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          { 'Valoración y Análisis de Madurez de la Gestión de Conocimiento' }
        </Typography>

        <Dialog
          fullWidth={true}
          open={this.state.openPDFDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handlePDFClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Valoración</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_title_lz' } /> : null }</b>
              <br />{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_desc_lz' } /> : null }
            </DialogContentText>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><b>{ 'Relevancia'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '1'} value={1} onChange={this.handleChange('relevance')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '2'} value={2} onChange={this.handleChange('relevance')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '3'} value={3} onChange={this.handleChange('relevance')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '4'} value={4} onChange={this.handleChange('relevance')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.relevance === '5'} value={5} onChange={this.handleChange('relevance')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Especificidad'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '1'} value={1} onChange={this.handleChange('specificity')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '2'} value={2} onChange={this.handleChange('specificity')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '3'} value={3} onChange={this.handleChange('specificity')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '4'} value={4} onChange={this.handleChange('specificity')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.specificity === '5'} value={5} onChange={this.handleChange('specificity')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Control'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '1'} value={1} onChange={this.handleChange('control')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '2'} value={2} onChange={this.handleChange('control')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '3'} value={3} onChange={this.handleChange('control')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '4'} value={4} onChange={this.handleChange('control')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.control === '5'} value={5} onChange={this.handleChange('control')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Impactos'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '40%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '1'} value={1} onChange={this.handleChange('impacts')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '2'} value={2} onChange={this.handleChange('impacts')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '3'} value={3} onChange={this.handleChange('impacts')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '4'} value={4} onChange={this.handleChange('impacts')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.impacts === '5'} value={5} onChange={this.handleChange('impacts')} color="primary" />} />  
                  </RadioGroup>
                  </TableCell>
                </TableRow>                                                
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              disabled={ this.state.currentRating.relevance === 0 || this.state.currentRating.specificity === 0 || this.state.currentRating.control === 0 || this.state.currentRating.impacts === 0 }
              onClick={this.handleSave}
              color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '20%' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '2%' }} align={'left'}>{ ' Valoración' }</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Interpretación' }</TableCell>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Recomendaciones' }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="strategy.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="strategy.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('strategy.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="strategy.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('strategy.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('strategy.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('strategy.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('strategy.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('strategy.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="strategy.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('strategy.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('strategy.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('strategy.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('strategy.item_two').recommendation }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="connect.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="connect.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('connect.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="connect.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('connect.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('connect.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('connect.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="connect.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('connect.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('connect.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('connect.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="connect.item_three_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('connect.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('connect.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('connect.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="connect.item_four_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('connect.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('connect.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('connect.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                { this.makeResult('connect.item_one').sum + this.makeResult('connect.item_two').sum + this.makeResult('connect.item_three').sum + this.makeResult('connect.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('connect') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="sort.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="sort.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('sort.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="sort.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('sort.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('sort.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('sort.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="sort.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('sort.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('sort.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('sort.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="sort.item_three_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('sort.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('sort.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('sort.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="sort.item_four_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('sort.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('sort.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('sort.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('sort.item_one').sum + this.makeResult('sort.item_two').sum + this.makeResult('sort.item_three').sum + this.makeResult('sort.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#e91e63', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('sort') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="socialize.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="socialize.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="socialize.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('socialize.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="socialize.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('socialize.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="socialize.item_three_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('socialize.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="socialize.item_four_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('socialize.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('socialize.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('socialize.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('socialize.item_one').sum + this.makeResult('socialize.item_two').sum + this.makeResult('socialize.item_three').sum + this.makeResult('socialize.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ffc107', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('socialize') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="develop.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="develop.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('develop.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="develop.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('develop.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('develop.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('develop.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="develop.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('develop.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('develop.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('develop.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="develop.item_three_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('develop.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('develop.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('develop.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="develop.item_four_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('develop.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('develop.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('develop.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="develop.title" /></TableCell>
                <TableCell style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('develop.item_one').sum + this.makeResult('develop.item_two').sum + this.makeResult('develop.item_three').sum + this.makeResult('develop.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#bdbdbd', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('develop') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="policies.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="policies.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('policies.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="policies.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('policies.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('policies.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('policies.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="policies.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('policies.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('policies.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('policies.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="policies.item_three_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('policies.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('policies.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('policies.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="policies.item_four_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('policies.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('policies.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('policies.item_four').recommendation }</TableCell>
              </TableRow> 
              <TableRow>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('policies.item_one').sum + this.makeResult('policies.item_two').sum + this.makeResult('policies.item_three').sum + this.makeResult('policies.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#ff5722', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('policies') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="structures.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="structures.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('structures.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="structures.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('structures.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('structures.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('structures.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f',  cursor: 'pointer' }} align={'left'}><FormattedMessage id="structures.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('structures.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('structures.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('structures.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="structures.item_three_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('structures.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('structures.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('structures.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="structures.item_four_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('structures.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('structures.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('structures.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('structures.item_one').sum + this.makeResult('structures.item_two').sum + this.makeResult('structures.item_three').sum + this.makeResult('structures.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#78909c', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('structures') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="technology.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="technology.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('technology.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="technology.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('technology.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('technology.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('technology.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="technology.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('technology.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('technology.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('technology.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="technology.item_three_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('technology.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('technology.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('technology.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="technology.item_four_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('technology.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('technology.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('technology.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('technology.item_one').sum + this.makeResult('technology.item_two').sum + this.makeResult('technology.item_three').sum + this.makeResult('technology.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#0097a7', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('technology') }</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="results.title" /></TableCell>
                <TableCell colSpan={3} style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="results.title_desc" /></TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('results.item_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="results.item_one_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('results.item_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('results.item_one').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_one').interpretation }</TableCell>
                <TableCell className={ classes.colTwo }align={'left'}>{ this.makeResult('results.item_one').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('results.item_two')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="results.item_two_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('results.item_two')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('results.item_two').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_two').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_two').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('results.item_three')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="results.item_three_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('results.item_three')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('results.item_three').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_three').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_three').recommendation }</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell onClick={event => this.handleClickOpen('results.item_four')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="results.item_four_title_lz" /></TableCell>
                <TableCell onClick={event => this.handleClickOpen('results.item_four')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeResult('results.item_four').sum }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_four').interpretation }</TableCell>
                <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResult('results.item_four').recommendation }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}></TableCell>
                <TableCell style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0 }} align={'left'}>
                  { this.makeResult('results.item_one').sum + this.makeResult('results.item_two').sum + this.makeResult('results.item_three').sum + this.makeResult('results.item_four').sum }
                </TableCell>
                <TableCell colSpan={2} style={{ backgroundColor: '#43a047', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeFocusResult('results') }</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

ModelZero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModelZero);
/* eslint-enable no-unused-vars */