/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { FormattedMessage } from 'react-intl';
import agent from './Agent';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Support from './Support';
import SupportZero from './SupportZero';

let counter = 0;
function createData(oid, title, description, domains, created_at) {
  counter += 1;
  return { id: counter, oid, title, description, domains, created_at };
}

const ExpansionPanel = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const Dialog = withStyles(theme => ({
  paper: { borderRadius: 0 }
}))(MuiDialog);

class ExpansionAgents extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      expanded: '',
      agents: [],
      openDialog: false,
      openSnack: false,
      id: '',
      title: '',
      description: '',
      fullWidth: true,
      maxWidth: 'sm',
      submitAction: 'new',
      disableSubmit: true,
      submitActionTitle: <FormattedMessage id="agents.add" />,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setUp = this.setUp.bind(this);
  } 

  componentDidMount() {
    this.setUp();
  }

  UNSAFE_componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.areaId !== prevProps.areaId) {
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    agent.getList(this.props.areaId).then((result) =>
      this.setState(data => ({ agents: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)) }))
    );
  }

  handleChange = (value) => {
    if (this.state.expanded === value) {
      this.setState({ expanded: false });
    } else {
      this.setState({ expanded: value });
    }
  }

  handleClickOpen() {
    this.setState({ openDialog: true, submitAction: 'new', submitActionTitle: <FormattedMessage id="agents.add" /> });
  }

  handleClickClose() {
    this.setState({ openDialog: false, id: '', title: '', description: '' });
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitAction === 'new') {
      agent.create(this.state.title, this.state.description, this.props.areaId, () => {
        agent.getList(this.props.areaId).then((result) => {
          this.setState(data => ({
              agents: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)),
              id: '',
              title: '',
              description: '',
              SnackMessage: <FormattedMessage id="agents.saved" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    } else {
      agent.update(this.state.id, this.state.title, this.state.description, this.props.areaId, () => {
        agent.getList(this.props.areaId).then((result) => {
            this.setState(data => ({
              agents: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)),
              id: '',
              title: '',
              description: '',
              SnackMessage: <FormattedMessage id="agents.updated" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    }
  }

  render() {

    return (
      <React.Fragment>

        <Grid container spacing={1} alignItems="center" style={{ marginBottom: 4 }}>
          <Grid item>
            <Typography><FormattedMessage id="agents.title" /></Typography>
          </Grid>
          <Grid item>
            <Tooltip title={<FormattedMessage id="agents.add" />} placement={'right'}>
              <IconButton aria-label={<FormattedMessage id="agents.add" />} onClick={this.handleClickOpen}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="title"><FormattedMessage id="agents.head_title" /></InputLabel>
                <Input id="title" value={this.state.title} onChange={this.handleTitleChange} autoFocus />
              </FormControl>                        
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleSubmit} color="primary" disabled={!(this.state.title !== '')}>
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        { this.state.agents.map(({ id, oid, title, description, domains }) => (
        <ExpansionPanel key={title} square expanded={this.state.expanded === 'panel' + id } onChange={event => this.handleChange('panel' + id)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>{ title }</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>            
              { this.props.areaLevel === 0 ? <SupportZero agentId={oid} /> : <Support agentId={oid} /> }
          </ExpansionPanelDetails>
        </ExpansionPanel>
        ))}

      </React.Fragment>
    );
  }
}

export default ExpansionAgents;
/* eslint-enable no-unused-vars */