/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MakeContext } from '../MakeProvider';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Typography from '@material-ui/core/Typography';
import BallotIcon from '@material-ui/icons/Ballot';
import HelpIcon from '@material-ui/icons/Help';
import Link from '@material-ui/core/Link';
import { FormattedMessage } from 'react-intl';
import area from '../areas/Area';
import auth from '../Auth';

let counter = 1;
function createData(oid, name, description) {
  counter += 1;
  return { key: counter, id: name, icon: <FullscreenIcon />, link: "/areas/" + oid };
}

const areaItems = [
  {
    id: <FormattedMessage id="areas.title" />,
    key: 1,
    children: [],
  }
];

const contextItems = [
  {
    id: <FormattedMessage id="context.title" />,
    key: 27,
    children: [
      { key: 28, id: <FormattedMessage id="areas.title" />, icon: <GroupWorkIcon />, link: "/areas", active: false },
      { key: 29, id: <FormattedMessage id="references.title" />, icon: <BallotIcon />, link: "/references", active: false },
      /*{ key: 29, id: <FormattedMessage id="categories.title" />, icon: <ViewListIcon />, link: "/categories", active: false },
      { key: 30, id: <FormattedMessage id="users.title" />, icon: <PeopleIcon />, link: "/users", active: false },*/
    ],
  }
];

const KitItems = [
  {
    id: <FormattedMessage id="kit.title" />,
    key: 27,
    children: [
      { key: 28, id: <FormattedMessage id="kit.model" />, icon: <GroupWorkIcon />, link: "/model", active: false },
      /*{ key: 29, id: <FormattedMessage id="categories.title" />, icon: <ViewListIcon />, link: "/categories", active: false },*/
      { key: 30, id: <FormattedMessage id="kit.how" />, icon: <HelpIcon />, link: "/how", active: false },
      { key: 31, id: <FormattedMessage id="kit.reference" />, icon: <BallotIcon />, link: "/reference", active: false },
    ],
  }
];

const styles = theme => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.black,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(0, 0, 0, 0.7)',
  },
  itemCategory: {
    backgroundColor: '#fff',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 20,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  organization: {
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.black,
    cursor: 'pointer'
  },
  copy: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.7)',
    fontFamily: theme.typography.fontFamily,
    padding: 12,
    marginTop: 'auto',
  },
  copyLinK: {
    '&:hover': {
      textDecoration: 'none',
    },
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.7)',
  },
  itemOrg: {
    backgroundColor: '#fff',
    paddingTop: 16,
    paddingBottom: 0,
    cursor: 'pointer'
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
    '&$textDense': {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing(2),
  },
  dialogStyle: {
    borderRadius: 0
  }
});

class Navigator extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      data: [],
      fullWidth: true,
      maxWidth: 'sm',
      areaData: {},
      currentItem: 28
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleGoHome = this.handleGoHome.bind(this);
    this.handleorgNameChange = this.handleorgNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    areaItems[0].children = [];
    area.getList().then((result) =>
      this.setState(data => ({ data: result.map(n => areaItems[0].children.push(createData(n._id.$oid, n.title, n.description))) }))
    )
  }

  handleClickOpen() {
    this.setState({ openDialog: true });
  }

  handleClickClose() {
    this.setState({ openDialog: false });
  }

  handleGoHome = (event, id, oid) => {
    this.props.history.push("/");
  };

  handleorgNameChange(event) {

  }

  handleSubmit(e) {

  }

  render() {

  const { classes, ...other } = this.props;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={classNames(classes.firebase, classes.item, classes.itemOrg)} onClick={this.handleGoHome} style={{ paddingTop: 20, paddingBottom: 24, justifyContent: 'center' }}>
          <img src="/make.png" alt="Make" width="71px" />
        </ListItem>
        {
          KitItems.map(({ id, key, children }) => (
          <React.Fragment key={key}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ key, id: childId, link, icon, active }) => (
              <ListItem
                button
                dense
                key={key}
                className={classNames(
                  classes.item,
                  classes.itemActionable,
                  (this.props.history.location.pathname === link) && classes.itemActiveItem,
                )}
                onClick={() => { this.props.history.push(link); }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                    /*textDense: classes.textDense,*/
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        )) }
        {
          areaItems.map(({ id, key, children }) => (
          <React.Fragment key={key}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ key, id: childId, link, icon, active }) => (
              <ListItem
                button
                dense
                key={key}
                className={classNames(
                  classes.item,
                  classes.itemActionable,
                  (this.props.history.location.pathname === link) && classes.itemActiveItem,
                )}
                onClick={() => { this.props.history.push(link); }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                    /*textDense: classes.textDense,*/
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        )) }
        { auth.getProfile().role.filter(role => (role.name === "admin" || role.name === "owner")).length > 0 ?
          contextItems.map(({ id, key, children }) => (
          <React.Fragment key={key}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ key, id: childId, link, icon, active }) => (
              <ListItem
                button
                dense
                key={key}
                className={classNames(
                  classes.item,
                  classes.itemActionable,
                  (this.props.history.location.pathname === link) && classes.itemActiveItem,
                )}
                onClick={() => { this.props.history.push(link); }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                    /*textDense: classes.textDense,*/
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        )) : null }
      </List>
      <Typography className={classes.copy}>
        © 2022 <br/>
        <Link href="https://ica2.com" target="_blank" className={classes.copyLinK}>ICA2 Innovación y Tecnología S.L.</Link>
        <br/>
        <Link href="https://www.fundecyt-pctex.es" target="_blank" className={classes.copyLinK}>FUNDECYT - PCTEX.</Link>
      </Typography>
    </Drawer>
  );
  }
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
/* eslint-enable no-unused-vars */
