/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { MakeContext } from '../../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { FormattedMessage } from 'react-intl';
import line from '../lines/Line';
import domain from '../domains/Domain';
import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

let counter = 0;
function createData(oid, title, description, domains, created_at) {
  counter += 1;
  return { id: counter, oid, title, description, domains, created_at };
}

let counterDomain = 0;
function createDomainData(oid, title, description, created_at) {
  counterDomain += 1;
  return { id: counterDomain, oid, title, description, created_at };
}

const ExpansionPanel = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

const Dialog = withStyles(theme => ({
  paper: { borderRadius: 0 }
}))(MuiDialog);

class ExpansionLinesDomains extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      expanded: '',
      lines: [],
      lineId: null,
      domainId: null,
      openDialog: false,
      openDomainDialog: false,
      openDelDialog: false,
      openDelDomainDialog: false,
      openSnack: false,
      id: '',
      title: '',
      description: '',
      openRatingDialog: false,
      currentRating: { priority: 0, complexity: 0, shortage: 0, singularity: 0, applicability: 0, risk: 0 },
      fullWidth: true,
      maxWidth: 'sm',
      submitAction: 'new',
      disableSubmit: true,
      submitActionTitle: <FormattedMessage id="lines.add" />,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickDomainOpen = this.handleClickDomainOpen.bind(this);
    this.handleClickDomainClose = this.handleClickDomainClose.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDomainSubmit = this.handleDomainSubmit.bind(this);
    this.handleLineEditClickOpen = this.handleLineEditClickOpen.bind(this);
    this.handleDeleteLine = this.handleDeleteLine.bind(this);
    this.handleDomainEditClickOpen = this.handleDomainEditClickOpen.bind(this);
    this.handleDeleteDomain = this.handleDeleteDomain.bind(this);
    this.openDelDialog = this.openDelDialog.bind(this);
    this.handleDelClickClose = this.handleDelClickClose.bind(this);
    this.openDelDomainDialog = this.openDelDomainDialog.bind(this);
    this.handleDelDomainClickClose = this.handleDelDomainClickClose.bind(this);
    this.handleRatingChange = this.handleRatingChange.bind(this);
    this.handleRatingClickOpen = this.handleRatingClickOpen.bind(this);
    this.handleRatingClickClose = this.handleRatingClickClose.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setUp = this.setUp.bind(this);
  } 

  componentDidMount() {
    this.setUp();
  }

  UNSAFE_componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.areaId !== prevProps.areaId) {
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    line.getList(this.props.areaId).then((result) =>
      this.setState(data => ({ lines: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)) }))
    );
  }

  handleChange = (value) => {
    if (this.state.expanded === value) {
      this.setState({ expanded: false });
    } else {
      this.setState({ expanded: value });
    }
  }

  handleClickOpen() {
    this.setState({ openDialog: true, submitAction: 'new', submitActionTitle: <FormattedMessage id="lines.add" /> });
  }

  handleLineEditClickOpen(lineId, title, description) {
    this.setState({ openDialog: true, submitAction: 'edit', title: title, description: description, lineId: lineId, submitActionTitle: <FormattedMessage id="lines.editing" /> });
  }

  handleClickClose() {
    this.setState({ openDialog: false, id: '', title: '', description: '' });
  }

  handleClickDomainOpen(lineId) {
    this.setState({ openDomainDialog: true, submitAction: 'new', lineId: lineId, submitActionTitle: <FormattedMessage id="domains.add" /> });
  }

  handleDomainEditClickOpen(oid, title, description, lineId) {
    this.setState({ openDomainDialog: true, domainId: oid, title: title, description: description, lineId: lineId, submitAction: 'edit', submitActionTitle: <FormattedMessage id="domains.editing" /> });
  }

  handleClickDomainClose() {
    this.setState({ openDomainDialog: false, id: '', title: '', description: '' });
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  openDelDialog() {
    this.setState({ openDelDialog: true });
  }

  handleDelClickClose() {
    this.setState({ openDelDialog: false });
  }

  openDelDomainDialog(domainId, lineId) {
    this.setState({ openDelDomainDialog: true, domainId: domainId, lineId: lineId });
  }

  handleDelDomainClickClose() {
    this.setState({ openDelDomainDialog: false });
  }

  handleRatingClickOpen(domainId, resourceType, resourceId) {
    let rating = { priority: 0, complexity: 0, shortage: 0, singularity: 0, applicability: 0, risk: 0 };
    domain.getKMap(domainId, resourceType, resourceId).then((row) => {

      if (row !== null) {
        rating = { priority: row.priority.toString(), complexity: row.complexity.toString(), shortage: row.shortage.toString(), singularity: row.singularity.toString(), applicability: row.applicability.toString(), risk: row.risk.toString() }
      }

      this.setState({ openRatingDialog: true, domainId: domainId, lineId: resourceId, currentRating: rating });
    });
    
  }

  handleRatingClickClose() {
    this.setState({ openRatingDialog: false });
  }

  handleRatingChange = (criteria) => (event) => {
    switch (criteria) {
      case 'priority':
        this.setState({ openRatingDialog: true, currentRating: { ...this.state.currentRating, priority: event.target.value } });
        break;
      case 'complexity':
        this.setState({ openRatingDialog: true, currentRating: { ...this.state.currentRating, complexity: event.target.value } });
        break;
      case 'shortage':
        this.setState({ openRatingDialog: true, currentRating: { ...this.state.currentRating, shortage: event.target.value } });
        break;
      case 'singularity':
        this.setState({ openRatingDialog: true, currentRating: { ...this.state.currentRating, singularity: event.target.value } });
        break;
      case 'applicability':
        this.setState({ openRatingDialog: true, currentRating: { ...this.state.currentRating, applicability: event.target.value } });
        break;
      case 'risk':
        this.setState({ openRatingDialog: true, currentRating: { ...this.state.currentRating, risk: event.target.value } });
        break;                  
      default:
        break;
    }
  }

  handleSave(e) {
    e.preventDefault();
    domain.saveKMap(this.state.domainId, 'Line', this.state.lineId, this.state.currentRating).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="kmap.updated" />,
        openSnack: true
      });
      this.handleRatingClickClose();
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitAction === 'new') {
      line.create(this.state.title, this.state.description, this.props.areaId, () => {
        line.getList(this.props.areaId).then((result) => {
          this.setState(data => ({
              lines: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)),
              lineId: '',
              title: '',
              description: '',
              SnackMessage: <FormattedMessage id="lines.saved" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    } else {
      line.update(this.state.lineId, this.state.title, this.state.description, this.props.areaId, () => {
        line.getList(this.props.areaId).then((result) => {
            this.setState(data => ({
              lines: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)),
              lineId: '',
              title: '',
              description: '',
              SnackMessage: <FormattedMessage id="lines.updated" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    }
  }

  handleDeleteLine = (oid) => {
    line.delete(oid).then((result) => {
      line.getList(this.props.areaId).then((result) => {
        this.setState(data => ({
          lines: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)),
          id: '',
          title: '',
          description: '',
          SnackMessage: <FormattedMessage id="lines.deleted" />,
          openSnack: true
        }));
        this.handleClickClose();
      })
    })
  }

  handleDomainSubmit(e) {
    e.preventDefault();
    if (this.state.submitAction === 'new') {
      domain.create(this.state.title, this.state.description, 'Line', this.state.lineId, () => {
        line.getList(this.props.areaId).then((result) => {
          this.setState(data => ({
              lines: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)),
              lineId: '',
              title: '',
              description: '',
              SnackMessage: <FormattedMessage id="domains.saved" />,
              openSnack: true,
            }));
            this.handleClickDomainClose();
          }
        )
      })
    } else {
      domain.update(this.state.domainId, this.state.title, this.state.description, 'Line', this.state.lineId, () => {
        line.getList(this.props.areaId).then((result) => {
            this.setState(data => ({
              lines: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)),
              lineId: '',
              title: '',
              description: '',
              SnackMessage: <FormattedMessage id="domains.updated" />,
              openSnack: true
            }));
            this.handleClickDomainClose();
          }
        )
      })
    }
  }

  handleDeleteDomain = (oid) => {
    domain.delete(this.state.domainId, 'Line', this.state.lineId).then((result) => {
      line.getList(this.props.areaId).then((result) => {
        this.setState(data => ({
          lines: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)),
          id: '',
          title: '',
          description: '',
          SnackMessage: <FormattedMessage id="domains.deleted" />,
          openSnack: true
        }));
        this.handleClickClose();
      })
    })
  }

  render() {

    return (
      <React.Fragment>

        <Grid container spacing={1} alignItems="center" style={{ marginTop: 30, marginBottom: 4 }}>
          <Grid item>
            <Typography><FormattedMessage id="lines.title" /></Typography>
          </Grid>
          <Grid item>
            <Tooltip title={<FormattedMessage id="lines.add" />} placement={'right'}>
              <IconButton aria-label={<FormattedMessage id="lines.add" />} onClick={this.handleClickOpen}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="title"><FormattedMessage id="lines.head_title" /></InputLabel>
                <Input id="title" value={this.state.title} onChange={this.handleTitleChange} autoFocus />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="description"><FormattedMessage id="description" /></InputLabel>
                <Input id="description" value={this.state.description} onChange={this.handleDescriptionChange} multiline={true} rows="4" />
              </FormControl>                    
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleSubmit} color="primary" disabled={!(this.state.title !== '')}>
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDomainDialog}
          aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="title"><FormattedMessage id="domains.head_title" /></InputLabel>
                <Input id="title" value={this.state.title} onChange={this.handleTitleChange} autoFocus />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="description"><FormattedMessage id="description" /></InputLabel>
                <Input id="description" value={this.state.description} onChange={this.handleDescriptionChange} multiline={true} rows="4" />
              </FormControl>                         
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickDomainClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleDomainSubmit} color="primary" disabled={!(this.state.title !== '')}>
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.openDelDialog}
          aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">Eliminar Linea de Investigación</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>¿Estás seguro de eliminar esta linea de investigación?</b><br/>
              Se eliminarán todos los dominios de conocimiento asociados a esta linea de investigación.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleDeleteActivity} color="primary">
              <FormattedMessage id="delete" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.openDelDomainDialog}
          aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">Eliminar Dominio de Conocimiento</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>¿Estás seguro de eliminar este dominio de conocimiento?</b><br/>
              Se eliminarán las valoraciones a este dominio de conocimiento.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelDomainClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleDeleteDomain} color="primary">
              <FormattedMessage id="delete" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.openRatingDialog}
          aria-labelledby="form-dialog-title"
        >
        <DialogTitle id="form-dialog-title">Valoración</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_title' } /> : null }</b>
              <br />{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_desc' } /> : null }
            </DialogContentText>
            <Table>
              <TableBody>
              <TableRow>
                  <TableCell><b>{ 'Prioridad estratégica regional' }</b><br />{ 'Prioridad que representa los intereses estratégicos clave de la región y en la que se concentran gran parte de los esfuerzos de posicionamiento futuro del territorio.' } <br /> { ' Valoración 1 - 10 (1 nada representativo de las prioridades estratégicos de la región, 10 totalmente en línea con las prioridades estratégicas de la región).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.priority === '1'} value={1} onChange={this.handleRatingChange('priority')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.priority === '2'} value={2} onChange={this.handleRatingChange('priority')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.priority === '3'} value={3} onChange={this.handleRatingChange('priority')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.priority === '4'} value={4} onChange={this.handleRatingChange('priority')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.priority === '5'} value={5} onChange={this.handleRatingChange('priority')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Complejidad' }</b><br />{ 'La complejidad entendida como la dificultad para aprender el dominio dadas las numerosas variables que interactúan, la trayectoria y oficio necesario, etc.' } <br /> { ' Valoración 1 - 5 (1 dominio de conocimiento muy sencillo, fácil de copiar y extrapolar, 5 dominio de conocimiento muy complejo y díficil de copiar y extrapolar).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.complexity === '1'} value={1} onChange={this.handleRatingChange('complexity')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.complexity === '2'} value={2} onChange={this.handleRatingChange('complexity')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.complexity === '3'} value={3} onChange={this.handleRatingChange('complexity')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.complexity === '4'} value={4} onChange={this.handleRatingChange('complexity')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.complexity === '5'} value={5} onChange={this.handleRatingChange('complexity')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Escasez' }</b><br />{ 'Es un dominio de conocimiento muy propio de la actividad y existen pocos profesionales que lo dominen (tanto en el sector como fuera de él), por lo que también es dificil de externalizar.' } <br /> { ' Valoración 1 - 5 (1 dominio de conocimiento muy fácil de encontrar fuera de la región e incluso en otros sectores, 5 muy difícil de encontrar fuera de la región e incluso en otros sectores).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.shortage === '1'} value={1} onChange={this.handleRatingChange('shortage')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.shortage === '2'} value={2} onChange={this.handleRatingChange('shortage')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.shortage === '3'} value={3} onChange={this.handleRatingChange('shortage')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.shortage === '4'} value={4} onChange={this.handleRatingChange('shortage')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.shortage === '5'} value={5} onChange={this.handleRatingChange('shortage')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Singularidad' }</b><br />{ 'Es un dominio de conocimiento del que se conoce poco fuera de la actividad en cuestión. Esta poco o nada difundido, y aunque puede estar en parte documentado, es un conocimiento normalmente tácito.' } <br /> { ' Valoración 1 - 5 (1 dominio de conocimiento muy común en cualquier sector, de dominio público, 5 dominio de conocimiento muy de know how propio, de oficio y expertise específico).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.singularity === '1'} value={1} onChange={this.handleRatingChange('singularity')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.singularity === '2'} value={2} onChange={this.handleRatingChange('singularity')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.singularity === '3'} value={3} onChange={this.handleRatingChange('singularity')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.singularity === '4'} value={4} onChange={this.handleRatingChange('singularity')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.singularity === '5'} value={5} onChange={this.handleRatingChange('singularity')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Aplicabilidad' }</b><br />{ 'Es un dominio de conocimiento clave por su relevancia en el funcionamiento correcto de la actividad actual y futura. Sin el, la actividad entra en crisis o se para.' } <br /> { ' Valoración 1 - 5 (1 dominio de conocimiento que muy esporádicamente resulta importante, 5 dominio de conocimiento de uso muy recurrente y en clara relación con la continuidad de las operaciones).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.applicability === '1'} value={1} onChange={this.handleRatingChange('applicability')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.applicability === '2'} value={2} onChange={this.handleRatingChange('applicability')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.applicability === '3'} value={3} onChange={this.handleRatingChange('applicability')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.applicability === '4'} value={4} onChange={this.handleRatingChange('applicability')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.applicability === '5'} value={5} onChange={this.handleRatingChange('applicability')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow> 
                <TableRow>
                  <TableCell><b>{ 'Riesgo' }</b><br />{ 'Es un dominio de conocimiento que puede perderse por relevo generacional, disrupciones tecnológicas severas, cambios de modelos de negocio, etc.' } <br /> { ' Valoracion 1 – 5 (1 dominio de conocimiento muy asentado y sin problemas de relevo o pérdida, 5 dominio de conocimiento con muchas opciones de pérdida y con claro riesgo de volatilidad).' }</TableCell>
                  <TableCell style={ { width: '50%' } }>
                  <RadioGroup row>
                    <FormControlLabel label="1" labelPlacement="top" control={<Radio checked={this.state.currentRating.risk === '1'} value={1} onChange={this.handleRatingChange('risk')} color="primary" />} />
                    <FormControlLabel label="2" labelPlacement="top" control={<Radio checked={this.state.currentRating.risk === '2'} value={2} onChange={this.handleRatingChange('risk')} color="primary" />} />
                    <FormControlLabel label="3" labelPlacement="top" control={<Radio checked={this.state.currentRating.risk === '3'} value={3} onChange={this.handleRatingChange('risk')} color="primary" />} />
                    <FormControlLabel label="4" labelPlacement="top" control={<Radio checked={this.state.currentRating.risk === '4'} value={4} onChange={this.handleRatingChange('risk')} color="primary" />} />
                    <FormControlLabel label="5" labelPlacement="top" control={<Radio checked={this.state.currentRating.risk === '5'} value={5} onChange={this.handleRatingChange('risk')} color="primary" />} />
                  </RadioGroup>
                  </TableCell>
                </TableRow>                                                
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRatingClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              disabled={ this.state.currentRating.priority === 0 || this.state.currentRating.complexity === 0 || this.state.currentRating.shortage === 0 || this.state.currentRating.singularity === 0 || this.state.currentRating.applicability === 0 || this.state.currentRating.risk === 0 }
              onClick={this.handleSave}
              color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        { this.state.lines.map(({ id, oid, title, description, domains }) => (
        <ExpansionPanel key={oid} square expanded={this.state.expanded === 'panel' + id } onChange={event => this.handleChange('panel' + id)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={"panel" + id +"bh-content"}
            id={"panel" + id +"bh-header"}
          >
            <BusinessCenterIcon /><Typography style={{ marginLeft: 12 }}>{ title }</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            
              <Grid container spacing={0} alignItems="center">

                <Grid item>
                  <Typography>{ 'Dominios de Conocimiento' }</Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={<FormattedMessage id="domains.add" />} placement={'right'}>
                    <IconButton edge="end" aria-label={<FormattedMessage id="domains.add" />} onClick={event => this.handleClickDomainOpen(oid)}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <List style={{ width: '100%' }}>
                  { domains.map(({ _id, title, description }) => (
                    <React.Fragment key={title}>
                    <ListItem>
                      <ListItemIcon>
                        <RadioButtonUncheckedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={title}
                        secondary={description}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title={'Valorar'} placement={'top'}>
                          <IconButton edge="end" size="small" aria-label={'Valorar'} onClick={event => this.handleRatingClickOpen(_id.$oid, 'Line', oid)}>
                            <CheckCircleIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id="edit" />} placement={'top'}>
                          <IconButton edge="end" size="small" aria-label={<FormattedMessage id="edit" />} onClick={event => this.handleDomainEditClickOpen(_id.$oid, title, description, oid)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id="delete" />} placement={'top'}>
                          <IconButton edge="end" size="small" aria-label={<FormattedMessage id="delete" />} onClick={event => this.openDelDomainDialog(_id.$oid, oid)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    
                    </React.Fragment>
                  ))}
                </List>

              </Grid>
            
              <div style={{ width: '100%', borderTop: '1px solid rgba(0, 0, 0, .125)' }}>
              <Button style={{ margin: 10, color:"rgba(0, 0, 0, .4)" }} onClick={event => this.handleLineEditClickOpen(oid, title, description)}>
                <EditIcon fontSize="small" />
                EDITAR LÍNEA
              </Button>
              <Button style={{ margin: 10, color:"rgba(0, 0, 0, .4)" }} onClick={this.openDelDialog}>
                <DeleteIcon fontSize="small" />
                ELIMINAR LÍNEA
              </Button>
            </div>

          </ExpansionPanelDetails>
        </ExpansionPanel>
        ))}

      </React.Fragment>
    );
  }
}

export default ExpansionLinesDomains;
/* eslint-enable no-unused-vars */