import auth from '../Auth';

class ReferenceModel {
  constructor() {
    this.getList = this.getList.bind(this)
    this.search = this.search.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(cb) {
    return fetch(process.env.REACT_APP_DEV_API_URL + 'references/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;
    }).catch(error => console.log(error) );
  }

  search(q, cb) {
    return fetch(process.env.REACT_APP_DEV_API_URL + 'references/search/' + q, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;
    }).catch(error => console.log(error) );
  }

  async create(reference, cb) {
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'references', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          reference
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async update(id, reference, cb) {
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'references', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, reference
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  show(id, cb) {
    return fetch(process.env.REACT_APP_DEV_API_URL + 'references/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;
    }).catch(error => console.log(error) );
  }

  edit(id, cb) {
    return fetch(process.env.REACT_APP_DEV_API_URL + 'references/' + id + '/edit', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;
    }).catch(error => console.log(error) );
  }

  getPDF(id, cb) {
    return fetch(process.env.REACT_APP_DEV_API_URL + 'references/' + id + '/pdf', {
      headers: {
        'Accept': 'application/pdf',
        'Content-Type': 'application/pdf',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    })
    .then(function(data) {
      return data;
    }).catch(error => console.log(error) );
  }

  delete(ids, cb) {
    return fetch(process.env.REACT_APP_DEV_API_URL + 'references', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
        reference: { ids: ids }
      })
    }).then(res => {  }).catch(error => console.log(error) );
  }

}

export default new ReferenceModel();
