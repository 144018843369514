import React, { Component } from 'react';
import { MakeContext } from '../MakeProvider';
import classNames from 'classnames';
import PropTypes from 'prop-types';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
//import LockIcon from '@material-ui/icons/LockOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import withStyles from '@material-ui/core/styles/withStyles';
import { FormattedMessage } from 'react-intl';
import auth from '../Auth'

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(1) * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  palette: {
    primary: '#000',
  },
  paper: {
    marginTop: theme.spacing(1) * 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1) * 3,
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      elevation={0}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

class SignIn extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      emailMessage: false,
      passwordMessage: false,
      open: false,
    }
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  } 

  handleEmailChange(event) {
    this.setState({
        email: event.target.value,
        emailMessage: false
    })
  }

  handlePasswordChange(event) {
    this.setState({
        password: event.target.value,
        passwordMessage: false
    })
  }

  validateForm() {
    if (this.state.email === '') {
      this.setState({
        emailMessage: true
      })
      return false
    }
    if (this.state.password === '') {
      this.setState({
        passwordMessage: true
      })
      return false
    }
    if (this.state.email !== '' && this.state.password !== '') { return true }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      auth.login(this.state.email, this.state.password, (res) => {
        if (res.status === 201) {
          this.context.updateUser(auth.getProfile());
          this.props.history.push("/");
        } else {
          this.setState({ open: true });
        }
      })
    }
  }

  

  render() {

    const { classes } = this.props;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Typography component="h2" gutterBottom style={{ marginTop: 30, backgroundImage: 'url(/make.png)', backgroundRepeat: 'no-repeat', backgroundSize: 92, backgroundPosition: 'center', height: 180 }}></Typography>
        <Paper className={classes.paper} elevation={0}>
{/*           <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar> */}
          <Typography component="h2" variant="h6" style={{ marginTop: 14 }}>
            <FormattedMessage id="signin" />
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            { this.state.open ?
            <MySnackbarContentWrapper
              onClose={this.handleClose}
              variant="warning"
              className={classes.margin}
              message="Nombre de usuario y/o contraseña invalidos!"
            />
            : null }
            <FormControl margin="normal" fullWidth error={this.state.emailMessage}>
              <InputLabel htmlFor="email"><FormattedMessage id="users.email" /></InputLabel>
              <Input id="email" autoComplete="email" value={this.state.email} onChange={this.handleEmailChange} aria-describedby="component-error-text" autoFocus />
              { this.state.emailMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <FormControl margin="normal" fullWidth error={this.state.passwordMessage}>
              <InputLabel htmlFor="password"><FormattedMessage id="users.password" /></InputLabel>
              <Input type="password" id="password" value={this.state.password} onChange={this.handlePasswordChange} aria-describedby="component-error-text" autoComplete="password" />
              { this.state.passwordMessage ? <FormHelperText id="component-error-text">Required field</FormHelperText> : null }
            </FormControl>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label={<FormattedMessage id="users.remember" />}
            />
            <Button
              type="submit"
              fullWidth
              className={classes.submit}
              elevation={0}
              variant="outlined"
            >
              <FormattedMessage id="signin" />
            </Button>
          </form><br />
          {/* <Typography className={classes.link} component="a" href="/signup">
            <FormattedMessage id="signup" />
          </Typography> */}
        </Paper>
      </main>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);