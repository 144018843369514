import React, { Component } from 'react';
import { MakeContext } from '../MakeProvider';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import auth from '../Auth';
import user from './User';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing.unit * 3,
  },
  buttonNew: {
    marginTop: '24px',
    marginBottom: '24px'
  }
});

class Profile extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      userData: { name: '', email: '' },
      openSnack: false,
    }
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  componentDidMount() {
    user.show(auth.getProfile().sub).then((result) =>
      this.setState({ userData: result })
    ); 
  }
  

  handleNameChange(event) {
    this.setState({
      userData: { ...this.state.userData, name: event.target.value }
    })
  }

  handleEmailChange(event) {
    this.setState({
      userData: { ...this.state.userData, email: event.target.value }
    })
  }

  handleSubmit(e) {
    e.preventDefault();
      user.updateName(auth.getProfile().sub, this.state.userData.name, () => {
        user.show(auth.getProfile().sub).then((result) => {
          this.setState({ userData: result, SnackMessage: <FormattedMessage id="profile.saved" />, openSnack: true })
          this.context.updateUser(result);
        }) 
      })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }
  
  render() {

    const { classes } = this.props;

    return (

      <div>
        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          <FormattedMessage id="profile.edit" />
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name"><FormattedMessage id="users.fullname" /></InputLabel>
            <Input id="name" value={this.state.userData.name} onChange={this.handleNameChange} />
          </FormControl>     
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email"><FormattedMessage id="users.email" /></InputLabel>
            <Input id="email" value={this.state.userData.email} onChange={this.handleEmailChange} disabled />
          </FormControl>
          <Button onClick={this.handleSubmit} color="primary" variant="outlined" style={{ marginTop: 20 }}>
              <FormattedMessage id="save" />
          </Button>
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={this.state.SnackMessage}
          />
        </Snackbar>    
      </div>

    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Profile);