/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import area from '../../areas/Area';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
  colOne: {
    border: '1px solid #cfd8dc', color: '#37474f', fontStyle: 'bold', fontSize: '17px', textAlign: 'left', paddingLeft: 55
  },
  colOneTotal: {
    backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0
  },
});

class CRR extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      currentTopic: '',
      currentRating: 0,
      ci: [],
      openPDFDialog: false,
      title: '',
      url: '',
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setUp = this.setUp.bind(this);
    this.handlePDFClickOpen = this.handlePDFClickOpen.bind(this);
    this.handlePDFClickClose = this.handlePDFClickClose.bind(this);
  } 

  componentDidMount() {
    this.setUp();
  } 

  UNSAFE_componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.areaId !== prevProps.areaId) {
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    area.getCi(this.props.areaId).then((result) =>
      this.setState({ ci: result })
    );
  }

  handleClickOpen(currentTopic) {
    let rating = 0;
    area.getCi(this.props.areaId).then((result) => {
      result.map((row) => {
        if (row.topic === currentTopic) {
          rating = row.civalue.toString();
        }
        return true;
      });
      this.setState({ openDialog: true, currentTopic: currentTopic, currentRating: rating });
    });
    
  }

  handleClickClose() {
    this.setState({ openDialog: false, currentTopic: '', currentRating: 0 });
    area.getCi(this.props.areaId).then((result) => {
      this.setState({ ci: result });
      this.forceUpdate();
    });
  }

  handleChange = (event) => {
      this.setState({ currentRating: event.target.value });
  }

  handleSave(e) {
    e.preventDefault();
    area.saveCi(this.props.areaId, 'CRR', this.state.currentTopic, this.state.currentRating).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="CRR.updated" />,
        openSnack: true
      });
      this.handleClickClose();
    })
  }

  makeItemValue(item) {

    var value = 0;

    this.state.ci.map((row) => {
      if (row.topic === item) {
        value = row.civalue;
      }
      return true
    });

    return value;

  }

  makeRatingResult(item) {

    var stat = { sum: 0, recommendation: '' }

    this.state.ci.map((row) => {
      if (row.topic.includes(item)) {
        stat.sum += row.civalue;
      }
      return true
    });

    if (stat.sum >= 0 && stat.sum <= 10) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_one'}
                              values={{ sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Apertura del I+D+i regional (educación, investigación, inversión)', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_14_Apert_I%2BD%2BiReg.pdf')}>MCIREG14</Link>
                              }} />;
    } else if (stat.sum > 10 && stat.sum <= 15) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Benchmarking, estancias, pasantías casos de referencia I+D+i internacionales', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_13_Bench_Estancias_Org_Ref_Inter_I%2BD%2Bi.pdf')}>MCIREG13</Link>,
                                sheetTwo: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Apertura del I+D+i regional (educación, investigación, inversión)', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_14_Apert_I%2BD%2BiReg.pdf')}>MCIREG14</Link>
                              }} />;
    } else if (stat.sum > 15 && stat.sum <= 20) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_three'}
                              values={{ sheetOne: <Link style={{ cursor: 'pointer' }}
                                onClick={event => this.handlePDFClickOpen('Benchmarking, estancias, pasantías casos de referencia I+D+i internacionales', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_13_Bench_Estancias_Org_Ref_Inter_I%2BD%2Bi.pdf')}>MCIREG13</Link>
                              }} />;
    }

    return stat;    
  }

  makeRatingResultM(item) {

    var stat = { sum: 0, recommendation: '' }

    this.state.ci.map((row) => {
      if (row.topic.includes(item)) {
        stat.sum += row.civalue;
      }
      return true
    });

    if (stat.sum >= 0 && stat.sum <= 10) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_one'}
                              values={{ sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Promoción de casos de éxito I+D+i con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_16_Promoc_Casos_Exito_I%2BD%2Bi_PerfInternac.pdf')}>MCIREG16</Link>
                              }} />;
    } else if (stat.sum > 10 && stat.sum <= 20) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Identificación de "burbujas de I+D+i" con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_15_Identif_Burbujas_I%2BD%2Bi_PerfInternac.pdf')}>MCIREG15</Link>,
                                sheetTwo: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Promoción de casos de éxito I+D+i con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_16_Promoc_Casos_Exito_I%2BD%2Bi_PerfInternac.pdf')}>MCIREG16</Link>
                              }} />;
    } else if (stat.sum > 20 && stat.sum <= 30) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_three'}
                              values={{ sheetOne: <Link style={{ cursor: 'pointer' }}
                                onClick={event => this.handlePDFClickOpen('Identificación de "burbujas de I+D+i" con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_15_Identif_Burbujas_I%2BD%2Bi_PerfInternac.pdf')}>MCIREG15</Link>
                              }} />;
    }

    return stat;    
  }

  makeRatingResultA(item) {

    var stat = { sum: 0, recommendation: '' }

    this.state.ci.map((row) => {
      if (row.topic.includes(item)) {
        stat.sum += row.civalue;
      }
      return true
    });

    if (stat.sum >= 0 && stat.sum <= 10) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_one'}
                              values={{ sheetOne: <Link style={{ cursor: 'pointer' }}
                                                    onClick={event => this.handlePDFClickOpen('Promoción de I+D+i privado', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_17_Promoc_I%2BD%2BiPriv.pdf')}>MCIREG17</Link>
                              }} />;
    } else if (stat.sum > 10 && stat.sum <= 20) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_two'}
                              values={{
                                sheetOne: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Promoción de I+D+i privado', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_17_Promoc_I%2BD%2BiPriv.pdf')}>MCIREG17</Link>,
                                sheetTwo: <Link style={{ cursor: 'pointer' }}
                                            onClick={event => this.handlePDFClickOpen('Posicionamiento internacional de liderazgo de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_18_Posic_Inter_Liderazgo_I%2BD%2Bi.pdf')}>MCIREG18</Link>
                              }} />;
    } else if (stat.sum > 20 && stat.sum <= 30) {
      stat.recommendation = <FormattedMessage id={item + '_interpretation_three'}
                              values={{ sheetOne: <Link style={{ cursor: 'pointer' }}
                                onClick={event => this.handlePDFClickOpen('Posicionamiento internacional de liderazgo de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_18_Posic_Inter_Liderazgo_I%2BD%2Bi.pdf')}>MCIREG18</Link>
                              }} />;
    }

    return stat;    
  }

  makeRatingTotal(item) {

    var sum = 0;
    var recommendation = '';

    this.state.ci.map((row) => {
      if (row.topic.includes(item)) {
        sum += row.civalue;
      }
      return true
    });

    if (sum >= 0 && sum <= 30) {
      recommendation = <FormattedMessage id={item + '.recommendations_one'}
                        values={{
                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Apertura del I+D+i regional (educación, investigación, inversión)', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_14_Apert_I%2BD%2BiReg.pdf')}>MCIREG14</Link>,
                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Promoción de casos de éxito I+D+i con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_16_Promoc_Casos_Exito_I%2BD%2Bi_PerfInternac.pdf')}>MCIREG16</Link>,
                          sheetThree: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Promoción de I+D+i privado', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_17_Promoc_I%2BD%2BiPriv.pdf')}>MCIREG17</Link>
                        }} />;
    } else if (sum > 30 && sum <= 60) {
      recommendation = <FormattedMessage id={item + '.recommendations_two'}
                        values={{
                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Benchmarking, estancias, pasantías casos de referencia I+D+i internacionales', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_13_Bench_Estancias_Org_Ref_Inter_I%2BD%2Bi.pdf')}>MCIREG13</Link>,
                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Apertura del I+D+i regional (educación, investigación, inversión)', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_14_Apert_I%2BD%2BiReg.pdf')}>MCIREG14</Link>,
                          sheetThree: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Identificación de "burbujas de I+D+i" con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_15_Identif_Burbujas_I%2BD%2Bi_PerfInternac.pdf')}>MCIREG15</Link>,
                          sheetFour: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Promoción de casos de éxito I+D+i con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_16_Promoc_Casos_Exito_I%2BD%2Bi_PerfInternac.pdf')}>MCIREG16</Link>,
                          sheetFive: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Promoción de I+D+i privado', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_17_Promoc_I%2BD%2BiPriv.pdf')}>MCIREG17</Link>,
                          sheetSix: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Posicionamiento internacional de liderazgo de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_18_Posic_Inter_Liderazgo_I%2BD%2Bi.pdf')}>MCIREG18</Link>,                                      
                        }} />;
    } else if (sum > 60 && sum <= 80) {
      recommendation = <FormattedMessage id={item + '.recommendations_three'}
                        values={{
                          sheetOne: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Benchmarking, estancias, pasantías casos de referencia I+D+i internacionales', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_13_Bench_Estancias_Org_Ref_Inter_I%2BD%2Bi.pdf')}>MCIREG13</Link>,
                          sheetTwo: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Identificación de "burbujas de I+D+i" con perfil internacional', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_15_Identif_Burbujas_I%2BD%2Bi_PerfInternac.pdf')}>MCIREG15</Link>,
                          sheetThree: <Link style={{ cursor: 'pointer' }}
                                      onClick={event => this.handlePDFClickOpen('Posicionamiento internacional de liderazgo de I+D+i', 'https://kitmake.ica2.com/resources/ic/ficha_MCIREG_18_Posic_Inter_Liderazgo_I%2BD%2Bi.pdf')}>MCIREG18</Link>
                        }} />;
    }

    return recommendation;    
  }

  handlePDFClickOpen(title, url) {
    this.setState({ openPDFDialog: true, title: title, url: url });
  }

  handlePDFClickClose() {
    this.setState({ openPDFDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

      <Typography variant="h6" className={classes.subTitle} gutterBottom>
        { 'Valoración y Análisis del Capital Relacional Regional' }
      </Typography>

      <Dialog
        fullWidth={true}
        open={this.state.openPDFDialog}
        fullScreen={true}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.dialogStyle
        }}
      >
        <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handlePDFClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
        <DialogContent>
            <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={this.state.openDialog}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.dialogStyle
        }}
      >
      <DialogTitle id="form-dialog-title">Valoración</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic } /> : null }</b>
            <br />{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_desc' } /> : null }
          </DialogContentText>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell><b>{ 'Valoración'}</b></TableCell>
                <TableCell style={ { width: '30%' } }>
                <RadioGroup row>
                  <Radio checked={this.state.currentRating === '1'} value={1} onChange={this.handleChange} color="primary" />
                  <Radio checked={this.state.currentRating === '2'} value={2} onChange={this.handleChange} color="primary" />
                  <Radio checked={this.state.currentRating === '3'} value={3} onChange={this.handleChange} color="primary" />
                  <Radio checked={this.state.currentRating === '4'} value={4} onChange={this.handleChange} color="primary" />
                  <Radio checked={this.state.currentRating === '5'} value={5} onChange={this.handleChange} color="primary" />
                </RadioGroup>
                </TableCell>
              </TableRow>                                                
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClickClose} color="primary">
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            disabled={ this.state.currentRating === 0 }
            onClick={this.handleSave}
            color="primary">
            <FormattedMessage id="save" />
          </Button>
        </DialogActions>
      </Dialog>

      <Paper className={classes.root} square={true} elevation={0}>
        <Table className={classes.table}>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}></TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Valoración' }</TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ ' Interpretación' }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}><FormattedMessage id="CRR.title" /></TableCell>
              <TableCell colSpan={2} style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}><FormattedMessage id="CRR.description" /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ backgroundColor: '#e0e0e0', border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.basic_stats_title" /></TableCell>
              <TableCell colSpan={2} style={{ backgroundColor: '#e0e0e0', border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.basic_stats_description" /></TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell onClick={event => this.handleClickOpen('CRR.basic_stats_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="CRR.basic_stats_one" /></TableCell>
              <TableCell onClick={event => this.handleClickOpen('CRR.basic_stats_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.basic_stats_one') }</TableCell>
              <TableCell rowSpan={4} style={{ backgroundColor: '#FFF', border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>{ this.makeRatingResult('CRR.basic_stats').recommendation }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.basic_stats_two')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.basic_stats_two" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.basic_stats_two') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.basic_stats_three')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.basic_stats_three" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.basic_stats_three') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.basic_stats_four')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.basic_stats_four" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.basic_stats_four') }</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ backgroundColor: '#90caf9', border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.medium_stats_title" /></TableCell>
              <TableCell colSpan={2} style={{ backgroundColor: '#90caf9', border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.medium_stats_description" /></TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell onClick={event => this.handleClickOpen('CRR.medium_stats_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="CRR.medium_stats_one" /></TableCell>
              <TableCell onClick={event => this.handleClickOpen('CRR.medium_stats_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.medium_stats_one') }</TableCell>
              <TableCell rowSpan={6} style={{ backgroundColor: '#FFF', border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>{ this.makeRatingResultM('CRR.medium_stats').recommendation }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.medium_stats_two')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.medium_stats_two" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.medium_stats_two') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.medium_stats_three')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.medium_stats_three" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.medium_stats_three') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.medium_stats_four')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.medium_stats_four" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.medium_stats_four') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.medium_stats_five')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.medium_stats_five" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.medium_stats_five') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.medium_stats_six')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.medium_stats_six" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.medium_stats_six') }</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ backgroundColor: '#ffd54f', border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.advanced_stats_title" /></TableCell>
              <TableCell colSpan={2} style={{ backgroundColor: '#ffd54f', border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.advanced_stats_description" /></TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell onClick={event => this.handleClickOpen('CRR.advanced_stats_one')} style={{ border: '1px solid #cfd8dc', color: '#37474f', cursor: 'pointer' }} align={'left'}><FormattedMessage id="CRR.advanced_stats_one" /></TableCell>
              <TableCell onClick={event => this.handleClickOpen('CRR.advanced_stats_one')} style={{ cursor: 'pointer' }} className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.advanced_stats_one') }</TableCell>
              <TableCell rowSpan={6} style={{ backgroundColor: '#FFF', border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top' }} align={'left'}>{ this.makeRatingResultA('CRR.advanced_stats').recommendation }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.advanced_stats_two')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.advanced_stats_two" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.advanced_stats_two') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.advanced_stats_three')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.advanced_stats_three" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.advanced_stats_three') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.advanced_stats_four')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.advanced_stats_four" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.advanced_stats_four') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.advanced_stats_five')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.advanced_stats_five" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.advanced_stats_five') }</TableCell>
            </TableRow>
            <TableRow onClick={event => this.handleClickOpen('CRR.advanced_stats_six')} style={{ cursor: 'pointer' }} hover>
              <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}><FormattedMessage id="CRR.advanced_stats_six" /></TableCell>
              <TableCell className={ classes.colOne } align={'left'}>{ this.makeItemValue('CRR.advanced_stats_six') }</TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '27%' }} align={'left'}></TableCell>
              <TableCell className={ classes.colOneTotal } align={'left'}>{ this.makeRatingResult('CRR.basic_stats').sum +this.makeRatingResultM('CRR.medium_stats').sum + this.makeRatingResultA('CRR.advanced_stats').sum }</TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ this.makeRatingTotal('CRR') }</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </Paper>

      </React.Fragment>
    );
  }
}

CRR.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CRR);
/* eslint-enable no-unused-vars */