/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../../MakeProvider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage } from 'react-intl';
import Link from '@material-ui/core/Link';
import area from '../../areas/Area';
import activity from './activities/Activity';
import line from './lines/Line';
import domain from './domains/Domain';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    width: '30%',
    marginTop: theme.spacing(3),
  },
  buttonNew: {
    marginTop: '24px',
  },
  subTitle: {
    marginTop: '24px',
    marginBottom: '20px'    
  },
  dialogStyle: {
    borderRadius: 0
  },
  selectControl: {
    width: '100%',
  },
  secondaryBar: {
    zIndex: 1,
  },
  colOne: {
    border: '1px solid #cfd8dc', color: '#37474f', fontStyle: 'bold', fontSize: '17px', textAlign: 'center', padding: 0
  },
  colTwo: {
    border: '1px solid #cfd8dc', color: '#37474f', verticalAlign: 'top'
  }
});

let counter = 0;
function createData(oid, title, description, domains, created_at) {
  counter += 1;
  return { id: counter, oid, title, description, domains, created_at };
}

class ModelZero extends Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      openDialog: false,
      currentTopic: '',
      currentRating: { relevance: 0, specificity: 0, control: 0, impacts: 0 },
      activities: [],
      lines: [],
      openPDFDialog: false,
      title: '',
      url: '',
    }
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setUp = this.setUp.bind(this);
    this.handlePDFClickOpen = this.handlePDFClickOpen.bind(this);
    this.handlePDFClickClose = this.handlePDFClickClose.bind(this);
  } 

  componentDidMount() {
    this.setUp();
  } 

  componentWillReceiveProps(prevProps, nextProps) {
    if (this.props.areaId !== prevProps.areaId) {
      setTimeout(function(){
        this.setUp();
      }.bind(this), 300);  
    }
  }

  setUp() {
    activity.getList(this.props.areaId).then((result) =>
      this.setState(data => ({ activities: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)) }))
    );
    line.getList(this.props.areaId).then((result) =>
      this.setState(data => ({ lines: result.map(n => createData(n._id.$oid, n.title, n.description, n.domains, n.created_at)) }))
    );
  }

  handleClickOpen(currentTopic) {
    let rating = { relevance: 0, specificity: 0, control: 0, impacts: 0 };
    area.getMaturity(this.props.areaId).then((result) => {
      result.map((row) => {
        if (row.topic === currentTopic) {
          rating = { relevance: row.relevance.toString(), specificity: row.specificity.toString(), control: row.control.toString(), impacts: row.impacts.toString() }
        }
        return true;
      });
      this.setState({ openDialog: true, currentTopic: currentTopic, currentRating: rating });
    });
    
  }

  handleClickClose() {
    this.setState({ openDialog: false, currentTopic: '', currentRating: { relevance: 0, specificity: 0, control: 0, impacts: 0 } });
    area.getMaturity(this.props.areaId).then((result) => {
      this.setState({ maturity: result });
      this.forceUpdate();
    });
  }

  handleChange = (criteria) => (event) => {
    switch (criteria) {
      case 'relevance':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, relevance: event.target.value } });
        break;
      case 'specificity':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, specificity: event.target.value } });
        break;
      case 'control':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, control: event.target.value } });
        break;
      case 'impacts':
        this.setState({ openDialog: true, currentRating: { ...this.state.currentRating, impacts: event.target.value } });
        break;
      default:
        break;
    }
  }

  handleSave(e) {
    e.preventDefault();
    area.saveMaturity(this.props.areaId, this.state.currentTopic, this.state.currentRating).then(() => {
      this.setState({
        SnackMessage: <FormattedMessage id="maturity.updated" />,
        openSnack: true
      });
      this.handleClickClose();
    })
  }

  makeResultActivities(sum) {

    var result = { interpretation: '', recommendation: '' };

    if (sum >= 0 && sum <= 15) {

      result.interpretation = <FormattedMessage id={'domains.interpretation_one_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO001', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO001.pdf')}>MAPCO001</Link>
                                }} />;

      result.recommendation = <FormattedMessage id={'domains.recommendation_one_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO001', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO001.pdf')}>MAPCO001</Link>
                                }} />;

    } else if (sum > 15 && sum <= 25) {

      result.interpretation = <FormattedMessage id={'domains.interpretation_two_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO002', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO002.pdf')}>MAPCO002</Link>
                                }} />;

      result.recommendation = <FormattedMessage id={'domains.recommendation_two_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO002', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO002.pdf')}>MAPCO002</Link>
                                }} />;

    } else if (sum > 25 && sum <= 35) {

      result.interpretation = <FormattedMessage id={'domains.interpretation_three_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO003', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO003.pdf')}>MAPCO003</Link>
                                }} />;

      result.recommendation = <FormattedMessage id={'domains.recommendation_three_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO003', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO003.pdf')}>MAPCO003</Link>
                                }} />;

    }

    return result;
  }

  makeResultLines(sum) {

    var result = { interpretation: '', recommendation: '' };

    if (sum >= 0 && sum <= 15) {

      result.interpretation = <FormattedMessage id={'domains.interpretation_one_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO004', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO004.pdf')}>MAPCO004</Link>
                                }} />;

      result.recommendation = <FormattedMessage id={'domains.recommendation_one_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO004', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO004.pdf')}>MAPCO004</Link>
                                }} />;

    } else if (sum > 15 && sum <= 25) {

      result.interpretation = <FormattedMessage id={'domains.interpretation_two_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO005', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO005.pdf')}>MAPCO005</Link>
                                }} />;

      result.recommendation = <FormattedMessage id={'domains.recommendation_two_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO005', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO005.pdf')}>MAPCO005</Link>
                                }} />;

    } else if (sum > 25 && sum <= 35) {

      result.interpretation = <FormattedMessage id={'domains.interpretation_three_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO006', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO006.pdf')}>MAPCO006</Link>
                                }} />;

      result.recommendation = <FormattedMessage id={'domains.recommendation_three_lz'}
                                values={{
                                  sheetOne: <Link style={{ cursor: 'pointer' }}
                                              onClick={event => this.handlePDFClickOpen('MAPCO006', 'https://kitmake.ica2.com/resources/zero/km/ficha_MAPCO006.pdf')}>MAPCO006</Link>
                                }} />;

    }

    return result;
  }

  handlePDFClickOpen(title, url) {
    this.setState({ openPDFDialog: true, title: title, url: url });
  }

  handlePDFClickClose() {
    this.setState({ openPDFDialog: false, title: '', url: '' });
  }

  render() {

    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>

        <Typography variant="h6" className={classes.subTitle} gutterBottom>
          { 'Mapa de Conocimiento' }
        </Typography>

        <Dialog
          fullWidth={true}
          open={this.state.openPDFDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.title }<Button onClick={this.handlePDFClickClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
              <embed src={ this.state.url } width="100%" height="100%" type="application/pdf"></embed>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Valoración</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_title' } /> : null }</b>
              <br />{ this.state.currentTopic ? <FormattedMessage id={ this.state.currentTopic + '_desc' } /> : null }
            </DialogContentText>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell><b>{ 'Relevancia'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '40%' } }>
                  <RadioGroup row>
                    <Radio checked={this.state.currentRating.relevance === '1'} value={1} onChange={this.handleChange('relevance')} color="primary" />
                    <Radio checked={this.state.currentRating.relevance === '2'} value={2} onChange={this.handleChange('relevance')} color="primary" />
                    <Radio checked={this.state.currentRating.relevance === '3'} value={3} onChange={this.handleChange('relevance')} color="primary" />
                    <Radio checked={this.state.currentRating.relevance === '4'} value={4} onChange={this.handleChange('relevance')} color="primary" />
                    <Radio checked={this.state.currentRating.relevance === '5'} value={5} onChange={this.handleChange('relevance')} color="primary" />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Especificidad'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '40%' } }>
                  <RadioGroup row>
                    <Radio checked={this.state.currentRating.specificity === '1'} value={1} onChange={this.handleChange('specificity')} color="primary" />
                    <Radio checked={this.state.currentRating.specificity === '2'} value={2} onChange={this.handleChange('specificity')} color="primary" />
                    <Radio checked={this.state.currentRating.specificity === '3'} value={3} onChange={this.handleChange('specificity')} color="primary" />
                    <Radio checked={this.state.currentRating.specificity === '4'} value={4} onChange={this.handleChange('specificity')} color="primary" />
                    <Radio checked={this.state.currentRating.specificity === '5'} value={5} onChange={this.handleChange('specificity')} color="primary" />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Control'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '40%' } }>
                  <RadioGroup row>
                    <Radio checked={this.state.currentRating.control === '1'} value={1} onChange={this.handleChange('control')} color="primary" />
                    <Radio checked={this.state.currentRating.control === '2'} value={2} onChange={this.handleChange('control')} color="primary" />
                    <Radio checked={this.state.currentRating.control === '3'} value={3} onChange={this.handleChange('control')} color="primary" />
                    <Radio checked={this.state.currentRating.control === '4'} value={4} onChange={this.handleChange('control')} color="primary" />
                    <Radio checked={this.state.currentRating.control === '5'} value={5} onChange={this.handleChange('control')} color="primary" />
                  </RadioGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><b>{ 'Impactos'}</b><br />{ 'Protagonismo de la GC o de la I+D+i en la estrategia regional.' } <br /> { ' Valoración 1 - 5 (1 protagonismo nulo, 5 protagonismo muy alto).' }</TableCell>
                  <TableCell style={ { width: '40%' } }>
                  <RadioGroup row>
                    <Radio checked={this.state.currentRating.impacts === '1'} value={1} onChange={this.handleChange('impacts')} color="primary" />
                    <Radio checked={this.state.currentRating.impacts === '2'} value={2} onChange={this.handleChange('impacts')} color="primary" />
                    <Radio checked={this.state.currentRating.impacts === '3'} value={3} onChange={this.handleChange('impacts')} color="primary" />
                    <Radio checked={this.state.currentRating.impacts === '4'} value={4} onChange={this.handleChange('impacts')} color="primary" />
                    <Radio checked={this.state.currentRating.impacts === '5'} value={5} onChange={this.handleChange('impacts')} color="primary" />
                  </RadioGroup>
                  </TableCell>
                </TableRow>                                                
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              disabled={ this.state.currentRating.relevance === 0 || this.state.currentRating.specificity === 0 || this.state.currentRating.control === 0 || this.state.currentRating.impacts === 0 }
              onClick={this.handleSave}
              color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>

            <TableRow>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '20%' }} align={'left'}></TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '2%' }} align={'left'}>{ ' Valoración' }</TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '39%' }} align={'left'}>{ ' Interpretación' }</TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '39%' }} align={'left'}>{ ' Recomendaciones' }</TableCell>
            </TableRow>

            { this.state.activities.map(({ id, oid, title, description, domains }) => (
              <React.Fragment>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ title }</TableCell>
                  <TableCell colSpan={3} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ description }</TableCell>
                </TableRow>

                { domains.map(({ _id, title, description, knowledge_level }) => (
                <TableRow>
                  <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>{ title }</TableCell>
                  <TableCell className={ classes.colOne } align={'left'}>
                    { knowledge_level ? (knowledge_level.priority + knowledge_level.complexity + knowledge_level.shortage + knowledge_level.singularity + knowledge_level.applicability + knowledge_level.risk) : 0 }
                  </TableCell>
                  <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResultActivities(knowledge_level ? (knowledge_level.priority + knowledge_level.complexity + knowledge_level.shortage + knowledge_level.singularity + knowledge_level.applicability + knowledge_level.risk) : 0).interpretation }</TableCell>
                  <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResultActivities(knowledge_level ? (knowledge_level.priority + knowledge_level.complexity + knowledge_level.shortage + knowledge_level.singularity + knowledge_level.applicability + knowledge_level.risk) : 0).recommendation }</TableCell>
                </TableRow>
                ))}
              </React.Fragment>
            ))}
            </TableBody>
          </Table>
        </Paper>

        <Paper className={classes.root} square={true} elevation={0}>
          <Table className={classes.table}>
            <TableBody>

            <TableRow>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '20%' }} align={'left'}></TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '2%' }} align={'left'}>{ ' Valoración' }</TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '39%' }} align={'left'}>{ ' Interpretación' }</TableCell>
              <TableCell style={{ backgroundColor: '#37474f', border: '1px solid #cfd8dc', color: '#FFF', width: '39%' }} align={'left'}>{ ' Recomendaciones' }</TableCell>
            </TableRow>

            { this.state.lines.map(({ id, oid, title, description, domains }) => (
              <React.Fragment>
                <TableRow>
                  <TableCell style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ title }</TableCell>
                  <TableCell colSpan={3} style={{ backgroundColor: '#2196f3', border: '1px solid #cfd8dc', color: '#FFF' }} align={'left'}>{ description }</TableCell>
                </TableRow>

                { domains.map(({ _id, title, description, knowledge_level }) => (
                <TableRow>
                  <TableCell style={{ border: '1px solid #cfd8dc', color: '#37474f' }} align={'left'}>{ title }</TableCell>
                  <TableCell className={ classes.colOne } align={'left'}>
                    { knowledge_level ? (knowledge_level.priority + knowledge_level.complexity + knowledge_level.shortage + knowledge_level.singularity + knowledge_level.applicability + knowledge_level.risk) : 0 }
                  </TableCell>
                  <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResultLines(knowledge_level ? (knowledge_level.priority + knowledge_level.complexity + knowledge_level.shortage + knowledge_level.singularity + knowledge_level.applicability + knowledge_level.risk) : 0).interpretation }</TableCell>
                  <TableCell className={ classes.colTwo } align={'left'}>{ this.makeResultLines(knowledge_level ? (knowledge_level.priority + knowledge_level.complexity + knowledge_level.shortage + knowledge_level.singularity + knowledge_level.applicability + knowledge_level.risk) : 0).recommendation }</TableCell>
                </TableRow>
                ))}
              </React.Fragment>
            ))}
            </TableBody>
          </Table>
        </Paper>

      </React.Fragment>
    );
  }
}

ModelZero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModelZero);
/* eslint-enable no-unused-vars */