import React from 'react';
import PropTypes from 'prop-types';
import { MakeContext } from '../MakeProvider';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import referenceModel from './ReferenceModel';
import { FormattedMessage } from 'react-intl';
//import MUIRichTextEditor from 'mui-rte';
//import FormLabel from '@material-ui/core/FormLabel';
//import { convertToRaw } from 'draft-js';

const styles = {
  card: {
    minWidth: 140,
    maxWidth: 280,
    minHeight: 230,
    maxHeight: 230,
    backgroundColor: '#e1f5fe'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  titleContents: {
    fontSize: 16,
    paddingTop: 120,
  },
  pos: {
    marginBottom: 12,
  },
  selectControl: {
    width: '100%',
    marginBottom: 24,
  },
  dialogStyle: {
    borderRadius: 0
  }
};

let counter = 0;
function createData(oid, identifier, title, level, concept, overall_objective, specific_objectives, keywords, technical_content, key_success_factors, references, created_at) {
  counter += 1;
  return { id: counter, oid, level, identifier, title, concept, overall_objective, specific_objectives, keywords, technical_content, key_success_factors, references, created_at };
}

class AreaForm extends React.Component{

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      fullWidth: true,
      maxWidth: 'lg',
      submitAction: props.submitAction,
      reference: props.reference,
      submitActionTitle: <FormattedMessage id="references.new" />,
    }
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleLevelChange = this.handleLevelChange.bind(this);
    this.handleConceptChange = this.handleConceptChange.bind(this);
    this.handleOverallObjectiveChange = this.handleOverallObjectiveChange.bind(this);
    this.handleSpecificObjectivesChange = this.handleSpecificObjectivesChange.bind(this);
    this.handleKeywordsChange = this.handleKeywordsChange.bind(this);
    this.handleTechnicalContentChange = this.handleTechnicalContentChange.bind(this);
    this.handleKeySuccessFactorsChange = this.handleKeySuccessFactorsChange.bind(this);
    this.handleReferencesChange = this.handleReferencesChange.bind(this);
    this.handleBullet = this.handleBullet.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  componentDidMount() {

  }

  handleIdentifierChange(event) {
    const reference = { ...this.state.reference, identifier: event.target.value }
    this.setState({ reference });
  }

  handleTitleChange(event) {
    const reference = { ...this.state.reference, title: event.target.value }
    this.setState({ reference });
  }

  handleLevelChange(event) {
    const reference = { ...this.state.reference, level: event.target.value }
    this.setState({ reference });
  }

  handleConceptChange(event) {
    const reference = { ...this.state.reference, concept: event.target.value }
    this.setState({ reference });
  }

  handleOverallObjectiveChange(event) {
    const reference = { ...this.state.reference, overall_objective: event.target.value }
    this.setState({ reference });
  }

  handleSpecificObjectivesChange(event) {
    const reference = { ...this.state.reference, specific_objectives: event.target.value }
    this.setState({ reference });
  }

  handleKeywordsChange(event) {
    const reference = { ...this.state.reference, keywords: event.target.value }
    this.setState({ reference });
  }

  handleTechnicalContentChange(event) {
    const reference = { ...this.state.reference, technical_content: event.target.value }
    this.setState({ reference });
  }

  handleKeySuccessFactorsChange(event) {
    const reference = { ...this.state.reference, key_success_factors: event.target.value }
    this.setState({ reference });
  }

  handleReferencesChange(event) {
    const reference = { ...this.state.reference, references: event.target.value }
    this.setState({ reference });
  }

  handleBullet = (event) => {
   
    const { keyCode, target } = event;
    let reference = null
    
    if (keyCode === 13) {

      switch (target.id) {
        case 'concept':
          reference = { ...this.state.reference, concept: target.value += '\u2022 ' }
          this.setState({ reference });
          break;
        case 'specific_objectives':
          reference = { ...this.state.reference, specific_objectives: target.value += '\u2022 ' }
          this.setState({ reference });
          break;
        case 'technical_content':
          reference = { ...this.state.reference, technical_content: target.value += '\u2022 ' }
          this.setState({ reference });
          break;
        case 'key_success_factors':
          reference = { ...this.state.reference, key_success_factors: target.value += '\u2022 ' }
          this.setState({ reference });
          break;
        default:
          break;
      }
      
    }
  }

  handleClick = (event, id, oid) => {
    this.props.history.push("/spaces/" + oid);
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitAction === 'new') {
      referenceModel.create(this.state.reference, () => {
        referenceModel.getList().then((result) => {
          this.setState(data => ({
              data: result.map(n => createData(n._id.$oid, n.identifier, n.title, n.level, n.concept, n.overall_objective, n.specific_objectives, n.keywords, n.technical_content, n.key_success_factors, n.references, n.created_at)),
              SnackMessage: <FormattedMessage id="references.saved" />,
              openSnack: true
            }));
            this.props.updateListAction();
            this.props.actionClose();
          }
        )
      })
    } else {
      referenceModel.update(this.state.reference.oid, this.state.reference, () => {
        referenceModel.getList().then((result) => {
          this.setState(data => ({
              data: result.map(n => createData(n._id.$oid, n.identifier, n.title, n.level, n.concept, n.overall_objective, n.specific_objectives, n.keywords, n.technical_content, n.key_success_factors, n.references, n.created_at)),
              SnackMessage: <FormattedMessage id="references.updated" />,
              openSnack: true
            }));
            this.props.updateListAction();
            this.props.actionClose();
          }
        )
      })
    }
  }

  render() {

    const { classes } = this.props;

    return (
      <div>
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.props.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">{this.props.submitActionTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              
            </DialogContentText>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="identifier"><FormattedMessage id="references.identifier" /></InputLabel>
                <Input id="identifier" value={this.state.reference.identifier} onChange={this.handleIdentifierChange} autoFocus />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="title"><FormattedMessage id="references.name" /></InputLabel>
                <Input id="title" value={this.state.reference.title} onChange={this.handleTitleChange} />
              </FormControl>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor="select-multiple"><FormattedMessage id="level" /></InputLabel>
                <Select
                  value={this.state.reference.level}
                  onChange={this.handleLevelChange}
                  input={<Input name="level" id="level-helper" />}
                  MenuProps={{ classes: { paper: classes.dialogStyle } }}
                >
                  <MenuItem key={0} value={0}>
                    {'Nivel 0'}
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    {'Nivel 1'}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="concept"><FormattedMessage id="references.concept" /></InputLabel>
                <Input id="concept" value={this.state.reference.concept} onChange={this.handleConceptChange} onKeyUp={this.handleBullet} multiline={true} rows="8" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="overall_objective"><FormattedMessage id="references.overall_objectives" /></InputLabel>
                <Input id="overall_objective" value={this.state.reference.overall_objective} onChange={this.handleOverallObjectiveChange} onKeyUp={this.handleBullet} multiline={true} rows="8" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="specific_objectives"><FormattedMessage id="references.specific_objectives" /></InputLabel>
                <Input id="specific_objectives" value={this.state.reference.specific_objectives} onChange={this.handleSpecificObjectivesChange} onKeyUp={this.handleBullet} multiline={true} rows="8" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="keywords"><FormattedMessage id="references.keywords" /></InputLabel>
                <Input id="keywords" value={this.state.reference.keywords} onChange={this.handleKeywordsChange} onKeyUp={this.handleBullet} multiline={true} rows="8" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="technical_content"><FormattedMessage id="references.technical_content" /></InputLabel>
                <Input id="technical_content" value={this.state.reference.technical_content} onChange={this.handleTechnicalContentChange} onKeyUp={this.handleBullet} multiline={true} rows="8" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="key_success_factors"><FormattedMessage id="references.key_success_factors" /></InputLabel>
                <Input id="key_success_factors" value={this.state.reference.key_success_factors} onChange={this.handleKeySuccessFactorsChange} onKeyUp={this.handleBullet} multiline={true} rows="8" />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="references"><FormattedMessage id="references.references" /></InputLabel>
                <Input id="references" value={this.state.reference.references} onChange={this.handleReferencesChange} onKeyUp={this.handleBullet} multiline={true} rows="8" />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.actionClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={this.state.SnackMessage}
          />
        </Snackbar>
      </div>
    );
  }
}

AreaForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AreaForm);
