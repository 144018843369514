import React, { Component } from 'react';
import auth from './Auth';

export const MakeContext = React.createContext();
export const MakeConsumer = MakeContext.Consumer

class MakeProvider extends Component {

  state = {
    userName: auth.loggedIn() ? auth.getProfile().name : '',
    userEmail: auth.loggedIn() ? auth.getProfile().email : '',
    userRoles: auth.loggedIn() ? auth.getProfile().role : '',
    areaList: false,
    updateUser: this.updateUser.bind(this),
    updateAreaList: this.updateAreaList.bind(this)
  }

  updateUser(UpdatedUser) {
    this.setState(prevState => ({ userName: UpdatedUser.name, userEmail: UpdatedUser.email, userRoles: UpdatedUser.role }))
  }

  updateAreaList(b) {
    this.setState(prevState => ({ spaceList: b }))
  }
  
  render () {
    return (
      <MakeContext.Provider value={this.state}>
        {this.props.children}
      </MakeContext.Provider>
    )
  }
}
export default MakeProvider