import auth from '../../../Auth';

class Domain {
  constructor() {
    this.getList = this.getList.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getSpaceList(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'areas/' + id + '/domains/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  getKMap(domainId, resource_type, resource_id, cb) {
    if (resource_type === 'Activity') {
      resource_type = 'activities/';
    } else if (resource_type) {
      resource_type = 'lines/';
    }
    return fetch(process.env.REACT_APP_DEV_API_URL + resource_type + resource_id + '/domains/' + domainId + '/kmap', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  async saveKMap(domainId, resource_type, resource_id, rating, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'kmap', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          domain: { domain_id: domainId, resource_type: resource_type, resource_id: resource_id, priority: rating.priority, complexity: rating.complexity, shortage: rating.shortage, singularity: rating.singularity, applicability: rating.applicability, risk: rating.risk }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  create(title, description, resource_type, resource_id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'POST',
      body: JSON.stringify({
        domain: { title: title, description: description, resource_type: resource_type, resource_id: resource_id }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

  async update(id, title, description, resource_type, resource_id, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'domains', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, domain: { title: title, description: description, resource_type: resource_type, resource_id: resource_id }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  show(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  delete(id, parent_type, parent_id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'domains', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
        id: id, domain: { parent_id: parent_id, parent_type: parent_type }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

}

export default new Domain();