import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MakeContext } from '../MakeProvider';
import { alpha, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import TextTruncate from 'react-text-truncate';
import { FormattedMessage, FormattedDate } from 'react-intl';
import ReferenceForm from './ReferenceForm';
import referenceModel from './ReferenceModel';
import { PDFViewer, Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

Font.registerHyphenationCallback(word => (
  [word]
));

Font.register({ family: 'Roboto', src: '/fonts/Roboto-Regular.ttf' });
Font.register({ family: 'Roboto', src: '/fonts/Roboto-Bold.ttf', fontWeight: 'bold' });

let counter = 0;
function createData(oid, identifier, title, level, concept, overall_objective, specific_objectives, keywords, technical_content, key_success_factors, references, created_at) {
  counter += 1;
  return { id: counter, oid, identifier, title, level, concept, overall_objective, specific_objectives, keywords, technical_content, key_success_factors, references, created_at };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'identifier', disablePadding: true, label: <FormattedMessage id="references.identifier" /> },
  { id: 'title', disablePadding: false, label: <FormattedMessage id="references.name" /> },
  { id: 'level', disablePadding: false, label: <FormattedMessage id="references.level" /> },
  { id: 'created_at', disablePadding: false, label: <FormattedMessage id="created" /> },
  { id: 'pdf', disablePadding: false, label: 'PDF' },
];

class EnhancedTableHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              checkedIcon={<CheckBoxIcon fontSize="small" />} icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            />
          </TableCell>
          {rows.map(row => {
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: lighten(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  tools: {
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'row'
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} seleccionado
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            <FormattedMessage id="references.list_title" />
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Eliminar">
            <IconButton aria-label="Eliminar" onClick={event => props.deleteAction()}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div className={classes.tools}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onKeyUp={props.searchAction}
            />
          </div>
          <Tooltip title={<FormattedMessage id="references.new" />}>
            <IconButton aria-label={<FormattedMessage id="references.new" />} onClick={props.action}>
              <AddIcon />
            </IconButton>
          </Tooltip>
          </div>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableCell: {
    cursor: 'pointer',
  },
  selectControl: {
    width: '100%',
  },
  dialogStyle: {
    borderRadius: 0
  }
});

const PDFstyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    paddingTop: 45,
    paddingBottom: 45,
    paddingHorizontal: 70,
  },
  textSub: {
    fontFamily: 'Roboto',
    fontSize: 12,
    marginBottom: 15,
    color: '#009be5',
    fontWeight: 'bold',
  },
  text: {
    fontSize: 12,
    marginBottom: 20,
    lineHeight: 1.5
  },
  textTools: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'right',
    marginBottom: 15,
    color: '#009be5',
  }
});

class EnhancedTable extends React.Component {

  static contextType = MakeContext;

  constructor (props) {
    super(props);
    this.state = {
      id: '',
      order: 'asc',
      orderBy: 'name',
      selected: [],
      OIDselected: [],
      data: [],
      page: 0,
      title: '',
      rowsPerPage: 10,
      fullWidth: true,
      maxWidth: 'sm',
      openDialog: false,
      openDelDialog: false,
      openPDFDialog: false,
      submitAction: 'new',
      submitActionTitle: <FormattedMessage id="categories.add" />,
      reference: { id: 0, oid: '', identifier: '', title: '', level: 0, concept: '\u2022 ', overall_objective: '', specific_objectives: '\u2022 ', keywords: '', technical_content: '\u2022 ', key_success_factors: '\u2022 ', references: '\u2022 ' }
    }
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickEditOpen = this.handleClickEditOpen.bind(this);
    this.handleClickEditClose = this.handleClickEditClose.bind(this);
    this.handleClickPDFOpen = this.handleClickPDFOpen.bind(this);
    this.handleClickPDFClose = this.handleClickPDFClose.bind(this);
    this.openDelDialog = this.openDelDialog.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDelClickClose = this.handleDelClickClose.bind(this)
    this.updateList = this.updateList.bind(this)
    this.searchReference = this.searchReference.bind(this)
  }

  componentDidMount() {
    referenceModel.getList().then((result) => {
      this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.identifier, n.title, n.level, n.concept, n.overall_objective, n.specific_objectives, n.keywords, n.technical_content, n.key_success_factors, n.references, n.created_at)) }))
    })
  }

  searchReference(event) {
    if (event.target.value.length > 0) {
      referenceModel.search(event.target.value).then((result) => {
          this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.identifier, n.title, n.level, n.concept, n.overall_objective, n.specific_objectives, n.keywords, n.technical_content, n.key_success_factors, n.references, n.created_at)) }))
        
      })
    } else {
      referenceModel.getList().then((result) => {
        this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.identifier, n.title, n.level, n.concept, n.overall_objective, n.specific_objectives, n.keywords, n.technical_content, n.key_success_factors, n.references, n.created_at)) }))
      })
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, oid) => {
    this.props.history.push("/areas/" + oid);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleCheck = (event, id, oid) => {
    const { selected, OIDselected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newOIDSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newOIDSelected = newOIDSelected.concat(OIDselected, oid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newOIDSelected = newOIDSelected.concat(OIDselected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newOIDSelected = newOIDSelected.concat(OIDselected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newOIDSelected = newOIDSelected.concat(
        OIDselected.slice(0, selectedIndex),
        OIDselected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected, OIDselected: newOIDSelected });
  }

  handleClickOpen() {

      this.setState(data => ({
        openDialog: true,
        submitAction: 'new',
        submitActionTitle: <FormattedMessage id="references.new" />,
      }))

  }

  handleClickEditOpen = (event, reference) => {
    this.setState(data => ({
      reference: reference,
      openDialog: true,
      submitAction: 'edit',
      submitActionTitle: <FormattedMessage id="references.edit" />,
    }))
}

  handleClickPDFOpen = (event, reference) => {
    //let pdf;
    //pdf = process.env.REACT_APP_DEV_API_URL + 'references/' + oid + '/pdf.pdf' //referenceModel.getPDF(oid)
    //console.log(convertFromRaw(JSON.parse(currentContent)))
    this.setState(data => ({ reference: reference, openPDFDialog: true }));

  }

  handleClickClose() {
    this.setState({ openDialog: false, reference: {} });
  }

  handleClickEditClose() {
    this.setState({ openDialog: false, reference: {} });
  }

  handleClickPDFClose() {
    this.setState({ openPDFDialog: false, reference: {} });
  }

  openDelDialog() {
    this.setState(data => ({ openDelDialog: true }));
  }

  openPDFDialog() {
    this.setState(data => ({ openPDFDialog: true }));
  }

  handleDelClickClose() {
    this.setState({ openDelDialog: false, selected: [], OIDselected: [] });
  }

  handleDelete = () => {
    referenceModel.delete(this.state.OIDselected).then((result) =>
      referenceModel.getList().then((result) => {
        this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.identifier, n.title, n.level, n.concept, n.overall_objective, n.specific_objectives, n.keywords, n.technical_content, n.key_success_factors, n.references, n.created_at)) }))
        this.handleDelClickClose();
      })
    );
  }

  updateList() {
    referenceModel.getList().then((result) => {
      this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.identifier, n.title, n.level, n.concept, n.overall_objective, n.specific_objectives, n.keywords, n.technical_content, n.key_success_factors, n.references, n.created_at)) }))

    })
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={classes.root} elevation={0} square={true}>
        <EnhancedTableToolbar numSelected={selected.length} action={this.handleClickOpen} deleteAction={this.openDelDialog} searchAction={this.searchReference} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      key={n.id}
                    >
                      <TableCell padding="checkbox" onClick={event => this.handleCheck(event, n.id, n.oid)} role="checkbox" aria-checked={isSelected} selected={isSelected}>
                        <Checkbox checked={isSelected} checkedIcon={<CheckBoxIcon fontSize="small" />} icon={<CheckBoxOutlineBlankIcon fontSize="small" />} />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none" onClick={event => this.handleClickEditOpen(event, n)} className={classes.tableCell}>
                        {n.identifier}
                      </TableCell>
                      <TableCell size="small" style={{width: '50%'}}>
                        <TextTruncate
                          line={1}
                          truncateText="…"
                          text={n.title}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none" onClick={event => this.handleClickEditOpen(event, n)} className={classes.tableCell}>
                        {n.level}
                      </TableCell>
                      <TableCell>{ <FormattedDate value={new Date(n.created_at)} year='numeric' month='long' day='2-digit' /> }</TableCell>
                      <TableCell onClick={event => this.handleClickPDFOpen(event, n)} className={classes.tableCell}>PDF</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={<FormattedMessage id="rows_per_page" />}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${ navigator.language === 'es-ES' ? 'de' : 'of' } ${count}`}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        
        {this.state.openDialog ?
        <ReferenceForm reference={this.state.reference} openDialog={this.state.openDialog} submitActionTitle={this.state.submitActionTitle} actionClose={this.handleClickClose} submitAction={this.state.submitAction} updateListAction={this.updateList} />
        : null }
        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.openDelDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Eliminar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>¿Estás seguro?</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleDelete} color="primary">
              <FormattedMessage id="delete" />
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.openPDFDialog ?
        <Dialog
          fullWidth={true}
          open={this.state.openPDFDialog}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
          <DialogTitle id="form-dialog-title">{ this.state.reference.title }<Button onClick={this.handleClickPDFClose} color="primary" align={'right'}>Cerrar</Button></DialogTitle>
          <DialogContent>
            <PDFViewer width='100%' height='100%'>
              <Document title={ this.state.reference.title }>
                <Page size="A4" style={PDFstyles.page} wrap={false}>
                    <Text style={PDFstyles.textTools}>Herramientas MAKE</Text>
                    <Text style={PDFstyles.textSub}>Título</Text>
                    <Text style={PDFstyles.text}>{this.state.reference.title}</Text>
                    <Text style={PDFstyles.textSub}>Concepto</Text>
                    <Text style={PDFstyles.text}>{this.state.reference.concept}</Text>
                    <Text style={PDFstyles.textSub}>Objetivo general</Text>
                    <Text style={PDFstyles.text}>{this.state.reference.overall_objective}</Text>
                    <Text style={PDFstyles.textSub}>Objetivos específicos</Text>
                    <Text style={PDFstyles.text}>{this.state.reference.specific_objectives}</Text>
                    <Text style={PDFstyles.textSub}>Palabras clave</Text>
                    <Text style={PDFstyles.text}>{this.state.reference.keywords}</Text>
                    <Text style={PDFstyles.textSub}>Contenido técnico</Text>
                    <Text style={PDFstyles.text}>{this.state.reference.technical_content}</Text>
                    <Text style={PDFstyles.textSub}>Factores clave de éxito</Text>
                    <Text style={PDFstyles.text}>{this.state.reference.key_success_factors}</Text>
                    <Text style={PDFstyles.textSub}>Referencias</Text>
                    <Text style={PDFstyles.text}>{this.state.reference.references}</Text>
                </Page>
              </Document>
            </PDFViewer>
          </DialogContent>
        </Dialog> : null }

      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
