/* eslint-disable no-unused-vars */
import React from 'react';
import { MakeContext } from '../MakeProvider';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GoIcon from '@material-ui/icons/ExitToApp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../layout/Snack';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import area from '../areas/Area';
import { FormattedMessage, FormattedDate } from 'react-intl';
import TextTruncate from 'react-text-truncate';
import { border } from '@material-ui/system';


const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: 20
  },
  orgCard: {
    minWidth: '50%',
  },
  organizationCard: {
    //borderBottom: '1px solid red'
  },
  card: {
    minWidth: 230,
    //maxWidth: 270,
    //minHeight: 220,
    //maxHeight: 270,
    backgroundColor: '#fff',
    border: '1px dashed gray'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  dialogStyle: {
    borderRadius: 0
  },
  nameLink: {
    cursor: 'pointer',
  },
  selectControl: {
    width: '100%',
  }
});

let counter = 0;
function createData(oid, title, description, country, level, created_at) {
  counter += 1;
  return { id: counter, oid, title, description, country, level, created_at };
}

const SECTORS = [
  { "id": "1", "name": "Tecnología (software)" },
  { "id": "2", "name": "Bienes de consumo" },
  { "id": "3", "name": "Entretenimiento/ Media" },
  { "id": "4", "name": "Servicios profesionales" },
  { "id": "5", "name": "Entidades gubernamentales" },
  { "id": "6", "name": "ONG" },
  { "id": "7", "name": "Servicios Financieros / Seguros" },
  { "id": "8", "name": "Telecomunicaicones" },
  { "id": "9", "name": "Energía" },
  { "id": "10", "name": "Aero/Auto/Transporte" },
  { "id": "11", "name": "Salud y Farmacéutica" },
  { "id": "12", "name": "Ingenieria / Construcción" },
];

const COUNTRIES = [
  {
    "code": "AF",
    "name": "Afghanistan"
  },
  {
    "code": "AX",
    "name": "Åland Islands"
  },
  {
    "code": "AL",
    "name": "Albania"
  },
  {
    "code": "DZ",
    "name": "Algeria"
  },
  {
    "code": "AS",
    "name": "American Samoa"
  },
  {
    "code": "AD",
    "name": "Andorra"
  },
  {
    "code": "AO",
    "name": "Angola"
  },
  {
    "code": "AI",
    "name": "Anguilla"
  },
  {
    "code": "AQ",
    "name": "Antarctica"
  },
  {
    "code": "AG",
    "name": "Antigua and Barbuda"
  },
  {
    "code": "AR",
    "name": "Argentina"
  },
  {
    "code": "AM",
    "name": "Armenia"
  },
  {
    "code": "AW",
    "name": "Aruba"
  },
  {
    "code": "AU",
    "name": "Australia"
  },
  {
    "code": "AT",
    "name": "Austria"
  },
  {
    "code": "AZ",
    "name": "Azerbaijan"
  },
  {
    "code": "BS",
    "name": "Bahamas"
  },
  {
    "code": "BH",
    "name": "Bahrain"
  },
  {
    "code": "BD",
    "name": "Bangladesh"
  },
  {
    "code": "BB",
    "name": "Barbados"
  },
  {
    "code": "BY",
    "name": "Belarus"
  },
  {
    "code": "BE",
    "name": "Belgium"
  },
  {
    "code": "BZ",
    "name": "Belize"
  },
  {
    "code": "BJ",
    "name": "Benin"
  },
  {
    "code": "BM",
    "name": "Bermuda"
  },
  {
    "code": "BT",
    "name": "Bhutan"
  },
  {
    "code": "BO",
    "name": "Bolivia, Plurinational State of"
  },
  {
    "code": "BQ",
    "name": "Bonaire, Sint Eustatius and Saba"
  },
  {
    "code": "BA",
    "name": "Bosnia and Herzegovina"
  },
  {
    "code": "BW",
    "name": "Botswana"
  },
  {
    "code": "BV",
    "name": "Bouvet Island"
  },
  {
    "code": "BR",
    "name": "Brazil"
  },
  {
    "code": "IO",
    "name": "British Indian Ocean Territory"
  },
  {
    "code": "BN",
    "name": "Brunei Darussalam"
  },
  {
    "code": "BG",
    "name": "Bulgaria"
  },
  {
    "code": "BF",
    "name": "Burkina Faso"
  },
  {
    "code": "BI",
    "name": "Burundi"
  },
  {
    "code": "KH",
    "name": "Cambodia"
  },
  {
    "code": "CM",
    "name": "Cameroon"
  },
  {
    "code": "CA",
    "name": "Canada"
  },
  {
    "code": "CV",
    "name": "Cape Verde"
  },
  {
    "code": "KY",
    "name": "Cayman Islands"
  },
  {
    "code": "CF",
    "name": "Central African Republic"
  },
  {
    "code": "TD",
    "name": "Chad"
  },
  {
    "code": "CL",
    "name": "Chile"
  },
  {
    "code": "CN",
    "name": "China"
  },
  {
    "code": "CX",
    "name": "Christmas Island"
  },
  {
    "code": "CC",
    "name": "Cocos (Keeling) Islands"
  },
  {
    "code": "CO",
    "name": "Colombia"
  },
  {
    "code": "KM",
    "name": "Comoros"
  },
  {
    "code": "CG",
    "name": "Congo"
  },
  {
    "code": "CD",
    "name": "Congo, the Democratic Republic of the"
  },
  {
    "code": "CK",
    "name": "Cook Islands"
  },
  {
    "code": "CR",
    "name": "Costa Rica"
  },
  {
    "code": "CI",
    "name": "Côte d'Ivoire"
  },
  {
    "code": "HR",
    "name": "Croatia"
  },
  {
    "code": "CU",
    "name": "Cuba"
  },
  {
    "code": "CW",
    "name": "Curaçao"
  },
  {
    "code": "CY",
    "name": "Cyprus"
  },
  {
    "code": "CZ",
    "name": "Czech Republic"
  },
  {
    "code": "DK",
    "name": "Denmark"
  },
  {
    "code": "DJ",
    "name": "Djibouti"
  },
  {
    "code": "DM",
    "name": "Dominica"
  },
  {
    "code": "DO",
    "name": "Dominican Republic"
  },
  {
    "code": "EC",
    "name": "Ecuador"
  },
  {
    "code": "EG",
    "name": "Egypt"
  },
  {
    "code": "SV",
    "name": "El Salvador"
  },
  {
    "code": "GQ",
    "name": "Equatorial Guinea"
  },
  {
    "code": "ER",
    "name": "Eritrea"
  },
  {
    "code": "EE",
    "name": "Estonia"
  },
  {
    "code": "ET",
    "name": "Ethiopia"
  },
  {
    "code": "FK",
    "name": "Falkland Islands (Malvinas)"
  },
  {
    "code": "FO",
    "name": "Faroe Islands"
  },
  {
    "code": "FJ",
    "name": "Fiji"
  },
  {
    "code": "FI",
    "name": "Finland"
  },
  {
    "code": "FR",
    "name": "France"
  },
  {
    "code": "GF",
    "name": "French Guiana"
  },
  {
    "code": "PF",
    "name": "French Polynesia"
  },
  {
    "code": "TF",
    "name": "French Southern Territories"
  },
  {
    "code": "GA",
    "name": "Gabon"
  },
  {
    "code": "GM",
    "name": "Gambia"
  },
  {
    "code": "GE",
    "name": "Georgia"
  },
  {
    "code": "DE",
    "name": "Germany"
  },
  {
    "code": "GH",
    "name": "Ghana"
  },
  {
    "code": "GI",
    "name": "Gibraltar"
  },
  {
    "code": "GR",
    "name": "Greece"
  },
  {
    "code": "GL",
    "name": "Greenland"
  },
  {
    "code": "GD",
    "name": "Grenada"
  },
  {
    "code": "GP",
    "name": "Guadeloupe"
  },
  {
    "code": "GU",
    "name": "Guam"
  },
  {
    "code": "GT",
    "name": "Guatemala"
  },
  {
    "code": "GG",
    "name": "Guernsey"
  },
  {
    "code": "GN",
    "name": "Guinea"
  },
  {
    "code": "GW",
    "name": "Guinea-Bissau"
  },
  {
    "code": "GY",
    "name": "Guyana"
  },
  {
    "code": "HT",
    "name": "Haiti"
  },
  {
    "code": "HM",
    "name": "Heard Island and McDonald Islands"
  },
  {
    "code": "VA",
    "name": "Holy See (Vatican City State)"
  },
  {
    "code": "HN",
    "name": "Honduras"
  },
  {
    "code": "HK",
    "name": "Hong Kong"
  },
  {
    "code": "HU",
    "name": "Hungary"
  },
  {
    "code": "IS",
    "name": "Iceland"
  },
  {
    "code": "IN",
    "name": "India"
  },
  {
    "code": "ID",
    "name": "Indonesia"
  },
  {
    "code": "IR",
    "name": "Iran, Islamic Republic of"
  },
  {
    "code": "IQ",
    "name": "Iraq"
  },
  {
    "code": "IE",
    "name": "Ireland"
  },
  {
    "code": "IM",
    "name": "Isle of Man"
  },
  {
    "code": "IL",
    "name": "Israel"
  },
  {
    "code": "IT",
    "name": "Italy"
  },
  {
    "code": "JM",
    "name": "Jamaica"
  },
  {
    "code": "JP",
    "name": "Japan"
  },
  {
    "code": "JE",
    "name": "Jersey"
  },
  {
    "code": "JO",
    "name": "Jordan"
  },
  {
    "code": "KZ",
    "name": "Kazakhstan"
  },
  {
    "code": "KE",
    "name": "Kenya"
  },
  {
    "code": "KI",
    "name": "Kiribati"
  },
  {
    "code": "KP",
    "name": "Korea, Democratic People's Republic of"
  },
  {
    "code": "KR",
    "name": "Korea, Republic of"
  },
  {
    "code": "KW",
    "name": "Kuwait"
  },
  {
    "code": "KG",
    "name": "Kyrgyzstan"
  },
  {
    "code": "LA",
    "name": "Lao People's Democratic Republic"
  },
  {
    "code": "LV",
    "name": "Latvia"
  },
  {
    "code": "LB",
    "name": "Lebanon"
  },
  {
    "code": "LS",
    "name": "Lesotho"
  },
  {
    "code": "LR",
    "name": "Liberia"
  },
  {
    "code": "LY",
    "name": "Libya"
  },
  {
    "code": "LI",
    "name": "Liechtenstein"
  },
  {
    "code": "LT",
    "name": "Lithuania"
  },
  {
    "code": "LU",
    "name": "Luxembourg"
  },
  {
    "code": "MO",
    "name": "Macao"
  },
  {
    "code": "MK",
    "name": "Macedonia, the Former Yugoslav Republic of"
  },
  {
    "code": "MG",
    "name": "Madagascar"
  },
  {
    "code": "MW",
    "name": "Malawi"
  },
  {
    "code": "MY",
    "name": "Malaysia"
  },
  {
    "code": "MV",
    "name": "Maldives"
  },
  {
    "code": "ML",
    "name": "Mali"
  },
  {
    "code": "MT",
    "name": "Malta"
  },
  {
    "code": "MH",
    "name": "Marshall Islands"
  },
  {
    "code": "MQ",
    "name": "Martinique"
  },
  {
    "code": "MR",
    "name": "Mauritania"
  },
  {
    "code": "MU",
    "name": "Mauritius"
  },
  {
    "code": "YT",
    "name": "Mayotte"
  },
  {
    "code": "MX",
    "name": "Mexico"
  },
  {
    "code": "FM",
    "name": "Micronesia, Federated States of"
  },
  {
    "code": "MD",
    "name": "Moldova, Republic of"
  },
  {
    "code": "MC",
    "name": "Monaco"
  },
  {
    "code": "MN",
    "name": "Mongolia"
  },
  {
    "code": "ME",
    "name": "Montenegro"
  },
  {
    "code": "MS",
    "name": "Montserrat"
  },
  {
    "code": "MA",
    "name": "Morocco"
  },
  {
    "code": "MZ",
    "name": "Mozambique"
  },
  {
    "code": "MM",
    "name": "Myanmar"
  },
  {
    "code": "NA",
    "name": "Namibia"
  },
  {
    "code": "NR",
    "name": "Nauru"
  },
  {
    "code": "NP",
    "name": "Nepal"
  },
  {
    "code": "NL",
    "name": "Netherlands"
  },
  {
    "code": "NC",
    "name": "New Caledonia"
  },
  {
    "code": "NZ",
    "name": "New Zealand"
  },
  {
    "code": "NI",
    "name": "Nicaragua"
  },
  {
    "code": "NE",
    "name": "Niger"
  },
  {
    "code": "NG",
    "name": "Nigeria"
  },
  {
    "code": "NU",
    "name": "Niue"
  },
  {
    "code": "NF",
    "name": "Norfolk Island"
  },
  {
    "code": "MP",
    "name": "Northern Mariana Islands"
  },
  {
    "code": "NO",
    "name": "Norway"
  },
  {
    "code": "OM",
    "name": "Oman"
  },
  {
    "code": "PK",
    "name": "Pakistan"
  },
  {
    "code": "PW",
    "name": "Palau"
  },
  {
    "code": "PS",
    "name": "Palestine, State of"
  },
  {
    "code": "PA",
    "name": "Panama"
  },
  {
    "code": "PG",
    "name": "Papua New Guinea"
  },
  {
    "code": "PY",
    "name": "Paraguay"
  },
  {
    "code": "PE",
    "name": "Peru"
  },
  {
    "code": "PH",
    "name": "Philippines"
  },
  {
    "code": "PN",
    "name": "Pitcairn"
  },
  {
    "code": "PL",
    "name": "Poland"
  },
  {
    "code": "PT",
    "name": "Portugal"
  },
  {
    "code": "PR",
    "name": "Puerto Rico"
  },
  {
    "code": "QA",
    "name": "Qatar"
  },
  {
    "code": "RE",
    "name": "Réunion"
  },
  {
    "code": "RO",
    "name": "Romania"
  },
  {
    "code": "RU",
    "name": "Russian Federation"
  },
  {
    "code": "RW",
    "name": "Rwanda"
  },
  {
    "code": "BL",
    "name": "Saint Barthélemy"
  },
  {
    "code": "SH",
    "name": "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    "code": "KN",
    "name": "Saint Kitts and Nevis"
  },
  {
    "code": "LC",
    "name": "Saint Lucia"
  },
  {
    "code": "MF",
    "name": "Saint Martin (French part)"
  },
  {
    "code": "PM",
    "name": "Saint Pierre and Miquelon"
  },
  {
    "code": "VC",
    "name": "Saint Vincent and the Grenadines"
  },
  {
    "code": "WS",
    "name": "Samoa"
  },
  {
    "code": "SM",
    "name": "San Marino"
  },
  {
    "code": "ST",
    "name": "Sao Tome and Principe"
  },
  {
    "code": "SA",
    "name": "Saudi Arabia"
  },
  {
    "code": "SN",
    "name": "Senegal"
  },
  {
    "code": "RS",
    "name": "Serbia"
  },
  {
    "code": "SC",
    "name": "Seychelles"
  },
  {
    "code": "SL",
    "name": "Sierra Leone"
  },
  {
    "code": "SG",
    "name": "Singapore"
  },
  {
    "code": "SX",
    "name": "Sint Maarten (Dutch part)"
  },
  {
    "code": "SK",
    "name": "Slovakia"
  },
  {
    "code": "SI",
    "name": "Slovenia"
  },
  {
    "code": "SB",
    "name": "Solomon Islands"
  },
  {
    "code": "SO",
    "name": "Somalia"
  },
  {
    "code": "ZA",
    "name": "South Africa"
  },
  {
    "code": "GS",
    "name": "South Georgia and the South Sandwich Islands"
  },
  {
    "code": "SS",
    "name": "South Sudan"
  },
  {
    "code": "ES",
    "name": "Spain"
  },
  {
    "code": "LK",
    "name": "Sri Lanka"
  },
  {
    "code": "SD",
    "name": "Sudan"
  },
  {
    "code": "SR",
    "name": "Suriname"
  },
  {
    "code": "SJ",
    "name": "Svalbard and Jan Mayen"
  },
  {
    "code": "SZ",
    "name": "Eswatini"
  },
  {
    "code": "SE",
    "name": "Sweden"
  },
  {
    "code": "CH",
    "name": "Switzerland"
  },
  {
    "code": "SY",
    "name": "Syrian Arab Republic"
  },
  {
    "code": "TW",
    "name": "Taiwan, Province of China"
  },
  {
    "code": "TJ",
    "name": "Tajikistan"
  },
  {
    "code": "TZ",
    "name": "Tanzania, United Republic of"
  },
  {
    "code": "TH",
    "name": "Thailand"
  },
  {
    "code": "TL",
    "name": "Timor-Leste"
  },
  {
    "code": "TG",
    "name": "Togo"
  },
  {
    "code": "TK",
    "name": "Tokelau"
  },
  {
    "code": "TO",
    "name": "Tonga"
  },
  {
    "code": "TT",
    "name": "Trinidad and Tobago"
  },
  {
    "code": "TN",
    "name": "Tunisia"
  },
  {
    "code": "TR",
    "name": "Turkey"
  },
  {
    "code": "TM",
    "name": "Turkmenistan"
  },
  {
    "code": "TC",
    "name": "Turks and Caicos Islands"
  },
  {
    "code": "TV",
    "name": "Tuvalu"
  },
  {
    "code": "UG",
    "name": "Uganda"
  },
  {
    "code": "UA",
    "name": "Ukraine"
  },
  {
    "code": "AE",
    "name": "United Arab Emirates"
  },
  {
    "code": "GB",
    "name": "United Kingdom"
  },
  {
    "code": "US",
    "name": "United States"
  },
  {
    "code": "UM",
    "name": "United States Minor Outlying Islands"
  },
  {
    "code": "UY",
    "name": "Uruguay"
  },
  {
    "code": "UZ",
    "name": "Uzbekistan"
  },
  {
    "code": "VU",
    "name": "Vanuatu"
  },
  {
    "code": "VE",
    "name": "Venezuela, Bolivarian Republic of"
  },
  {
    "code": "VN",
    "name": "Viet Nam"
  },
  {
    "code": "VG",
    "name": "Virgin Islands, British"
  },
  {
    "code": "VI",
    "name": "Virgin Islands, U.S."
  },
  {
    "code": "WF",
    "name": "Wallis and Futuna"
  },
  {
    "code": "EH",
    "name": "Western Sahara"
  },
  {
    "code": "YE",
    "name": "Yemen"
  },
  {
    "code": "ZM",
    "name": "Zambia"
  },
  {
    "code": "ZW",
    "name": "Zimbabwe"
  }
];

class AreaDash extends React.Component{

  static contextType = MakeContext;

  constructor (props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'name',
      selected: [],
      OIDselected: [],
      data: [],
      page: 0,
      title: '',
      level: 1,
      description: '',
      country: '',
      rowsPerPage: 5,
      fullWidth: true,
      maxWidth: 'sm',
      openDialog: false,
      submitAction: 'new',
      submitActionTitle: <FormattedMessage id="organizations.add" />,
      openDelDialog: false,
      toDel: ''
    }

    this.handleDelete = this.handleDelete.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleLevelChange = this.handleLevelChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.openDelDialog = this.openDelDialog.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDelClickClose = this.handleDelClickClose.bind(this);

  } 

  componentDidMount() {
    area.getList().then((result) =>
      this.setState(data => ({ data: result.map(n => createData(n._id.$oid, n.title, n.description, n.country, n.level, n.created_at)) }))
    )
  } 

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleLevelChange(event) {
    this.setState({ level: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleCountryChange(event) {
    this.setState({ country: event.target.value });
  }

  handleClickOpen() {
    this.setState({ openDialog: true, submitAction: 'new', submitActionTitle: <FormattedMessage id="areas.add" /> });
  }

  handleClickClose() {
    this.setState({ openDialog: false, title: '', description: '', country: '', level: 0 });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openSnack: false, SnackMessage: '' });
  }

  handleClick = (event, id, oid, title, description, country, level) => {
    if (this.context.userRoles.filter(role => (role.name === "admin")).length > 0) {
      this.setState({ openDialog: true, submitAction: 'edit', submitActionTitle: <FormattedMessage id="areas.editing" />, id: oid, title: title, description: description, country: country, level: level });
    } else {
      this.props.history.push('/spaces/' + oid)
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.submitAction === 'new') {
      area.create(this.state.title, this.state.description, this.state.country, this.state.level, () => {
        area.getList().then((result) => {
          this.setState(data => ({
              data: result.map(n => createData(n._id.$oid, n.title, n.description, n.country, n.level, n.created_at)),
              id: '',
              title: '',
              description: '',
              country: '',
              level: '',
              SnackMessage: <FormattedMessage id="areas.saved" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    } else {
      area.update(this.state.id, this.state.title, this.state.description, this.state.country, this.state.level, () => {
        area.getList().then((result) => {
          this.setState(data => ({
              data: result.map(n => createData(n._id.$oid, n.title, n.description, n.country, n.level, n.created_at)),
              id: '',
              title: '',
              description: '',
              country: '',
              level: '',
              SnackMessage: <FormattedMessage id="areas.updated" />,
              openSnack: true
            }));
            this.handleClickClose();
          }
        )
      })
    }
  }

  openDelDialog = (e, id) => {
    this.setState(data => ({ openDelDialog: true, toDel: id }));
  }

  handleDelClickClose() {
    this.setState({ openDelDialog: false });
  }

  handleDelete = (e, id) => {
    let ids = [];
    ids[0] = id;
    area.delete(ids).then((result) => {
      area.getList().then((result) => {
        this.setState(data => ({
            data: result.map(n => createData(n._id.$oid, n.title, n.description, n.country, n.level, n.created_at)),
            id: '',
            title: '',
            description: '',
            country: '',
            level: '',
            SnackMessage: <FormattedMessage id="areas.deleted" />,
            openSnack: true
          }));
          this.handleDelClickClose();
        }
      )
    })
  }

  render() {

    const { classes } = this.props;
    const { data } = this.state;

    return (
      <div>

          <Grid style={{ marginBottom: 14, marginLeft: 10, bottom: 0 }}>
            <Tooltip title={<FormattedMessage id="areas.add" />} placement={'right'}>
              <IconButton aria-label={<FormattedMessage id="areas.add" />} onClick={this.handleClickOpen}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid container spacing={4} alignItems="flex-start" className={classes.pos} alignContent="flex-start">
            {data.map((row, index) => (
            <Grid item key={row.id}>
              <Card elevation={0} square={true} className={classes.card}>
                <CardContent>
                  <Typography variant="h6" className={classes.nameLink} onClick={event => { /* this.context.switchArea(row); */ this.props.history.push("/areas/" + row.oid) } }>
                    <TextTruncate
                      line={1}
                      truncateText="…"
                      text={row.title}
                    />
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    <FormattedDate value={new Date(row.created_at)} year='numeric' month='long' day='2-digit' />
                  </Typography>
{/*                   <Typography component="span">
                    <TextTruncate
                      line={3}
                      truncateText="…"
                      text={row.description}
                    />
                  </Typography> */}
                </CardContent>
                <CardActions>
                  <Tooltip title={<FormattedMessage id="areas.edit" />}>
                    <IconButton aria-label={<FormattedMessage id="areas.edit" />} onClick={event => this.handleClick(event, row.id, row.oid, row.title, row.description, row.country, row.level)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip> 
                  <Tooltip title={<FormattedMessage id="areas.delete" />}>
                    <IconButton aria-label={<FormattedMessage id="areas.delete" />} onClick={event => this.openDelDialog(event, row.oid)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>                                    
                  <Tooltip title={<FormattedMessage id="switch" />}>
                    <IconButton aria-label={<FormattedMessage id="switch" />} onClick={event => { /* this.context.switchArea(row); */ this.props.history.push("/areas" + row.oid) } }>
                      <GoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
            ))}
          </Grid>          

        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.openDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">{this.state.submitActionTitle}</DialogTitle>
          <DialogContent>
            <form>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="title"><FormattedMessage id="areas.name" /></InputLabel>
                <Input id="title" value={this.state.title} onChange={this.handleTitleChange} autoFocus />
              </FormControl>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor="select-multiple"><FormattedMessage id="level" /></InputLabel>
                <Select
                  value={this.state.level}
                  onChange={this.handleLevelChange}
                  input={<Input name="level" id="level-helper" />}
                  MenuProps={{ classes: { paper: classes.dialogStyle } }}
                >
                  <MenuItem key={0} value={0}>
                    {'Nivel 0'}
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    {'Nivel 1'}
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="description"><FormattedMessage id="areas.description" /></InputLabel>
                <Input id="description" value={this.state.description} onChange={this.handleDescriptionChange} multiline={true} rows="4" />
              </FormControl>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor="select-multiple"><FormattedMessage id="country" /></InputLabel>
                <Select
                  value={this.state.country}
                  onChange={this.handleCountryChange}
                  input={<Input name="country" id="country-helper" />}
                  MenuProps={{ classes: { paper: classes.dialogStyle } }}
                >
                  {COUNTRIES.map(country => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>                        
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              <FormattedMessage id="save" />
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant="success"
            message={this.state.SnackMessage}
          />
        </Snackbar>

        <Dialog
          fullWidth={true}
          maxWidth={'xs'}
          open={this.state.openDelDialog}
          aria-labelledby="form-dialog-title"
          classes={{
            paper: classes.dialogStyle
          }}
        >
        <DialogTitle id="form-dialog-title">Eliminar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>¿Estás seguro?</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDelClickClose} color="primary">
              <FormattedMessage id="cancel" />
            </Button>
            <Button onClick={event => this.handleDelete(event, this.state.toDel)} color="primary">
              <FormattedMessage id="delete" />
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

AreaDash.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AreaDash);
/* eslint-enable no-unused-vars */