import auth from '../Auth';

class Area {
  constructor() {
    this.getList = this.getList.bind(this)
    this.create = this.create.bind(this)
    this.show = this.show.bind(this)
    this.delete = this.delete.bind(this)
  }

  getList(cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'areas/', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  async create(title, description, country, level, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'areas', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          area: { title: title, description: description, country: country, level: level }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  async saveMaturity(area_id, topic, rating, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'maturity', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          id: area_id, area: { topic: topic, relevance: rating.relevance, specificity: rating.specificity, control: rating.control, impacts: rating.impacts }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }
  
  getMaturity(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'areas/' + id + '/maturity', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  async saveCi(area_id, capital, topic, value, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'ci', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'POST',
        body: JSON.stringify({
          id: area_id, area: { topic: topic, capital: capital, civalue: value }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  getCi(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'areas/' + id + '/ci', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  async update(id, title, description, country, level, cb) { 
    try {
      await fetch(process.env.REACT_APP_DEV_API_URL + 'areas', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.getToken()
        },
        method: 'PUT',
        body: JSON.stringify({
          id: id, area: { title: title, description: description, country: country, level: level }
        })
      });
      cb();
    }
    catch (error) {
      return console.log(error);
    }
  }

  show(id, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'areas/' + id, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'GET'
    }).then((res) => res.json())
    .then(function(data) {
      return data;       
    }).catch(error => console.log(error) );
  }

  delete(ids, cb) { 
    return fetch(process.env.REACT_APP_DEV_API_URL + 'areas', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.getToken()
      },
      method: 'DELETE',
      body: JSON.stringify({
        area: { ids: ids }
      })
    }).then(res => { cb() }).catch(error => console.log(error) );
  }

}

export default new Area();